import { z } from 'zod'

const ZDataPoint = z.object({
  name: z.string(),
  count: z.number()
})

export type DataPoint = z.infer<typeof ZDataPoint>

const ZBarKpiData = z.object({
  date: z.string(),
  dataPoint: z.array(ZDataPoint)
})

export const ZBarKpiDataObject = z.object({
  type: z.string(),
  totalCount: z.number(),
  liveData: z.array(ZBarKpiData),
  CalculatedAt: z.string().optional()
})

export type BarKpiDataObject = z.infer<typeof ZBarKpiDataObject>

export const ZBarKpiApiResponse = z.object({
  http_status: z.number(),
  data: ZBarKpiDataObject,
  error: z.boolean(),
  error_message: z.string().optional()
})

export type BarKpiApiResponse = z.infer<typeof ZBarKpiApiResponse>

const ZHistoryARData = z.object({
  assetType: z.string(),
  serialNumber: z.string(),
  lastCommDate: z.string().nullable(),
  cliponSerialNumber: z.string().nullable(),
  shippedDate: z.string().nullable(),
  installedDate: z.string().nullable(),
  firstCommDate: z.string().nullable()
})

export type HistoryARData = z.infer<typeof ZHistoryARData>

const ZHistoryARDataObject = z.object({
  total_count: z.number(),
  assets: z.array(ZHistoryARData)
})

export type HistoryARDataObject = z.infer<typeof ZHistoryARDataObject>

export const ZHistoryARApiResponse = z.object({
  http_status: z.number(),
  data: ZHistoryARDataObject.optional(),
  error: z.boolean(),
  error_message: z.string().optional()
})

export type HistoryARApiResponse = z.infer<typeof ZHistoryARApiResponse>

const ZHistoryADSData = z.object({
  assetId: z.string(),
  serialNumber: z.string(),
  assetType: z.string(),
  cliponSerialNumber: z.string().nullable(),
  installationDate: z.string().nullable(),
  lastCommDate: z.string().nullable(),
  firstCommDate: z.string().nullable(),
  selectedDate: z.string().nullable()
})

export type HistoryADSData = z.infer<typeof ZHistoryADSData>

const ZHistoryADSDataObject = z.object({
  totalCount: z.number(),
  assetDetails: z.array(ZHistoryADSData)
})

export type HistoryADSDataObject = z.infer<typeof ZHistoryADSDataObject>

export const ZHistoryADSApiResponse = z.object({
  http_status: z.number(),
  data: ZHistoryADSDataObject.optional(),
  error: z.boolean(),
  error_message: z.string().optional()
})

export type HistoryADSApiResponse = z.infer<typeof ZHistoryADSApiResponse>

const ZHistoryKSData = z.object({
  assetId: z.string().nullable(),
  serialNumber: z.string(),
  assetType: z.string(),
  cliponSerialNumber: z.string().nullable(),
  installationDate: z.string().nullable(),
  lastCommunicationDate: z.string().nullable(),
  firstCommDate: z.string().nullable(),
  selectedDate: z.string().nullable()
})

export type HistoryKSData = z.infer<typeof ZHistoryKSData>

const ZHistoryKSDataObject = z.object({
  totalCount: z.number(),
  assetDetails: z.array(ZHistoryKSData)
})

export type HistoryKSDataObject = z.infer<typeof ZHistoryKSDataObject>

export const ZHistoryKSApiResponse = z.object({
  http_status: z.number(),
  data: ZHistoryKSDataObject.optional(),
  error: z.boolean(),
  error_message: z.string().optional()
})

export type HistoryKSApiResponse = z.infer<typeof ZHistoryKSApiResponse>

export interface BarChartState {
  isLoading: boolean
  error: boolean
  data: BarKpiDataObject | undefined
  httpStatus: number
}

const ZKpiPreferenceData = z.object({
  kpiId: z.string(),
  order: z.number()
})

export type KpiPreferenceData = z.infer<typeof ZKpiPreferenceData>

export const ZKpiPreferenceObject = z.object({
  userId: z.string(),
  kpiPreferences: z.array(ZKpiPreferenceData)
})

export type KpiPreferenceObject = z.infer<typeof ZKpiPreferenceObject>

export const ZKpiPreferenceApiResponse = z.object({
  http_status: z.number(),
  data: ZKpiPreferenceObject.optional(),
  error: z.boolean(),
  error_message: z.string().optional()
})

export type KpiPreferenceApiResponse = z.infer<typeof ZKpiPreferenceApiResponse>

const ZKpiPreferenceObj = z.object({
  kpiId: z.string(),
  order: z.number()
})

export type KpiPreferenceObj = z.infer<typeof ZKpiPreferenceObj>

const ZKpiPreferenceRequest = z.object({
  userId: z.string(),
  kpiPreferences: z.array(ZKpiPreferenceObj)
})

export type KpiPreferenceRequest = z.infer<typeof ZKpiPreferenceRequest>

export const ZSaveKpiPreferenceApiResponse = z.object({
  http_status: z.number(),
  data: z.boolean().optional(),
  error: z.boolean(),
  error_message: z.string().optional()
})

const ZDonutKpiDatapoint = z.object({
  name: z.string(),
  percentage: z.number()
})

export type DonutKpiDatapoint = z.infer<typeof ZDonutKpiDatapoint>

const ZDonutKpiLiveDataObj = z.object({
  dataPoint: z.array(ZDonutKpiDatapoint)
})

export type DonutKpiLiveDataObj = z.infer<typeof ZDonutKpiLiveDataObj>

export const ZDonutKpiDataObject = z.object({
  type: z.string(),
  totalCount: z.number(),
  CalculatedAt: z.string().optional(),
  liveData: z.array(ZDonutKpiLiveDataObj)
})

export type DonutKpiDataObject = z.infer<typeof ZDonutKpiDataObject>

export const ZDonutKpiApiResponse = z.object({
  http_status: z.number(),
  data: ZDonutKpiDataObject,
  error: z.boolean(),
  error_message: z.string().optional()
})

export type DonutKpiApiResponse = z.infer<typeof ZDonutKpiApiResponse>

export interface DonutKpiState {
  isLoading: boolean
  error: boolean
  data: DonutKpiDataObject | undefined
  httpStatus: number
}

// Donut kpis - response for Bar chart
const ZHistoryDonutTableData = z.object({
  assetType: z.string().nullable(),
  serialNumber: z.string(),
  cliponSerialNumber: z.string().nullable(),
  installationDate: z.string().nullable(),
  lastCommunicationDate: z.string().nullable(),
  intervalLength: z.number().nullable(),
  selectedDate: z.string().nullable(),
  firstCommDate: z.string().nullable(),
  numAlarms: z.any(),
  lcState: z.string().nullable(),
  firmwareVersion: z.string().nullable().optional(),
  value: z.any(),
  status: z.string().nullable().optional(),
  valueString: z.any()
})

export type HistoryDonutTableData = z.infer<typeof ZHistoryDonutTableData>

const ZHistoryDonutTableDataObject = z.object({
  totalCount: z.number(),
  assetDetails: z.array(ZHistoryDonutTableData)
})

export type HistoryDonutTableDataObject = z.infer<typeof ZHistoryDonutTableDataObject>

export const ZHistoryDonutTableApiResponse = z.object({
  http_status: z.number(),
  data: ZHistoryDonutTableDataObject.optional(),
  error: z.boolean(),
  error_message: z.string().optional()
})

export type HistoryDonutTableApiResponse = z.infer<typeof ZHistoryDonutTableApiResponse>

// Alarm service - response for Table

const ZHistoryASData = z.object({
  assetType: z.string(),
  serialNumber: z.string(),
  cliponSerialNumber: z.string().nullable(),
  installationDate: z.string().nullable(),
  lastCommunicationDate: z.string().nullable(),
  value: z.any()
})

export type HistoryASData = z.infer<typeof ZHistoryASData>

const ZHistoryASDataObject = z.object({
  totalCount: z.number(),
  assetDetails: z.array(ZHistoryASData)
})

export type HistoryASDataObject = z.infer<typeof ZHistoryASDataObject>

export const ZHistoryASApiResponse = z.object({
  http_status: z.number(),
  data: ZHistoryASDataObject.optional(),
  error: z.boolean(),
  error_message: z.string().optional()
})

export type HistoryASApiResponse = z.infer<typeof ZHistoryASApiResponse>
