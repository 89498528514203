import { useState } from 'react'
import { Card, CardContent, CircularProgress } from '@mui/material'
import AssetGroupDetailsComponent from './GroupDetailsComponents/AssetGroupDetails'
import BackButton from '../../../components/BackButton'
import { useNavigate } from 'react-router-dom'
import './GroupDetailsMainComponent.css'
import LanguageStrings from '../../../i18n/locales'
import AssignedAssetListTable from './GroupDetailsComponents/AssignedAssetList'
import exportIcon from '../../../assets/exportIcon.svg'
import { type ConnectedProps, connect, useSelector } from 'react-redux'
import { type RootState } from '../../../store'
import { Button } from '../../../components/Button'
import UnassignAssetWindow from './GroupDetailsComponents/UnassignAssetWindow'
import { type UpdateGroupRequest, type GroupsAssetObj } from '../types'
import { updateGroup, exportGroupDetailsById } from '../redux/actionCreator'
import { type UpdateGroupState } from '../redux/updateGroupSlice'
import Modal from '../../../components/Modal'
import AuthError from '../../../components/ErrorComponents/AuthError'

const GroupDetailsMainComponentStrings = LanguageStrings().AssetGroupStrings.GroupDetailsMainComponent

const GroupDetailsMainComponent = (props: PropsFromRedux): JSX.Element => {
  const navigate = useNavigate()
  const [reset, setReset] = useState(false)
  const [isEditEnabled, setIsEditEnaled] = useState(false)
  const [addedAssets, setAddedAssets] = useState<GroupsAssetObj[]>([])
  const [assetTypeId, setAssetTypeId] = useState('')
  const [description, setDescription] = useState('')
  const [descError, setDescError] = useState<boolean>(false)
  const [initialDesc, setInitialDesc] = useState<string>('')
  const [showAddAssetWindow, setShowAddAssetWindow] = useState<boolean>(false)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [showUpdatePopup, setShowUpdatePopup] = useState(false)
  const numAssignedAssets = useSelector((state: RootState) => state.assetAssignedList.assetAssignedList !== null ? state.assetAssignedList.assetAssignedList.total_count : 0)

  let groupName = sessionStorage.getItem('selectedGroupName')
  const groupNameID = sessionStorage.getItem('selectedGroupId')
  if (groupName === null) {
    groupName = ''
  }

  let groupId = sessionStorage.getItem('selectedGroupId')
  if (groupId === null) {
    groupId = ''
  }

  const resetComponent = (): void => {
    setIsEditEnaled(false)
    setAddedAssets([])
    setAssetTypeId('')
    setDescription('')
    setDescError(false)
    setInitialDesc('')
    setShowAddAssetWindow(false)
    setShowConfirmation(false)
    setShowUpdatePopup(false)
    setReset(!reset)
  }

  const assetModified = `${addedAssets.length} Assets has been added`
  const descriptionModified = 'Description has been modified'

  const getUpdateMessage = (): JSX.Element => {
    return (
      <ul>
        {addedAssets.length > 0 && description !== initialDesc && (
          `${assetModified} and ${descriptionModified}`
        )}
        {addedAssets.length > 0 && description === initialDesc && (
          `${assetModified}`
        )}
        {addedAssets.length === 0 && description !== initialDesc && (
          `${descriptionModified}`
        )}
      </ul>
    )
  }

  const handleModifyGroup = (): void => {
    props.updateGroup(groupId ?? '', {
      description,
      assign_assets: addedAssets.map((asset) => asset.asset_id),
      un_assign_assets: []
    })
  }

  const handleUpdateGroupWithFailure = (): JSX.Element => {
    // istanbul ignore else
    if (props.updateGroupDetailsState.httpStatus === 201) {
      return (
        <Modal buttonTypeNegative='hollow' title='Successfully Modified' LabelPositive='Ok'
          onClickPositive={() => {
            resetComponent()
          }}
          onClose={() => { resetComponent() }}>
          <p style={{ paddingTop: '1.2em' }}>{getUpdateMessage()}</p>
        </Modal>
      )
    } else if (props.updateGroupDetailsState.isLoading) {
      return (
        <Modal title='Modifying Group Details'>
          <div className='CircularprogressAmi'>
              <CircularProgress />
          </div>
        </Modal>
      )
    } else if (props.updateGroupDetailsState.httpStatus === 401) {
      return (
                <Modal titleStyle={{ paddingRight: '2em' }} width={'fit-content'} height={'fit-content'} buttonTypeNegative='hollow' LabelNegative='Close'
                    onClickNegative={() => { setShowUpdatePopup(false) }}
                    onClose={() => { setShowUpdatePopup(false) }} title='Modifying Group Details'>
                    <div className='authError'>
                        <AuthError className={'errorCompInsideModal'} errorMessage="Unauthorized"/>
                    </div>
                </Modal>
      )
    } else if (props.updateGroupDetailsState.httpStatus === 403) {
      return (
                <Modal titleStyle={{ paddingRight: '2em' }} width={'fit-content'} height={'fit-content'} buttonTypeNegative='hollow' LabelNegative='Close'
                    onClickNegative={() => { setShowUpdatePopup(false) }}
                    onClose={() => { setShowUpdatePopup(false) }} title='Modifying Group Details'>
                    <div className='authError'>
                        <AuthError className={'errorCompInsideModal'} errorMessage="accessForbidden"/>
                    </div>
                </Modal>
      )
    } else {
      return (
        <Modal width={'fit-content'} height={'fit-content'} buttonTypeNegative='hollow' LabelNegative='Close' LabelPositive='Retry'
          onClickNegative={() => { setShowUpdatePopup(false) }}
          onClose={() => { setShowUpdatePopup(false) }} title='Modifying Group Details'
          onClickPositive={handleModifyGroup}>
          <div className='authError'>
            <AuthError className={'errorCompInsideModal'} customError={props.updateGroupDetailsState.error}/></div>
        </Modal>
      )
    }
  }

  return (
    <div style={{ width: 'calc(100vw - 3.5rem)', paddingRight: '2rem' }}>
      <div className='link-breadcrumb'>
        <span data-testid='assetManagementbreadcrumb' onClick={() => { navigate('/card') }} className='assetmgmtli'>{GroupDetailsMainComponentStrings.Asset_Management}</span>
        <span style={{ padding: '0 0.5em' }}>/</span>
        <span onClick={() => { navigate('/asset-group-list') }} className='assetmgmtli'>{GroupDetailsMainComponentStrings.Asset_Group}</span>
        <span style={{ padding: '0 0.5em' }}>/</span>
        <span>{groupName}</span>
      </div>
      <div className='topUtilityBar'>
        <BackButton />
        <span id='componentTitle' data-testid='configurations'>{groupName}</span>
        <span id='top-right-ami-utilities'>
          <span id='top-right-btn-span'>
            <img src={exportIcon} onClick={
              // istanbul ignore next
              () => { props.exportGroupDetailsById(groupName ?? '', groupNameID !== null ? groupNameID : '') }} style={{ cursor: 'pointer' }} />
          </span>
        </span>
      </div>
      <Card sx={{ borderRadius: '10px', backgroundColor: '#272727', color: '#F0F0F0 !important', padding: '25px' }}>
        <h4>{GroupDetailsMainComponentStrings.ASSET_GROUP_DETAILS}</h4>
        <CardContent>
          <AssetGroupDetailsComponent
            setAssetTypeId={setAssetTypeId}
            isEditEnabled={isEditEnabled}
            description={description}
            setDescription={setDescription}
            descError={descError}
            setDescError={setDescError}
            setInitialDesc={setInitialDesc}
            reset={reset}
          />
          <div>
            <div id='assigned-asset-list-group'>
              <h5>{GroupDetailsMainComponentStrings.Assigned_Asset_List} <span className='config-list-box'>{numAssignedAssets} {GroupDetailsMainComponentStrings.Assets}</span></h5>
            </div>
            <AssignedAssetListTable
              addedAssets={addedAssets}
              setShowAddAssetWindow={setShowAddAssetWindow}
              isEditEnabled={isEditEnabled}
              reset={reset}
              resetComponent={resetComponent}
            />
          </div>
        </CardContent>
      </Card>
      <div style={{ width: '100%', backgroundColor: '#272727', display: 'flex', marginTop: '20px', justifyContent: 'space-between', alignItems: 'center', padding: '6px 24px' }}>
        <div style={{ justifyContent: 'flex-start' }}>
          {
            isEditEnabled &&
            <Button type='borderless' dataTestId='cancel-btn' onClick={() => { setIsEditEnaled(false) }}>Cancel</Button>
          }
        </div>
        <div style={{ justifyContent: 'flex-end' }}>
          {
            isEditEnabled
              ? <Button primaryColor={(descError || (addedAssets.length === 0 && initialDesc === description)) ? '#B0B0B0' : undefined} disabled={(descError || (addedAssets.length === 0 && initialDesc === description))} dataTestId='update-btn' onClick={() => { setShowConfirmation(true) }}>Update</Button>
              : <Button dataTestId='modify-btn' onClick={() => { setIsEditEnaled(true) }}>Modify</Button>
          }
        </div>
      </div>
      {
        showAddAssetWindow
          ? <>
            <UnassignAssetWindow
              addedAssets={addedAssets}
              setAddedAssets={setAddedAssets}
              assetTypeId={assetTypeId}
              setShowAddAssetWindow={setShowAddAssetWindow}
              onClose={() => { setShowAddAssetWindow(false) }}
            />
          </>
          : <></>
      }
      { showConfirmation &&
        <Modal buttonTypeNegative='hollow' title='Modifying Group Details' LabelNegative='Cancel' LabelPositive='Update' onClickPositive={() => {
          setShowConfirmation(false)
          setShowUpdatePopup(true)
          handleModifyGroup()
        }} onClose={() => { setShowConfirmation(false) }} onClickNegative={() => { setShowConfirmation(false) }}>
          <p style={{ paddingTop: '1.2em' }}>Do you want to modify Group ?</p>
        </Modal>
      }
      {
        showUpdatePopup &&
        handleUpdateGroupWithFailure()
      }
    </div>
  )
}

interface DispatchToProps {
  updateGroup: (groupId: string, updateGroupRequest: UpdateGroupRequest) => void
  exportGroupDetailsById: (assetGroup: string, assetGroupId: string) => void
}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  updateGroup: (groupId: string, updateGroupRequest: UpdateGroupRequest) => dispatch(updateGroup(groupId, updateGroupRequest)),
  exportGroupDetailsById: (assetGroup: string, assetGroupId: string) => dispatch(exportGroupDetailsById(assetGroup, assetGroupId))
})

interface StateToProps {
  updateGroupDetailsState: UpdateGroupState
}

const mapStateToProps = (state: RootState): StateToProps => ({
  updateGroupDetailsState: state.updateGroupDetailsState
})

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(GroupDetailsMainComponent)
