import { z } from 'zod'
import interceptedAxios from '../../../utils/interceptedAxios'

const ZAssetTypes = z.object({
  asset_type_id: z.string(),
  asset_type_name: z.string(),
  category_type_id: z.string(),
  category_type_name: z.string()
})

const ZApiResponse = z.object({
  http_status: z.number(),
  data: z.array(ZAssetTypes).nullable(),
  error: z.boolean()
})

export type AssetTypes = z.infer<typeof ZAssetTypes>

export async function getAssetTypes (
  onSuccess: (assetTypes: AssetTypes[], httpStatus: number) => any,
  onFailure: (errorMessage: any, httpStatus: number) => any
): Promise<void> {
  const url = '/asset-service/api/v1/udp/assets/types'

  interceptedAxios
    .get(url)
    .then((response) => {
      const parseResponse = ZApiResponse.safeParse(response.data)
      // istanbul ignore else
      if (parseResponse.success) {
        // istanbul ignore else
        if (
          parseResponse.data.data !== null &&
          parseResponse.data.data !== undefined
        ) {
          onSuccess(parseResponse.data.data, response.status)
        } else {
          onFailure([], response.status)
        }
      } else {
        onFailure('Unable to parse Response', response.status)
      }
    })
    .catch((error) => {
      // istanbul ignore else
      if (error.response !== undefined) {
        onFailure(error.response.data.error_message, error.response.status)
      } else {
        onFailure('Network Error', 500)
      }
    })
}
