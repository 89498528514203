import React from 'react'
import GenericToggleButton from '../../../../components/GenericToggleButton'
import dayjs from 'dayjs'
import HistoryARTableComponent from './HistoryARTableComponent'
import { type ConnectedProps, connect } from 'react-redux'
import { type RootState } from '../../../../store'
import { fetchKpiHistoryAR, fetchDonutKpiHistoryTable, fetchKpiHistoryKS } from '../../redux/actionCreators'
import { doesKpiHaveDonutChart, isSingleBarKpi, type KpiKeyDonut, type KpiKey } from '../../redux/changeKpiType'
import { useSelector } from 'react-redux'
import { getDetailKeyword, getDonutDetails, getToggleOptions } from './kpiUtils'
import HistoryDonutKpiTableComponent from './HistoryDonutKpiTableComponent'
import { type DonutKpiState } from '../../types'
import { type BarGraphParameter } from '../../KpiDetails'
import HistoryKSTableComponent from './HistoryKSTableComponent'

interface ParentProps {
  endDate: dayjs.Dayjs
  barGraphParameter: BarGraphParameter
  setBarGraphParameter: React.Dispatch<React.SetStateAction<BarGraphParameter>>
  selectedDonut: number
  initModification: boolean
  setInitModification: React.Dispatch<React.SetStateAction<boolean>>
  page: number
  setPage: React.Dispatch<React.SetStateAction<number>>
}

type Order = 'asc' | 'desc'

interface Sort {
  order: Order
  orderBy: string
}

const HistoryTable = (props: AllProps): JSX.Element => {
  const rowsPerPage = 10
  const [initLoading, setInitLoading] = React.useState<boolean>(false)
  const selectedKpi = useSelector((state: RootState) => state.kpiType)
  const [sort, setSort] = React.useState<Sort>({ order: 'asc', orderBy: 'serialNumber' })
  const [detailKeyword, detailService] = getDetailKeyword(selectedKpi, props.barGraphParameter.bar.value)

  const fetchAssets = (pageNum: number): void => {
    if (doesKpiHaveDonutChart(selectedKpi)) {
      props.fetchDonutKpiHistoryTable(detailKeyword, dayjs(props.barGraphParameter.date).format('YYYY-MM-DD'), getDonutDetails(selectedKpi as KpiKeyDonut, props.selectedDonut, props.historyDonutState), pageNum, rowsPerPage, detailService)
    } else {
      if (detailService === 'asset-service') {
        props.fetchKpiHistoryAR(detailKeyword, dayjs(props.barGraphParameter.date).format('YYYY-MM-DD'), pageNum, rowsPerPage, detailService)
      } else {
        props.fetchKpiHistoryKS(detailKeyword, dayjs(props.barGraphParameter.date).format('YYYY-MM-DD'), pageNum, rowsPerPage, detailService)
      }
    }
  }

  React.useEffect(() => {
    if (doesKpiHaveDonutChart(selectedKpi) && props.historyDonutState.httpStatus === 200) {
      fetchAssets(props.page - 1)
    } else if (!doesKpiHaveDonutChart(selectedKpi) && initLoading) {
      fetchAssets(props.page - 1)
    }
    setInitLoading(true)
  }, [props.page, props.historyDonutState])

  React.useEffect(() => {
    if (props.initModification) {
      props.setPage(1)
      if (doesKpiHaveDonutChart(selectedKpi) && props.historyDonutState.httpStatus === 200) {
        fetchAssets(0)
      } else if (!doesKpiHaveDonutChart(selectedKpi)) {
        fetchAssets(0)
      }
    }
    props.setInitModification(true)
  }, [props.barGraphParameter])

  React.useEffect(() => {
    props.setBarGraphParameter(prev => { return { ...prev, date: dayjs(new Date()) } })
  }, [props.selectedDonut])

  const getHistoryTable = (): JSX.Element => {
    if (doesKpiHaveDonutChart(selectedKpi)) {
      return <HistoryDonutKpiTableComponent selectedDonut={props.selectedDonut} rowPerPage={rowsPerPage} page={props.page} setPage={props.setPage} sort={sort} setSort={setSort} selectedBar={props.barGraphParameter.bar} selectedDate={props.barGraphParameter.date} />
    } else {
      if (detailService === 'asset-service') {
        return <HistoryARTableComponent rowPerPage={rowsPerPage} page={props.page} setPage={props.setPage} sort={sort} setSort={setSort} selectedBar={props.barGraphParameter.bar} selectedDate={props.barGraphParameter.date} />
      } else {
        return <HistoryKSTableComponent rowPerPage={rowsPerPage} page={props.page} setPage={props.setPage} sort={sort} setSort={setSort} selectedBar={props.barGraphParameter.bar} selectedDate={props.barGraphParameter.date} />
      }
    }
  }

  return (
    <>
      <div style={{ display: 'flex', padding: '10px', borderBottom: '2px solid #202020' }}>
        {
          (!isSingleBarKpi(selectedKpi) && !doesKpiHaveDonutChart(selectedKpi)) && <span style={{ paddingRight: '1em' }}>
          <GenericToggleButton options={getToggleOptions(selectedKpi)} selected={props.barGraphParameter.bar} onChange={(option) => { props.setBarGraphParameter(prev => { return { ...prev, bar: option } }) }} />
        </span>
        }
      </div>
      <div>
        {getHistoryTable()}
      </div>
    </>
  )
}

interface DispatchToProps {
  fetchKpiHistoryAR: (
    kpiType: string,
    inputDateRead: string,
    page: number,
    size: number, service: string) => void
  fetchKpiHistoryKS: (
    kpiType: string,
    inputDateRead: string,
    page: number,
    size: number, service: string) => void
  fetchDonutKpiHistoryTable: (
    kpiType: string,
    inputDateRead: string,
    donutType: string,
    page: number,
    size: number, service: string) => void
}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  fetchKpiHistoryAR: (
    kpiType: string,
    inputDateRead: string,
    page: number,
    size: number, service: string) => dispatch(fetchKpiHistoryAR(kpiType, inputDateRead, page, size, service)),
  fetchKpiHistoryKS: (
    kpiType: string,
    inputDateRead: string,
    page: number,
    size: number, service: string) => dispatch(fetchKpiHistoryKS(kpiType, inputDateRead, page, size, service)),
  fetchDonutKpiHistoryTable: (
    kpiType: string,
    inputDateRead: string,
    donutType: string,
    page: number,
    size: number, service: string) => dispatch(fetchDonutKpiHistoryTable(kpiType, inputDateRead, donutType, page, size, service))
})

interface StateToProps {
  kpiType: KpiKey
  historyDonutState: DonutKpiState
}

const mapStateToProps = (state: RootState): StateToProps => ({
  kpiType: state.kpiType,
  historyDonutState: state.historyDonutState
})

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>
type AllProps = ParentProps & PropsFromRedux

export default connector(HistoryTable)
