/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
// istanbul ignore file
import { useState, useRef, useEffect, type ReactNode } from 'react'
import { connect, type ConnectedProps } from 'react-redux'
import { CircularProgress, FormHelperText, TextField, useMediaQuery } from '@mui/material'
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import LanguageStrings from '../../i18n/locales'
import type { Option } from '../GenXDropdown'
import { GenericDropdown } from '../GenericDropdown'
import AuthError from '../ErrorComponents/AuthError'
import { Button } from '../Button'
import { type AdvancedFilterOptionState } from '../../features/asset-group/redux/advancedFilterOptionSlice'
import { getAdvancedFilterOption } from '../../features/asset-group/redux/actionCreator'
import { type RootState } from '../../store'
import { validateAdvFilterField } from './AdvancedFilter'

const AdvancedFilterTableStrings = LanguageStrings().AssetGroupStrings.GroupDetailsComponent.AdvancedFilterTable

const textFieldStyles = () => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: 'none'
    },
    '&:hover fieldset': {
      border: 'none'
    },
    '&.Mui-focused fieldset': {
      border: 'none'
    },
    color: '#D0D0D0'
  }
})

const AdvancedFilterTable = (props: AllProps): JSX.Element => {
  const contentRef = useRef<HTMLDivElement>(null)
  const [parameterListErrorInit, setParameterListErrorInit] = useState<boolean[][]>(Array(props.conditions.length).fill([false, false, false]))
  const screenLessThan1200Px = useMediaQuery('(max-width: 1200px)')
  const heightLessThan600Px = useMediaQuery('(max-height: 750px)')
  const heightGreaterThan1200px = useMediaQuery('(min-height: 1200px)')
  const [parameterOptions, setParameterOptions] = useState<Option[]>([])
  const [operatorOptions, setOperatorOptions] = useState<Option[]>([])

  useEffect(() => {
    props.getAdvancedFilterOption(props.searchKey)
  }, [])

  useEffect(() => {
    const advancedFilterOption = props.advancedFilterOption.advancedFilterOption

    if (advancedFilterOption != null && typeof advancedFilterOption === 'object') {
      const params: Option[] = []
      const uniqueParams: Record<string, boolean> = {}

      Object.entries(advancedFilterOption).forEach(([key, value]) => {
        if (!uniqueParams[key]) {
          uniqueParams[key] = true
          params.push({
            value: key,
            label: value.parameter_display_name
          })
        }
      })

      setParameterOptions(params)
    }
  }, [props.advancedFilterOption])

  useEffect(() => {
    const allOperators: Option[] = []
    props.conditions.forEach((item) => {
      const advancedFilterOption = props.advancedFilterOption.advancedFilterOption
      if (item.parameter.length > 0 && advancedFilterOption != null) {
        const allowedOperators = advancedFilterOption[item.parameter]?.allowed_operators?.split(',')
        const ops: Option[] = allowedOperators.map((operator) => ({
          value: operator.trim(),
          label: operator.trim()
        }))
        allOperators.push(...ops)
      }
    })

    const uniqueOperators = Array.from(new Set(allOperators.map((op) => op.value)))
      .map((value) => allOperators.find((op) => op.value === value))
      .filter((op): op is Option => op !== undefined)

    setOperatorOptions(uniqueOperators)
  }, [props.conditions, props.advancedFilterOption])

  const renderValueField = (item: { parameter: string, operator: string, value: string | string[] }, index: number): ReactNode => {
    const advancedFilterOption = props.advancedFilterOption.advancedFilterOption
    const parameterDataType = advancedFilterOption?.[item.parameter]?.parameter_data_type
    const booleanOptions: any = [
      { label: 'True', value: true },
      { label: 'False', value: false }
    ]

    const utcOptions: Option[] = []
    const utcNumbers = [-8, -7, -6, -5, 0, 1, 2, 3, 4]

    const backendValues: Record<string, string> = {
      mUTC8: '-32',
      mUTC7: '-28',
      mUTC6: '-24',
      mUTC5: '-20',
      UTC: '0',
      UTC1: '4',
      UTC2: '8',
      UTC3: '12',
      UTC4: '16'
    }

    for (const i of utcNumbers) {
      let label = ''

      if (i < 0) {
        label = `mUTC${-i}` // For negative UTC
      } else if (i === 0) {
        label = 'UTC' // For UTC 0
      } else {
        label = `UTC${i}` // For positive UTC
      }
      const value = backendValues[label] || label

      const obj: Option = {
        label,
        value
      }
      utcOptions.push(obj)
    }

    for (const i of utcNumbers) {
      let val = ''
      if (i > 0) val = '+'
      else if (i === 0) val = '-'
      const obj: Option = {
        label: `UTC${i !== 0 ? val : ''}${i !== 0 ? i : ''}`,
        value: `UTC${i !== 0 ? val : ''}${i !== 0 ? i : ''}`
      }
      utcOptions.push(obj)
    }
    const validation = (value: string | string[]) => {
      const currentCondition = props.conditions[index]
      const { parameter } = currentCondition

      if (value === '') {
        return AdvancedFilterTableStrings.EmptyValueValidation
      } else if (parameterDataType === 'string') {
        return AdvancedFilterTableStrings.OnlyAlphanumeric
      } else if (parameterDataType === 'double' && (parameter?.toLowerCase().includes('version') ?? false)) {
        return AdvancedFilterTableStrings.ValidateFirmware
      } else if (parameterDataType === 'double') {
        return AdvancedFilterTableStrings.OnlyDouble
      } else {
        return AdvancedFilterTableStrings.EmptyValueValidation
      }
    }

    const handleValueChange = (newValue: string | string[], index: number, datatype: string, fieldIndex: number) => {
      if (parameterListErrorInit[index] === undefined) {
        return // Prevent access to undefined
      }

      if (datatype === 'string' || datatype === 'double') {
        const newError = parameterListErrorInit[index]
        newError[fieldIndex] = true
        setParameterListErrorInit([...parameterListErrorInit.slice(0, index), newError, ...parameterListErrorInit.slice(index + 1)])
      }

      props.setErrors([...props.errors.slice(0, index), validateAdvFilterField(newValue, datatype, props.conditions[index]?.parameter), ...props.errors.slice(index + 1)])

      const updatedCriteria = [...props.conditions]
      updatedCriteria[index].value = newValue
      setCriteriaAndNotify(updatedCriteria)
    }

    if (item.operator === 'Between') {
      if (parameterDataType === 'string' || parameterDataType === 'double') {
        const fromValue: string = Array.isArray(item.value) ? item.value[0] : item.value
        const toValue: string = Array.isArray(item.value) ? item.value[1] : ''

        return (
                <>
                    <div style={{ display: 'flex', flexDirection: 'row', width: 'fit-content' }}>
                        <span>
                            <TextField
                                id={`custom-textfield-${index}-from`}
                                variant="outlined"
                                placeholder={'From Value'}
                                value={fromValue || ''}
                                onChange={(e) => { handleValueChange([e.target.value, toValue], index, parameterDataType, 0) }}
                                size='small'
                                autoComplete='off'
                                className='custom-textfield'
                                sx={{
                                  ...textFieldStyles(),
                                  marginTop: '10px',
                                  width: '100%',
                                  border: parameterListErrorInit.find((_, idx) => idx === index)?.[0] && validateAdvFilterField(fromValue, parameterDataType, props.conditions[index].parameter) ? '1px solid #EE3426' : 'none'
                                }}
                            />
                            {parameterListErrorInit.find((_, idx) => idx === index)?.[0] && validateAdvFilterField(fromValue, parameterDataType, props.conditions[index].parameter) &&
                                <FormHelperText sx={{ color: '#EE3426', paddingLeft: '10px', whiteSpace: 'nowrap', fontSize: '11px' }} className="errorColor">
                                    {validation(fromValue)}
                                </FormHelperText>
                            }
                        </span>
                        <span>
                            <TextField
                                id={`custom-textfield-${index}-to`}
                                variant="outlined"
                                placeholder={'To Value'}
                                value={toValue || ''}
                                onChange={(e) => { handleValueChange([fromValue, e.target.value], index, parameterDataType, 1) }}
                                size='small'
                                autoComplete='off'
                                className='custom-textfield'
                                sx={{
                                  ...textFieldStyles(),
                                  marginTop: '10px',
                                  marginLeft: '10px',
                                  width: '100%',
                                  border: parameterListErrorInit.find((_, idx) => idx === index)?.[1] && validateAdvFilterField(toValue, parameterDataType, props.conditions[index].parameter) ? '1px solid #EE3426' : 'none'
                                }}
                            />
                            {parameterListErrorInit.find((_, idx) => idx === index)?.[1] && validateAdvFilterField(toValue, parameterDataType, props.conditions[index].parameter) &&
                                <FormHelperText sx={{ color: '#EE3426', paddingLeft: '10px', whiteSpace: 'nowrap', fontSize: '11px' }} className="errorColor">
                                    {validation(toValue)}
                                </FormHelperText>
                            }
                        </span>
                    </div>
                </>
        )
      } else if (parameterDataType === 'timezone') {
        return (
          <>
              <div style={{ display: 'flex', flexDirection: 'row', width: 'fit-content' }}>
                  <GenericDropdown
                      width='100%'
                      placeholder='From Value'
                      menuStyle={{ height: '200px', width: '210px' }}
                      fontSize='12px'
                      menuItemStyle={{ fontSize: '12px' }}
                      options={utcOptions}
                      value={Array.isArray(item.value) ? item.value[0] : ''}
                      onChange={(selectedValue) => {
                        handleValueChange([selectedValue.target.value, Array.isArray(item.value) ? item.value[1] : ''], index, parameterDataType, 0)
                      }}
                      label={''}
                      dataTestId={'timezone-dropdown-from'}
                  />
                  <div style={{ marginLeft: '10px' }}>
                      <GenericDropdown
                          width='100%'
                          placeholder='To Value'
                          menuStyle={{ height: '200px', width: '210px' }}
                          fontSize='12px'
                          menuItemStyle={{ fontSize: '12px' }}
                          options={utcOptions}
                          value={Array.isArray(item.value) ? item.value[1] : ''}
                          onChange={(selectedValue) => {
                            handleValueChange([Array.isArray(item.value) ? item.value[0] : '', selectedValue.target.value], index, parameterDataType, 1)
                          }}
                          label={''}
                          dataTestId={'timezone-dropdown-to'}
                      />
                  </div>
              </div>
          </>
        )
      } else if (parameterDataType === 'time') {
        return (
          <>
              <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center' }}>
                  <div style={{ marginTop: '2px' }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <TimePicker
                              className='time-picker-Scheduler'
                              data-testid='time-picker-from'
                              views={['hours', 'minutes']}
                              value={item.value[0] !== '' ? dayjs(item.value[0]) : null}
                              onChange={(val: dayjs.Dayjs | null) => {
                                const updatedCriteria = [...props.conditions]
                                const localDate = (val !== null) ? val.local().format() : ''
                                updatedCriteria[index].value = [localDate, item.value[1]]
                                props.setErrors([...props.errors.slice(0, index), localDate === '', ...props.errors.slice(index + 1)])
                                setCriteriaAndNotify(updatedCriteria)
                              }}
                              componentsProps={{
                                popper: {
                                  sx: {
                                    '& .MuiPaper-root': {
                                      maxHeight: '150px',
                                      overflowY: 'auto'
                                    }
                                  }
                                }
                              }}
                              sx={{
                                '& .MuiInputBase-root': {
                                  height: '40px',
                                  '& input': { color: '#D0D0D0' }
                                },
                                width: '95%'
                              }}
                          />
                      </LocalizationProvider>
                  </div>
                  <div style={{ marginTop: '2px' }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <TimePicker
                              className='time-picker-Scheduler'
                              data-testid='time-picker-to'
                              views={['hours', 'minutes']}
                              value={item.value[1] !== '' ? dayjs(item.value[1]) : null}
                              onChange={(val: dayjs.Dayjs | null) => {
                                const updatedCriteria = [...props.conditions]
                                const localDate = (val !== null) ? val.local().format() : ''
                                updatedCriteria[index].value = [item.value[0], localDate]
                                props.setErrors([...props.errors.slice(0, index), localDate === '', ...props.errors.slice(index + 1)])
                                setCriteriaAndNotify(updatedCriteria)
                              }}
                              componentsProps={{
                                popper: {
                                  sx: {
                                    '& .MuiPaper-root': {
                                      maxHeight: '150px',
                                      overflowY: 'auto'
                                    }
                                  }
                                }
                              }}
                              sx={{
                                '& .MuiInputBase-root': {
                                  height: '40px',
                                  '& input': { color: '#D0D0D0' }
                                },
                                width: '95%'
                              }}
                          />
                      </LocalizationProvider>
                  </div>
              </div>
          </>
        )
      }
    } else if (item.operator !== '') {
      if (parameterDataType === 'string' || parameterDataType === 'double') {
        const value: string = Array.isArray(item.value) ? item.value[0] : item.value

        return (
                <>
                    <TextField
                        id={`custom-textfield-${index}`}
                        variant="outlined"
                        placeholder={'Enter Value'}
                        value={value || ''}
                        onChange={(e) => { handleValueChange(e.target.value, index, parameterDataType, 0) }}
                        size='small'
                        autoComplete='off'
                        className='custom-textfield'
                        sx={{ ...textFieldStyles(), width: '100%', border: parameterListErrorInit.find((_, idx) => idx === index)?.[0] && validateAdvFilterField(value, parameterDataType, props.conditions[index].parameter) ? '1px solid #EE3426' : 'none' }}
                    />
                    {(parameterListErrorInit.find((_, idx) => idx === index)?.[0] && validateAdvFilterField(value, parameterDataType, props.conditions[index].parameter)) &&
                        <FormHelperText sx={{ color: '#EE3426', paddingLeft: '10px', whiteSpace: 'nowrap', fontSize: '11px' }} className="errorColor">
                            {validation(value)}
                        </FormHelperText>
                    }
                </>
        )
      } else if (parameterDataType === 'boolean') {
        const currentValue = Array.isArray(item.value) ? item.value[0] : item.value
        return (
                <>
                    <GenericDropdown
                        width='283px'
                        placeholder='Select Value'
                        menuStyle={{ height: '200px', width: '210px' }}
                        fontSize='12px'
                        menuItemStyle={{ fontSize: '12px' }}
                        options={booleanOptions}
                        value={currentValue}
                        onChange={(event) => {
                          handleValueChange(event.target.value, index, parameterDataType, 0)
                        }}
                        label={''} dataTestId={'boolean-dropdown'} />
                </>
        )
      } else if (parameterDataType === 'timezone') {
        const currentValue = Array.isArray(item.value) ? item.value[0] : item.value
        return (
                <>
                    <GenericDropdown
                        width='100%'
                        placeholder='Select Value'
                        menuStyle={{ height: '200px', width: '210px' }}
                        fontSize='12px'
                        menuItemStyle={{ fontSize: '12px' }}
                        options={utcOptions}
                        value={currentValue}
                        onChange={(selectedValue) => {
                          handleValueChange(selectedValue.target.value, index, parameterDataType, 0)
                        }}
                        label={''} dataTestId={'timezone-dropdown'}
                    />
                </>
        )
      } else if (parameterDataType === 'time') {
        return (
          <>
              <div style={{ marginTop: '0px' }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                          className='time-picker-Scheduler'
                          data-testid='time-picker'
                          views={['hours', 'minutes']}
                          value={item.value[0] !== '' ? dayjs(item.value[0]) : null}
                          onChange={(val: dayjs.Dayjs | null) => {
                            const updatedCriteria = [...props.conditions]
                            const localDate = (val !== null) ? val.local().format() : ''
                            updatedCriteria[index].value = [localDate]
                            props.setErrors([...props.errors.slice(0, index), localDate === '', ...props.errors.slice(index + 1)])
                            setCriteriaAndNotify(updatedCriteria)
                          }}
                          componentsProps={{
                            popper: {
                              sx: {
                                '& .MuiPaper-root': {
                                  maxHeight: '150px',
                                  overflowY: 'auto'
                                }
                              }
                            }
                          }}
                          sx={{
                            '& .MuiInputBase-root': {
                              height: '40px',
                              '& input': { color: '#D0D0D0' }
                            },
                            width: '100%'
                          }}
                      />
                  </LocalizationProvider>
              </div>
          </>
        )
      }
    } else {
      return (
        <>
            <TextField
                id={`custom-textfield-${index}`}
                variant="outlined"
                placeholder={'Enter Value'}
                value={''}
                disabled={true}
                size='small'
                autoComplete='off'
                className='custom-textfield'
                sx={{ ...textFieldStyles(), width: '100%', border: parameterListErrorInit.find((_, idx) => idx === index)?.[0] && validateAdvFilterField(item.value) ? '1px solid #EE3426' : 'none' }}
            />
            {parameterListErrorInit.find((_, idx) => idx === index)?.[0] && validateAdvFilterField(item.value) &&
                <FormHelperText sx={{ color: '#EE3426', paddingLeft: '10px', whiteSpace: 'nowrap', fontSize: '11px' }} className="errorColor">
                    {AdvancedFilterTableStrings.EmptyValueValidation}
                </FormHelperText>
            }
        </>
      )
    }
  }

  const setCriteriaAndNotify = (updatedCriteria: Array<{ parameter: string, operator: string, value: string | string[], dataType: string }>) => {
    props.setConditions(updatedCriteria) // Notify the parent
  }

  const handleAddCriteria = (): void => {
    props.setErrors([...props.errors, true])
    setParameterListErrorInit(prev => [...prev, [false, false]])
    setCriteriaAndNotify([...props.conditions, { parameter: '', operator: '', value: ['', ''], dataType: '' }])
  }

  const handleDeleteCriteria = (index: number): void => {
    const updatedCriteria = props.conditions.filter((_, i) => i !== index)
    setParameterListErrorInit(parameterListErrorInit.filter((_, i) => i !== index))
    props.setErrors(props.errors.filter((_, i) => i !== index))
    setCriteriaAndNotify(updatedCriteria)
  }

  const handleDeleteAll = (): void => {
    setCriteriaAndNotify([])
    setParameterListErrorInit([])
    props.setErrors([])
  }

  const handleOperatorSelect = (selectedValue: Option, index: number): void => {
    const updatedCriteria = [...props.conditions]
    updatedCriteria[index].value = ['', ''] // Reset the value
    updatedCriteria[index].operator = selectedValue.value
    setCriteriaAndNotify(updatedCriteria)
  }

  const handleParameterSelect = (selectedValue: Option, index: number): void => {
    const updatedCriteria = [...props.conditions]
    updatedCriteria[index].parameter = selectedValue.value
    updatedCriteria[index].operator = '' // Reset the operator
    updatedCriteria[index].value = ['', ''] // Reset the value
    updatedCriteria[index].dataType = props.advancedFilterOption.advancedFilterOption?.[selectedValue.value]?.parameter_data_type ?? ''
    setCriteriaAndNotify(updatedCriteria)
    props.setErrors([...props.errors.slice(0, index), true, ...props.errors.slice(index + 1)])
  }

  if (props.advancedFilterOption.isLoading) {
    return (
            <div className='CircularprogressAmi'>
                <CircularProgress />
            </div>
    )
  } else if (props.advancedFilterOption.httpStatus === 401) {
    return (
            <div className='errorCompInsideModal'><AuthError errorMessage="Unauthorized" /></div>
    )
  } else if (props.advancedFilterOption.httpStatus === 403) {
    return (
            <div className='errorCompInsideModal'><AuthError errorMessage="Access Forbidden" /></div>
    )
  } else if (props.advancedFilterOption.httpStatus === 200) {
    return (
            <div style={{ margin: '0px', borderRadius: '10px', fontSize: '12px', color: '#F0F0F0', border: '0.7px solid #101010' }}>
                <div style={{ color: '#F0F0F0', padding: '10px 1em', backgroundColor: '#313030', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}>
                    {'Filter Criteria'}
                </div>
                <div ref={contentRef} style={{ maxHeight: heightGreaterThan1200px ? '40vh' : heightLessThan600Px ? 220 : 300, overflowY: 'auto' }}>
                    <table style={{ width: '100%', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px', tableLayout: 'fixed' }}>
                        <thead style={{ outline: 'solid #202020', position: 'sticky', top: 0, zIndex: 10000, backgroundColor: '#272727' }}>
                            <tr>
                                <th style={{ color: '#F0F0F0', padding: '10px', textAlign: 'left' }}>{AdvancedFilterTableStrings.Parameter}</th>
                                <th style={{ color: '#F0F0F0', padding: '10px', borderLeft: '2px solid #202020', textAlign: 'left' }}>{AdvancedFilterTableStrings.Operator}</th>
                                <th style={{ color: '#F0F0F0', padding: '10px', borderLeft: '2px solid #202020', textAlign: 'left' }}>{AdvancedFilterTableStrings.Value}</th>
                                <th style={{ color: '#F0F0F0', padding: '10px', borderLeft: '2px solid #202020', textAlign: 'left', width: '80px' }}>{AdvancedFilterTableStrings.Action}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.conditions.map((item, index) => (
                                <tr key={index}>
                                    <td style={{ color: '#F0F0F0', padding: '10px', borderBottom: '1px solid #202020' }}>
                                        <GenericDropdown
                                            width={screenLessThan1200Px ? 210 : 260}
                                            placeholder='Select Parameter'
                                            menuStyle={{ height: '200px', width: '210px' }}
                                            fontSize='12px'
                                            menuItemStyle={{ fontSize: '12px' }}
                                            options={parameterOptions}
                                            value={parameterOptions.find((option) => option.value === item.parameter)?.value ?? ''}
                                            onChange={(e) => {
                                              handleParameterSelect(parameterOptions.find((item) => item.value === e.target.value) ?? { label: '', value: '' }, index)
                                            }}
                                            label={''} dataTestId='parameter-dropdown' />
                                    </td>
                                    <td style={{ color: '#F0F0F0', padding: '10px', borderBottom: '1px solid #202020' }}>
                                        <GenericDropdown
                                            dataTestId="operator-dropdown"
                                            width={screenLessThan1200Px ? 210 : 260}
                                            menuStyle={{ height: '200px', width: '210px' }}
                                            fontSize='12px'
                                            menuItemStyle={{ fontSize: '12px' }}
                                            placeholder="Select Operator"
                                            value={operatorOptions.find((op) => op.value === item.operator)?.value ?? ''}
                                            options={item.parameter ? operatorOptions : []}
                                            onChange={(e) => {
                                              handleOperatorSelect(operatorOptions.find((item) => item.value === e.target.value) ?? { label: '', value: '' }, index)
                                            }}
                                            label={''}
                                        />
                                    </td>
                                    <td style={{ color: '#F0F0F0', padding: '10px', borderBottom: '1px solid #202020' }}>
                                        {renderValueField(item, index)}
                                    </td>
                                    <td style={{ color: '#F0F0F0', padding: '10px', borderBottom: '1px solid #202020', width: '80px' }}>
                                        <span
                                            data-testid="delete-conditions-btn"
                                            style={{ color: '#64c3ff', textDecoration: 'none', cursor: 'pointer' }}
                                            onClick={() => {
                                              handleDeleteCriteria(index)
                                            }}
                                        >
                                            {AdvancedFilterTableStrings.Delete}
                                        </span>
                                    </td>
                                </tr>
                            ))}
                            {props.conditions.length === 0 && (
                                <tr>
                                    <td
                                        colSpan={4}
                                        style={{ color: '#F0F0F0', textAlign: 'center', padding: '10px' }}
                                    >
                                        {AdvancedFilterTableStrings.NoDataAvailable}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <div style={{ padding: '10px', flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
                    <Button
                        type="borderless"
                        dataTestId='add-criteria-btn'
                        width="150px"
                        onClick={() => {
                          if (props.conditions.length < 20) {
                            handleAddCriteria()
                          }
                        }}
                        disabled={props.conditions.length >= 20}
                    >
                        {AdvancedFilterTableStrings.AddCriteria}
                    </Button>
                    <Button
                        type="borderless"
                        data-testid="delete-all-btn"
                        width="150px"
                        onClick={handleDeleteAll}
                        disabled={props.conditions.length === 0}
                    >
                        {AdvancedFilterTableStrings.DeleteAll}
                    </Button>
                </div>
            </div>
    )
  } else {
    return (
            <div className='errorCompInsideModal'><AuthError errorMessage="cannotFetchGroups" /></div>
    )
  }
}

interface DispatchToProps {
  getAdvancedFilterOption: (searchKey: string) => void
}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  getAdvancedFilterOption: (searchKey: string) => dispatch(getAdvancedFilterOption(searchKey))
})

interface StateToProps {
  advancedFilterOption: AdvancedFilterOptionState
}

const mapStateToProps = (state: RootState): StateToProps => ({
  advancedFilterOption: state.advancedFilterOption
})

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

interface AdvancedFilterTableProps {
  setConditions: (newCriteria: Array<{ parameter: string, operator: string, value: string | string[], dataType: string }>) => void
  conditions: Array<{ parameter: string, operator: string, value: string | string[], dataType: string }>
  errors: boolean[]
  setErrors: (value: boolean[]) => void
  searchKey: string
}

type AllProps = AdvancedFilterTableProps & PropsFromRedux

export default connector(AdvancedFilterTable)
