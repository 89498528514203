// istanbul ignore file
import { z } from 'zod'
import interceptedAxios from '../../../utils/interceptedAxios'

export interface CreateGroupRequest {
  group_name: string
  description: string
  asset_type: string
  firmware_file: string
}

export const ZCreateGroupData = z.object({
  asset_group_id: z.string(),
  group_name: z.string()
})

const ZCreateGroupApiResponse = z.object({
  http_status: z.number(),
  data: ZCreateGroupData,
  error: z.boolean(),
  error_message: z.optional(z.string())
})

export type CreateGroupApiResponse = z.infer<typeof ZCreateGroupApiResponse>

export async function postCreateGroup (
  CreateGroupBody: CreateGroupRequest,
  onSuccess: (GroupData: any, httpStatus: number) => any,
  onFailure: (errorMessage: string, httpStatus: number) => any
): Promise<void> {
  const url = '/asset-service/api/v1/udp/firmwareGroupManagement/assignNewGroup'
  interceptedAxios
    .post(url, CreateGroupBody)
    .then((response) => {
      const parseResponse = ZCreateGroupApiResponse.safeParse(response.data)
      // istanbul ignore next
      if (parseResponse.success) {
        if (response.data.data !== null && response.data.data !== undefined) {
          onSuccess(parseResponse.data, response.status)
        }
      } else {
        onFailure(parseResponse.error.message, response.status)
      }
    })
    .catch((error) => {
      onFailure(error.message, error.response.status)
    })
}
