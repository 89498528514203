import interceptedAxios from '../../../utils/interceptedAxios'

export async function deleteGroupById (
  groupIds: string | string[],
  onSuccess: () => any,
  onFailure: () => any
): Promise<void> {
  const url = `/asset-service/api/v1/udp/groupManagement/delete?${Array.isArray(groupIds) ? `groupIds=${groupIds.join(',')}` : `groupIds=${groupIds}`}`

  interceptedAxios
    .delete(url)
    .then((_response) => {
      onSuccess()
    })
    .catch((_error) => {
      onFailure()
    })
}
