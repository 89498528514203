import { exportFileFormat } from '../../../utils/ExportFileUtils'
import interceptedAxios from '../../../utils/interceptedAxios'

export async function exportGroupDetailsService (
  groupName: string,
  assetGroupId: string,
  onSuccess: (exportFile: any) => any,
  onFailure: (errorMessage: string) => any
): Promise<void> {
  const url = `/asset-service/api/v1/udp/groupManagement/details/download?assetGroupId=${assetGroupId}`
  interceptedAxios
    .get(url, { responseType: 'blob' })
    .then((response: any) => {
      // istanbul ignore next
      exportFileFormat(response, groupName)
      onSuccess(response)
    })
    .catch((error) => {
      onFailure(error.message)
    })
}
