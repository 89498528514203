import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type ConfigurationListData } from '../services/getConfigurationProtocolList'

export interface ConfigurationListDataState {
  isLoading: boolean
  loadingError: string | undefined
  ConfigurationListData: ConfigurationListData[] | undefined
  httpStatus: number | undefined
  totalCount: number
}

// Initial state of the slice
const initialState: ConfigurationListDataState = {
  isLoading: true,
  loadingError: undefined,
  ConfigurationListData: undefined,
  httpStatus: undefined,
  totalCount: 0
}

// Slice to return from action
export const ConfigurationDetailsListDataState = createSlice({
  name: 'ConfigurationListData',
  initialState,
  reducers: {
    ConfigurationListLoadingAction: (state) => {
      // clear out the data whenever we reload
      return {
        isLoading: true,
        loadingError: undefined,
        ConfigurationListData: undefined,
        httpStatus: 0,
        totalCount: 0
      }
    },
    ConfigurationListUpdateAction: (state, action: PayloadAction<any>) => {
      return {
        isLoading: false,
        loadingError: undefined,
        ConfigurationListData: action.payload.data,
        httpStatus: action.payload.httpStatus,
        totalCount: action.payload.total_count
      }
    },
    ConfigurationListErrorAction: (state, action: PayloadAction<{ errorMessage: string, httpStatus: number }>) => {
      return {
        ...state,
        isLoading: false,
        loadingError: action.payload.errorMessage,
        httpStatus: action.payload.httpStatus,
        ConfigurationListData: undefined,
        totalCount: 0
      }
    }
  }
})

// Actions to export
export const {
  ConfigurationListLoadingAction,
  ConfigurationListUpdateAction,
  ConfigurationListErrorAction
} = ConfigurationDetailsListDataState.actions

export default ConfigurationDetailsListDataState.reducer
