/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import ArrowBackIos from '@mui/icons-material/ArrowBackIos'
import {
  Box,
  Breadcrumbs,
  CardContent,
  CircularProgress,
  Grid,
  Link,
  TextField,
  Typography,
  styled
} from '@mui/material'
import React, { useEffect, useReducer, useState } from 'react'
import LanguageStrings from '../../../../i18n/locales'
import { useNavigate } from 'react-router-dom'
import { GenXDropdown, type Option } from '../../../../components/GenXDropdown'
import { GenericDropdown } from '../../../../components/GenericDropdown'
import { type ConnectedProps, connect, useSelector } from 'react-redux'
import { type RootState } from '../../../../store'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { TimePicker } from '@mui/x-date-pickers'
import { Button } from '../../../../components/Button'
import dayjs, { type Dayjs } from 'dayjs'
import { useAuth } from 'react-oidc-context'
import './scheduler.css'
import Modal from '../../../../components/Modal'
import { fetchDeviceList, fetchAssetBySearchValue } from '../../../ami-meter-data-collection/redux/actionCreators'
import { fetchTemplateDetailsList, getAllCreateSchdulerDropdown, createReportSchedulerTemplate, getConfigurationListDetails } from '../../redux/actionCreators'
import { type CreateReportSchedulerTemplateState } from '../../redux/createReportSchedulerTemplateSlice'
import { isValidNameEdit } from '../../../../utils/validator'
import { type ReportSchedulerData } from '../../types'
import { type CreateSchedulerDropDownState } from '../../redux/getAllCreateSchdulerDropdownSlice'
import { fetchAssetGroupList } from '../../../asset-group/redux/actionCreator'
import { fetchAssetTypes } from '../../../asset-configuration/redux/actionCreator'
import { recurrencePatternStaticOptions, weekdays, MonthsInYear, HourlyFrequency, recurrencepatternhourly, GenericDateOptions } from '../ReportSchedulerComponents/schedulerData'
import AuthError from '../../../../components/ErrorComponents/AuthError'
import { type ConfigurationListDataState } from '../../redux/getConfigurationProtocolListSlice'
import { type AssetTypesState } from '../../../asset-configuration/redux/assetTypeSlice'
import { type AssetGroupsListState } from '../../../asset-group/redux/AssetGroupsListSlice'
import { type TemplateDetailsListState } from '../../redux/templateDetailsListSlice'
import { GenericError } from '../../../../components/ErrorComponents/GenericError'

const CreateSchedulerStrings =
  LanguageStrings().AMIDataCollectionStrings.ConfigurationUploadStrings
    .SchedulerStrings.CreateSchedulerFormStrings

const style = {
  width: '99%',
  borderRadius: '10px',
  border: '1px solid #101010',
  background: '#272727',
  boxShadow: '1px 1px 1px 0px rgba(0, 0, 0, 0.15)',
  fontSize: '16px',
  marginBottom: '16px',
  padding: '24px'
}

const WhiteDatePicker = styled(DatePicker)({
  '& input': {
    color: '#b8b6b6',
    padding: '10px 14px'
  }
})

const WhiteTimePicker = styled(TimePicker)({
  '& input': {
    color: '#b8b6b6',
    padding: '7px 7px'
  }
})

const CustomLocalizationProviderWrapper = styled('div')({
  backgroundColor: '#5c5b5bdd',
  borderRadius: '4px',
  width: '86%'
})

interface ITableData {
  reportTemplateId: string
  reportName: string
  reportType: string
  reportContent: Array<{
    columnDisplayName: string
    columnName: string
    columnOrder: number
    columnHeaderName: string
    columnId: string
  }>
}
interface IIntervalData {
  schedulerIntervalId: string
  schedulerInterval: string
}

const CreateReportScheduler = (props: PropsFromRedux): JSX.Element => {
  const navigate = useNavigate()
  const [selectedAssetType, setselectedAssetType] = useState<Option | null>(null)
  const [recurrencePatternOptions, setRecurrencePatternOptions] = useState<Option[] | null>(null)
  const [selectedAssetOptions, setselectedAssetOptions] = useState<Option[]>([])
  const [selectedGroupData, setGroupData] = useState<Option | null>(null)
  const [GroupDataOptions, setGroupDataOptions] = useState<Option[]>([])
  const [selectedAssetData, setAssetData] = useState<Option | null>(null)
  const [AssetDataOptions, setAssetDataOptions] = useState<Option[]>([])
  const [reportTemplate, setreportTemplate] = useState<Option | null>(null)
  const [selectedIntervalData, setSelectedIntervalData] = useState<Option | null>(null)
  const [selectedlocation, setselectedlocation] = useState<Option | null>(null)
  const [selectedlocationOptions, setselectedlocationOptions] = useState<Option[]>([])
  const [reportTemplateOptions, setreportTemplateOptions] = useState<Option[]>([])
  const [scheduleAssigemntOptions, setscheduleAssigemntNameOptions] = useState<Option[]>([])
  const [IntervalDataOptions, setIntervalDataOptions] = useState<Option[]>([])
  const [selectedScheduleAssigemnt, setSelectedscheduleAssigemnt] = useState<Option | null>(null)
  const [recurrencePattern, setRecurrencePattern] = useState<Option | null>(null)
  const [Monthday, setMonthDay] = useState<Option | null>(null)
  const [Weekday, setWeekDay] = useState<Option | null>(null)
  const [HourlyValue, setHourlyValue] = useState<Option | null>(null)
  const [selectAssetType, setselectAssetType] = useState(true)
  const [selectGroupType, setselectGroupType] = useState(false)
  const [selectDeviceType, setselectDeviceType] = useState(false)
  const [daily, setDaily] = useState(false)
  const [weekly, setweekly] = useState(false)
  const [monthly, setMonthly] = useState(false)
  const [yearly, setyearly] = useState(false)
  const [hourly, setHourly] = useState(false)
  const [Time, setTime] = useState(null)
  const defaultDateAsset = dayjs(new Date())
  const [startDate, setStartDate] = useState<Dayjs | null | undefined>(
    defaultDateAsset
  )
  const [endDate, setEndDate] = useState<Dayjs | null | undefined>(
    defaultDateAsset
  )
  const [monthDate, setMonthDate] = useState<Option | null>(null)
  const [confirm, setconfirm] = useState(false)
  const [showSuccessPopUp, setshowSuccessPopUp] = useState(false)
  const [reqError, setReqError] = useState<boolean>(false)
  const [selectSFTP, setselectSFTP] = useState<boolean>(false)
  const [dateAlertPopup, setdateAlertPopup] = useState(false)
  const scheduleAssigemntParameter = useSelector((state: RootState) => state.SchdulerDropDownListData.schdulerDropDownListData?.assignments)
  const intervalsData = useSelector((state: RootState) => state.SchdulerDropDownListData.schdulerDropDownListData?.intervals)
  const locationsData = useSelector((state: RootState) => state.SchdulerDropDownListData.schdulerDropDownListData?.locations)
  const tableData = useSelector((state: RootState) => state.templateDetailsListState.templateDetailsList)
  const groupData = useSelector((state: RootState) => state.assetGroupList.assetGroupsList?.group_details)
  const groupDataloading = useSelector((state: RootState) => state.assetGroupList.isLoading)
  const deviceData = useSelector((state: RootState) => state.deviceList.deviceList)
  const assetType = useSelector((state: RootState) => state.assetTypes.assetTypes)
  const createSchedulerResponce = useSelector((state: RootState) => state.CreateSchedulerTemplate)
  const configurationList = useSelector((state: RootState) => state.ConfigurationListData)
  const configurationListStatus = useSelector((state: RootState) => state.ConfigurationListData.httpStatus)
  const assetList = useSelector((state: RootState) => state.deviceList)
  const [protocolOptions, setProtocolOption] = useState<Option[]>([])
  const [selectedProtocolOption, setSelectedProtocolOption] = useState<Option | null>(null)
  const rowsPerPage = 10
  const auth = useAuth()
  const loadingOption = { value: 'loading', label: 'Loading...', isDisabled: true }
  const [parametersLoaded, setParametersLoaded] = useState<'started' | 'loaded' | 'failed'>('started')

  const initialState = {
    schedulerName: null,
    reportSchedulerId: null,
    reportTemplateId: null,
    schedulerIntervalId: null,
    schedulerLocationId: null,
    recurrencePattern: null,
    reportMonth: null,
    dayOfTheMonth: null,
    dayOfTheWeek: null,
    frequency: null,
    time: null,
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    startDate,
    endDate,
    schedulerAssignmentId: null,
    assignmentValues: null,
    connectionName: selectedProtocolOption?.label ?? '',
    protocolId: selectedProtocolOption?.label ?? ''
  }

  const [addState, dispatch] = useReducer(
    (curVal: any, newVal: any) => ({ ...curVal, ...newVal }),
    initialState
  )

  const getSchedulerParameters = (): void => {
    props.fetchDeviceList(0, 10, 'asc', 'id', auth.user?.profile.customer as string)
    props.getAllCreateSchdulerDropdown()
    props.fetchTemplateDetailsList()
    props.fetchAssetGroupList(0, 10, 'asc', 'assetGroupId', '')
    props.fetchAssetTypes()
    props.getConfigurationListDetails(0, 1000, 'desc', 'createdAt')
  }

  useEffect(() => {
    if (props.SchdulerDropDownListData.httpStatus === 200 && props.ConfigurationListData.httpStatus === 200 && props.reportTemplateList.httpStatus === 200) {
      setParametersLoaded('loaded')
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    } else if ((props.SchdulerDropDownListData.loadingError || props.ConfigurationListData.loadingError || props.reportTemplateList.error) && (!props.SchdulerDropDownListData.isLoading && !props.ConfigurationListData.isLoading && !props.reportTemplateList.isLoading)) {
      setParametersLoaded('failed')
    }
  }, [props.SchdulerDropDownListData, props.ConfigurationListData, props.reportTemplateList])

  useEffect(() => {
    getSchedulerParameters()
  }, [])

  const handleParameterRetry = (): void => {
    setParametersLoaded('started')
    if (props.assetTypeState.httpStatus !== 200) {
      props.fetchAssetTypes()
    }
    if (assetList.httpStatus !== 200) {
      props.fetchDeviceList(0, 10, 'asc', 'id', auth.user?.profile.customer as string)
    }
    if (props.groupList.httpStatus !== 200) {
      props.fetchAssetGroupList(0, 2000, 'asc', 'assetGroupId', '')
    }
    if (props.SchdulerDropDownListData.httpStatus !== 200) {
      props.getAllCreateSchdulerDropdown()
    }
    if (props.ConfigurationListData.httpStatus !== 200) {
      props.getConfigurationListDetails(0, 1000, 'desc', 'createdAt')
    }
    if (props.reportTemplateList.httpStatus !== 200) {
      props.fetchTemplateDetailsList()
    }
  }

  useEffect(() => {
    if (tableData !== undefined && tableData?.length > 0) {
      const reportTemplateoption: Option[] = []
      tableData?.forEach((item: any) => {
        reportTemplateoption.push({
          value: item.reportTemplateId,
          label: item.reportName
        })
      })
      setreportTemplateOptions(reportTemplateoption)
    }
  }, [tableData])

  useEffect(() => {
    if (intervalsData !== undefined && intervalsData.length > 0) {
      const intervalDataOptions: Option[] = []
      intervalsData.forEach((item: any) => {
        intervalDataOptions.push({
          value: item.schedulerIntervalId,
          label: item.schedulerInterval
        })
      })
      setIntervalDataOptions(intervalDataOptions)
    }
  }, [intervalsData])

  useEffect(() => {
    if (scheduleAssigemntParameter !== undefined && scheduleAssigemntParameter.length > 0) {
      const scheduleAssigemntOptions: Option[] = []
      scheduleAssigemntParameter?.forEach((item: any) => {
        scheduleAssigemntOptions.push({
          value: item.schedulerAssignmentId,
          label: item.schedulerAssignment
        })
      })
      setscheduleAssigemntNameOptions(scheduleAssigemntOptions)
    }
  }, [scheduleAssigemntParameter])

  useEffect(() => {
    if (locationsData !== undefined && locationsData.length > 0) {
      const locationsDataOptions: Option[] = []
      locationsData?.forEach((item: any) => {
        locationsDataOptions.push({
          value: item.schedulerLocationId,
          label: item.schedulerLocation
        })
      })
      setselectedlocationOptions(locationsDataOptions)
    }
  }, [locationsData])

  useEffect(() => {
    if (groupData !== undefined && groupData.length > 0) {
      const GroupDataOptions: Option[] = []
      groupData?.forEach((item: any) => {
        GroupDataOptions.push({
          value: item.group_id,
          label: item.group_name
        })
      })
      setGroupDataOptions(GroupDataOptions)
    } else {
      setGroupDataOptions([])
    }
  }, [groupData])

  useEffect(() => {
    if (assetType !== undefined && assetType.length > 0) {
      const AssetTypeDataOptions: Option[] = []
      assetType?.forEach((item: any) => {
        AssetTypeDataOptions.push({
          value: item.asset_type_id,
          label: item.asset_type_name
        })
      })
      setselectedAssetOptions(AssetTypeDataOptions)
    }
  }, [assetType])

  useEffect(() => {
    if (deviceData !== null && deviceData.length > 0) {
      const deviceDataOptions: Option[] = []
      deviceData?.forEach((item: any) => {
        deviceDataOptions.push({
          value: item.id,
          label: item.name
        })
      })
      setAssetDataOptions(deviceDataOptions)
    } else {
      setAssetDataOptions([])
    }
  }, [deviceData])

  useEffect(() => {
    if (configurationList?.httpStatus === 200 && configurationList?.ConfigurationListData !== undefined) {
      const options = configurationList.ConfigurationListData
        .filter((item) => item.protocolTypeName === selectedlocation?.label)
        .map((item) => ({
          label: item.connectionName,
          value: item.protocolId
        }))
      setProtocolOption(options)
    }
  }, [selectedlocation, configurationList])

  const handleSearchValue = (searchVal: string): Option[] => {
    const trimSearch = searchVal.trim()
    let filterdVal: Option[] = []
    if (trimSearch === '') {
      props.fetchDeviceList(0, 10, 'asc', 'id', auth.user?.profile.customer as string)
      filterdVal = AssetDataOptions.filter(AssetDataOptions => AssetDataOptions.label.includes(trimSearch))
      return filterdVal
    } else {
      props.fetchAssetBySearchValue(trimSearch, auth.user?.profile.customer as string, 0, rowsPerPage, 'id', 'asc')
      filterdVal = AssetDataOptions.filter(AssetDataOptions => AssetDataOptions.label.includes(trimSearch))
      return filterdVal
    }
  }

  const handleGroupSearchValue = (searchVal: string): Option[] => {
    props.fetchAssetGroupList(0, rowsPerPage, 'asc', 'assetGroupId', searchVal)
    return GroupDataOptions.filter(GroupDataOptions => GroupDataOptions.label.includes(searchVal))
  }

  const handleschedulerName = (name: string, value: string): void => {
    dispatch({ ...addState, schedulerName: value })
  }

  const handleconfirmClick = (): void => {
    props.createReportSchedulerTemplate(addState)
    setconfirm(false)
    setshowSuccessPopUp(true)
  }

  function getDatesFromMonth (year: number, month: Option | null): Option[] {
    const dates: Option[] = []
    if (month === null) {
      return dates
    }
    const monthIndex = parseInt(month.value) - 1

    const lastDay = new Date(year, monthIndex + 1, 0).getDate()
    for (let day = 1; day <= lastDay; day++) {
      dates.push({ label: day.toString(), value: day.toString() })
    }
    if (recurrencePattern?.label === 'Yearly' && yearly && monthDate?.label === 'February') {
      dates.push(...GenericDateOptions.slice(1, 3))
    }
    return dates
  }

  const handleOkandClose = (): void => {
    if (createSchedulerResponce.httpStatus === 201) {
      sessionStorage.setItem('currentSessionTab', '1')
      setshowSuccessPopUp(false)
      setconfirm(false)
      navigate('/datapub')
      clearAllDetails()
    } else {
      setshowSuccessPopUp(false)
      setconfirm(false)
    }
  }
  const handleBackNavigate = (): void => {
    sessionStorage.setItem('currentSessionTab', '1')
    navigate('/datapub')
  }

  const handleCreateSchedulrWithFailure = (): JSX.Element => {
    // istanbul ignore else
    if (createSchedulerResponce.httpStatus === 201) {
      return (
        <Modal
          buttonTypeNegative='hollow'
          title={CreateSchedulerStrings.SuccessTitle}
          LabelPositive={CreateSchedulerStrings.OkButton}
          onClickPositive={() => { handleOkandClose() }}
          onClose={() => { handleOkandClose() }}
        >
          <p style={{ paddingTop: '1.2em' }}>
            {createSchedulerResponce.httpStatus === 201 ? CreateSchedulerStrings.successMsg : createSchedulerResponce.error}
          </p>
        </Modal>
      )
    } else if (createSchedulerResponce.isLoading) {
      return (
        <Modal title={'Loading...'}>
          <div className='CircularprogressAmi'>
            <CircularProgress />
          </div>
        </Modal>
      )
    } else if (createSchedulerResponce.httpStatus === 400) {
      return (
        <Modal buttonTypeNegative='hollow'
          title={CreateSchedulerStrings.ErrorTitle}
          LabelPositive={CreateSchedulerStrings.OkButton}
          onClickPositive={() => { handleOkandClose() }}
          onClose={() => { handleOkandClose() }}
        >
          <p style={{ paddingTop: '1.2em' }}>
            {createSchedulerResponce.error}
          </p>
        </Modal>
      )
    } else if (createSchedulerResponce.httpStatus === 401) {
      return (
        <div className='authError'><AuthError errorMessage='Unauthorized' /></div>
      )
    } else if (createSchedulerResponce.httpStatus === 403) {
      return (
        <div className='authError'><AuthError errorMessage='accessForbidden' /></div>
      )
    } else {
      return (
        <Modal
          buttonTypeNegative='hollow'
          title={CreateSchedulerStrings.ConfirmTitle}
          LabelPositive={CreateSchedulerStrings.OkButton}
          onClickPositive={() => {
            setshowSuccessPopUp(false)
            setconfirm(false)
            navigate('/datapub')
          }}
          onClose={() => {
            setshowSuccessPopUp(false)
            setconfirm(false)
            navigate('/datapub')
          }}
        >
          <p style={{ paddingTop: '1.2em' }}>
            {createSchedulerResponce.error}
          </p>
        </Modal>
      )
    }
  }

  const handleReportTemplate = (selectedValue: Option): void => {
    setreportTemplate(selectedValue)
    setRecurrencePattern(null)
    dispatch({ ...addState, reportTemplateId: selectedValue.value, schedulerIntervalId: null })
    updateIntervalOption(selectedValue)
  }

  const updateIntervalOption = (selectedValue: Option): void => {
    setSelectedIntervalData(null)
    const obj = tableData?.find((o: ITableData) => o.reportTemplateId === selectedValue.value)

    if (intervalsData !== undefined && obj !== undefined) {
      let filteredIntervalsData = intervalsData

      if ((obj.reportType === '.cmep-1997' || obj.reportType === '.cmep-2008') && obj.reportName.includes('Meter Reads Report')) {
        filteredIntervalsData = intervalsData.filter((o: IIntervalData) => o.schedulerInterval === 'Last day' || o.schedulerInterval === 'Hourly')
      } else if (obj.reportType === '.cmep-1997' || obj.reportType === '.cmep-2008') {
        filteredIntervalsData = intervalsData.filter(
          (o: IIntervalData) => o.schedulerInterval === 'Last day'
        )
      } else {
        filteredIntervalsData = intervalsData.filter(
          (o: IIntervalData) => o.schedulerInterval === 'Last day' || o.schedulerInterval === 'Last week' || o.schedulerInterval === 'Last month'
        )
      }

      setIntervalDataOptions(convertIntervalDataToOption(filteredIntervalsData))
    } else {
      setIntervalDataOptions(convertIntervalDataToOption(intervalsData))
    }
  }

  const convertIntervalDataToOption = (interval: IIntervalData[] | undefined): Option[] => {
    if (interval !== undefined && interval.length > 0) {
      const intervalsDataOptions: Option[] = []
      interval.forEach((item: IIntervalData) => {
        intervalsDataOptions.push({
          value: item.schedulerIntervalId,
          label: item.schedulerInterval
        })
      })
      return intervalsDataOptions
    }
    return []
  }

  const clearAllDetails = (): void => {
    dispatch({ ...addState, schedulerName: '' })
    setreportTemplate(null)
    setSelectedscheduleAssigemnt(null)
    setAssetData(null)
    setGroupData(null)
    setselectedAssetType(null)
    setSelectedIntervalData(null)
    setselectedlocation(null)
    setRecurrencePattern(null)
    setStartDate(null)
    setEndDate(null)
    setMonthDate(null)
    setMonthDay(null)
    setWeekDay(null)
    setTime(null)
    setHourlyValue(null)
  }

  const isValidlength = (value: string): boolean => {
    return value.length >= 5 && value.length <= 60
  }

  // istanbul ignore next
  const getDatesForCurrentMonth = (): Option[] => {
    const currentYear = new Date().getFullYear()
    const currentMonth = new Date().getMonth()
    const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate()
    const dates: Option[] = []

    for (let day = 1; day <= daysInMonth; day++) {
      dates.push({ value: day.toString(), label: day.toString() })
    }

    if (monthly && recurrencePattern?.label === 'Monthly') {
      dates.push(...GenericDateOptions)
    }

    return dates
  }

  const handleScheduleAssigemnt = (selectedValue: Option): void => {
    setSelectedscheduleAssigemnt(selectedValue)
    setAssetData(null)
    setGroupData(null)
    setselectedAssetType(null)

    if (selectedValue.label === 'Asset') {
      setselectAssetType(true)
      setselectGroupType(false)
      setselectDeviceType(false)
    } else if (selectedValue.label === 'Asset group') {
      setselectAssetType(false)
      setselectGroupType(true)
      setselectDeviceType(false)
    } else if (selectedValue.label === 'Asset type') {
      setselectAssetType(false)
      setselectGroupType(false)
      setselectDeviceType(true)
    } else if (selectedValue.label === 'All asset') {
      setselectAssetType(false)
      setselectGroupType(false)
      setselectDeviceType(false)
    }
    dispatch({ ...addState, schedulerAssignmentId: selectedValue.value, assignmentValues: null })
  }

  const handleGroupAssigemnt = (name: string, value: string): void => {
    setGroupData({ label: value !== undefined ? value : '', value })
    dispatch({ ...addState, assignmentValues: [value] })
  }

  const handleAssetAssigemnt = (name: string, value: string): void => {
    setAssetData({ label: value !== undefined ? value : '', value })
    // const assetName = assetList?.deviceList?.find((item: { id: any }) => item.id === value)?.name
    dispatch({ ...addState, assignmentValues: [value] })
  }

  const handleDeviceTypeAssigemnt = (selectedValue: Option): void => {
    setselectedAssetType(selectedValue)
    dispatch({ ...addState, assignmentValues: [selectedValue.value] })
  }

  const handleIntervalData = (selectedValue: Option): void => {
    setSelectedIntervalData(selectedValue)
    setRecurrencePattern(null)
    setHourlyValue(null)
    if (selectedValue.label === 'Hourly') {
      setRecurrencePatternOptions(recurrencepatternhourly)
    } else {
      setRecurrencePatternOptions(recurrencePatternStaticOptions)
    }
    dispatch({ ...addState, schedulerIntervalId: selectedValue.value })
  }

  const handleLocationData = (selectedValue: Option): void => {
    if (selectedValue.value !== selectedlocation?.value) {
      setselectedlocation(selectedValue)
      setselectSFTP(true)
      setProtocolOption([])
      setSelectedProtocolOption(null)
      dispatch({ ...addState, schedulerLocationId: selectedValue.value })
    }
  }

  const handleStartDate = (val: any): void => {
    setStartDate(val)
    dispatch({ ...addState, startDate: val })
  }

  const handleEndDate = (val: any): void => {
    setEndDate(val)
    dispatch({ ...addState, endDate: val })
  }

  const handleMonthsInYear = (selectedValue: Option): void => {
    setMonthDay(null)
    setTime(null)
    setWeekDay(null)
    setMonthDate(selectedValue)
    dispatch({ ...addState, reportMonth: selectedValue.value })
  }

  const handleRecurrencePattern = (selectedValue: Option): void => {
    setRecurrencePattern(selectedValue)
    setMonthDay(null)
    setMonthDate(null)
    setTime(null)
    setWeekDay(null)
    if (selectedValue.value === 'Daily') {
      setDaily(true)
      setMonthly(false)
      setyearly(false)
      setweekly(false)
      setHourly(false)
    } else if (selectedValue.value === 'Weekly') {
      setweekly(true)
      setDaily(true)
      setMonthly(false)
      setyearly(false)
      setHourly(false)
    } else if (selectedValue.value === 'Monthly') {
      setweekly(false)
      setMonthly(true)
      setDaily(true)
      setyearly(false)
      setHourly(false)
    } else if (selectedValue.value === 'Yearly') {
      setyearly(true)
      setMonthly(true)
      setDaily(true)
      setweekly(false)
      setHourly(false)
    } else if (selectedValue.value === 'Hourly') {
      setHourly(true)
      setDaily(false)
      setMonthly(false)
      setyearly(false)
      setweekly(false)
    }
    dispatch({ ...addState, recurrencePattern: selectedValue.value })
  }

  const handleMonthday = (selectedValue: Option): void => {
    setMonthDay(selectedValue)
    setTime(null)
    setWeekDay(null)
    if (selectedValue.value === '29' || selectedValue.value === '30' || selectedValue.value === '31') {
      setdateAlertPopup(true)
    }
    dispatch({ ...addState, dayOfTheMonth: selectedValue.value })
  }

  const handleWeeklyDay = (selectedValue: Option): void => {
    setWeekDay(selectedValue)
    dispatch({ ...addState, dayOfTheWeek: selectedValue.value })
  }

  const handleHourly = (selectedValue: Option): void => {
    setHourlyValue(selectedValue)
    dispatch({ ...addState, frequency: selectedValue.value })
  }

  const handleSelectedTime = (val: any, Time: string): void => {
    const options = {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    }
    const value = val.$d.toLocaleTimeString('en-US', options)
    setTime(val)
    dispatch({ ...addState, time: value })
  }

  const checkIfEditInfoValid = (): boolean => {
    return (JSON.stringify(initialState) === JSON.stringify(addState))
  }

  const validateFields = (): void => {
    if (
      addState.schedulerName !== null &&
      addState.reportTemplateId !== null &&
      addState.schedulerAssignmentId !== null &&
      addState.schedulerIntervalId !== null &&
      addState.schedulerLocationId !== null &&
      addState.startDate !== null &&
      addState.endDate !== null &&
      addState.connectionName !== null &&
      configurationListStatus === 200
    ) {
      if (addState.schedulerName) {
        if (!isValidNameEdit(addState.schedulerName)) {
          setReqError(true)
          return
        }
      }

      if (addState.schedulerName) {
        if (!isValidlength(addState.schedulerName)) {
          setReqError(true)
          return
        }
      }

      if (addState.recurrencePattern === null) {
        setReqError(true)
        return
      }

      if (!addState.protocolId) {
        setReqError(true)
        return
      }

      if (recurrencePattern?.label === 'Hourly') {
        if (HourlyValue === null) {
          setReqError(true)
          return
        }
      }

      if (recurrencePattern?.label === 'Daily') {
        if (Time === null) {
          setReqError(true)
          return
        }
      }

      if (recurrencePattern?.label === 'Weekly') {
        if (Time === null) {
          setReqError(true)
          return
        } else if (Weekday === null) {
          setReqError(true)
          return
        }
      }

      if (recurrencePattern?.label === 'Monthly') {
        if (Time === null) {
          setReqError(true)
          return
        } else if (Monthday === null) {
          setReqError(true)
          return
        }
      }

      if (recurrencePattern?.label === 'Yearly') {
        if (Time === null) {
          setReqError(true)
          return
        } else if (monthDate === null) {
          setReqError(true)
          return
        } else if (Monthday === null) {
          setReqError(true)
          return
        }
      }
      if (selectedScheduleAssigemnt?.label === 'Asset') {
        if (selectedAssetData === null) {
          setReqError(true)
          return
        }
      } else if (selectedScheduleAssigemnt?.label === 'Group') {
        if (selectedGroupData === null) {
          setReqError(true)
          return
        }
      } else if (selectedScheduleAssigemnt?.label === 'Asset type') {
        if (selectedAssetType === null) {
          setReqError(true)
          return
        }
      }
      setconfirm(true)
    } else {
      setReqError(true)
    }
  }

  // istanbul ignore next
  const getLoadedSchedulerUI = (): JSX.Element => {
    return (
      <><Box sx={style} className='add-Scheduler-container'>
        <CardContent>
          <Grid container spacing={1}>
            <Grid item md={3}>
              <span style={{ fontSize: '15px', padding: 10, display: 'block' }}>
                {CreateSchedulerStrings.ScheduleName}
                <sup style={{ color: '#EE3426' }}>*</sup>
              </span>
              <TextField
                id='scheduler-name'
                variant='outlined'
                data-testId='scheduler-name'
                placeholder='Enter Name'
                InputLabelProps={{
                  disabled: true
                }}
                value={addState.schedulerName}
                onChange={(e) => {
                  handleschedulerName('schedulerName', e.target.value)
                } }
                size='small'
                autoComplete='off'
                className='scheduler-name-class'
                sx={{
                  width: '270px',
                  color: '#fffff !important'
                }} />
              {addState.schedulerName !== null && !isValidNameEdit(addState.schedulerName) && (
                <div className='error-response'>
                  <span className='error-upload-message'>
                    {CreateSchedulerStrings.enterValidText}
                  </span>
                </div>
              )}
              {reqError && !addState.schedulerName && (
                <div className='error-response'>
                  <span className='error-upload-message'>{CreateSchedulerStrings.required}</span>
                </div>
              )}
              {addState.schedulerName !== null && !isValidlength(addState.schedulerName) && (
                <div className='error-response'>
                  <span className='error-upload-message'>
                    {CreateSchedulerStrings.enterMoreText}
                  </span>
                </div>
              )}
            </Grid>
            <Grid item md={3}>
              <span style={{ fontSize: '15px', padding: 10, display: 'block' }}>
                {CreateSchedulerStrings.reportTemplateSelection}
                <sup style={{ color: '#EE3426' }}>*</sup>
              </span>
              <GenXDropdown
                placeholder='Select Report Template'
                value={reportTemplate}
                options={reportTemplateOptions}
                onSelect={handleReportTemplate}
                width={270} />
              {reqError && !addState.reportTemplateId && (
                <div className='error-response'>
                  <span className='error-upload-message'>{CreateSchedulerStrings.required}</span>
                </div>
              )}
            </Grid>
            <Grid item md={3}>
              <span style={{ fontSize: '15px', padding: 10, display: 'block' }}>
                {CreateSchedulerStrings.schedulerAssignment}
                <sup style={{ color: '#EE3426' }}>*</sup>
              </span>
              <GenXDropdown
                placeholder='Select Assignment'
                value={selectedScheduleAssigemnt}
                options={scheduleAssigemntOptions}
                onSelect={handleScheduleAssigemnt}
                width={270} />
              {reqError && !addState.schedulerAssignmentId && (
                <div className='error-response'>
                  <span className='error-upload-message'>{CreateSchedulerStrings.required}</span>
                </div>
              )}
            </Grid>
            {selectAssetType && (
              <Grid item md={3}>
                <span
                  style={{ fontSize: '15px', padding: 10, display: 'block' }}
                >
                  {CreateSchedulerStrings.assetsAssignment}
                  <sup style={{ color: '#EE3426' }}>*</sup>
                </span>
                <GenericDropdown
                  value={selectedAssetData !== null ? selectedAssetData.label : ''}
                  options={assetList.isLoading ? [loadingOption] : AssetDataOptions}
                  width={270}
                  placeholder={'Select an Asset'}
                  allowInput={true}
                  menuStyle={{ maxHeight: '220px' }}
                  handleSearchInput={handleSearchValue}
                  onChange={(e) => { handleAssetAssigemnt('selectedAsset', e.target.value) } }
                  label={''} dataTestId={''} />
                {reqError && !selectedAssetData && (
                  <div className='error-response'>
                    <span className='error-upload-message'>{CreateSchedulerStrings.required}</span>
                  </div>
                )}
              </Grid>
            )}
            {selectGroupType && (
              <Grid item md={3}>
                <span
                  style={{ fontSize: '15px', padding: 10, display: 'block' }}
                >
                  {CreateSchedulerStrings.assetsAssignment}
                  <sup style={{ color: '#EE3426' }}>*</sup>
                </span>
                <GenericDropdown
                  value={selectedGroupData !== null ? selectedGroupData.label : ''}
                  options={groupDataloading ? [loadingOption] : GroupDataOptions}
                  width={270}
                  placeholder={'Select Asset Group'}
                  allowInput={true}
                  menuStyle={{ maxHeight: '220px' }}
                  handleSearchInput={handleGroupSearchValue}
                  onChange={(e) => { handleGroupAssigemnt('selectedAsset', e.target.value) } }
                  label={''} dataTestId={''} />
                {reqError && !selectedGroupData && (
                  <div className='error-response'>
                    <span className='error-upload-message'>{CreateSchedulerStrings.required}</span>
                  </div>
                )}
              </Grid>
            )}
            {selectDeviceType && (
              <Grid item md={3}>
                <span
                  style={{ fontSize: '15px', padding: 10, display: 'block' }}
                >
                  {CreateSchedulerStrings.assetsAssignment}
                  <sup style={{ color: '#EE3426' }}>*</sup>
                </span>
                <GenXDropdown
                  placeholder='Select Asset Type'
                  value={selectedAssetType}
                  options={selectedAssetOptions}
                  onSelect={handleDeviceTypeAssigemnt}
                  width={270} />
                {reqError && !selectedAssetType && (
                  <div className='error-response'>
                    <span className='error-upload-message'>{CreateSchedulerStrings.required}</span>
                  </div>
                )}
              </Grid>
            )}
          </Grid>
        </CardContent>
        <CardContent>
          <Grid container spacing={1}>
            <Grid item md={3}>
              <span style={{ fontSize: '15px', padding: 10, display: 'block' }}>
                {CreateSchedulerStrings.dataIntervalSelection}
                <sup style={{ color: '#EE3426' }}>*</sup>
              </span>
              <GenXDropdown
                placeholder='Select Interval'
                value={selectedIntervalData}
                options={IntervalDataOptions}
                onSelect={handleIntervalData}
                width={270} />
              {reqError && !addState.schedulerIntervalId && (
                <div className='error-response'>
                  <span className='error-upload-message'>{CreateSchedulerStrings.required}</span>
                </div>
              )}
            </Grid>
            <Grid item md={3}>
              <span style={{ fontSize: '15px', padding: 10, display: 'block' }}>
                {CreateSchedulerStrings.location}
                <sup style={{ color: '#EE3426' }}>*</sup>
              </span>
              <GenXDropdown
                placeholder='Select Location'
                data-testid='location'
                value={selectedlocation}
                options={selectedlocationOptions}
                onSelect={handleLocationData}
                width={270} />
              {reqError && !addState.schedulerLocationId && (
                <div className='error-response'>
                  <span className='error-upload-message'>{CreateSchedulerStrings.required}</span>
                </div>
              )}
            </Grid>
            {selectSFTP && (<Grid item md={3}>
              <span style={{ fontSize: '15px', padding: 10, display: 'block' }}>
                {'Configuration Name'}
                <sup style={{ color: '#EE3426' }}>*</sup>
              </span>
              <GenXDropdown
                placeholder='Select Connection'
                data-testid='connections'
                value={selectedProtocolOption}
                options={protocolOptions}
                disabled={protocolOptions.length === 0}
                onSelect={(item) => {
                  dispatch({ ...addState, protocolId: item?.value, connectionName: item.label })
                  setSelectedProtocolOption(item)
                } }
                width={270} />
              <div className="row">
                {protocolOptions.length === 0 && (
                  <div style={{ fontSize: '12px' }}>
                    {CreateSchedulerStrings.connectionNotAvilable}
                    <span
                      style={{ color: '#1976d2', cursor: 'pointer' }}
                      onClick={() => { navigate('/create-sftp-configuration') } }
                    >
                      here.
                    </span>
                  </div>
                )}
                {reqError && !selectedProtocolOption && (
                  <div className='error-response'>
                    <span className='error-upload-message'>{CreateSchedulerStrings.required}</span>
                  </div>
                )}
              </div>
            </Grid>)}
          </Grid>
        </CardContent>
      </Box><Box sx={style} className='add-Scheduler-container'>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item md={3}>
                <span style={{ fontSize: '15px', padding: 10, display: 'block' }}>
                  {CreateSchedulerStrings.RecurrencePattern}
                  <sup style={{ color: '#EE3426' }}>*</sup>
                </span>
                <GenXDropdown
                  placeholder='Select Recurrence Pattern'
                  data-testid='recurrence'
                  value={recurrencePattern}
                  options={recurrencePatternOptions ?? []}
                  onSelect={handleRecurrencePattern}
                  width={270} />
                {reqError && !addState.recurrencePattern && (
                  <div className='error-response'>
                    <span className='error-upload-message'>{CreateSchedulerStrings.required}</span>
                  </div>
                )}
              </Grid>
              {yearly && recurrencePattern !== null && (
                <Grid item md={3}>
                  <span
                    style={{ fontSize: '15px', padding: 10, display: 'block' }}
                  >
                    {CreateSchedulerStrings.Month}
                    <sup style={{ color: '#EE3426' }}>*</sup>
                  </span>
                  <GenXDropdown
                    placeholder='Select Month'
                    data-testid='month'
                    value={monthDate}
                    options={MonthsInYear}
                    onSelect={handleMonthsInYear}
                    width={270} />
                  {reqError && !addState.reportMonth && (
                    <div className='error-response'>
                      <span className='error-upload-message'>{CreateSchedulerStrings.required}</span>
                    </div>
                  )}
                </Grid>
              )}
              {monthly && recurrencePattern !== null && (
                <Grid item md={3}>
                  <span
                    style={{ fontSize: '15px', padding: 10, display: 'block' }}
                  >
                    {CreateSchedulerStrings.Date}
                    <sup style={{ color: '#EE3426' }}>*</sup>
                  </span>
                  {recurrencePattern.value === 'Yearly'
                    ? <GenXDropdown
                      placeholder='Select Date'
                      data-testId='month'
                      value={Monthday}
                      options={getDatesFromMonth(new Date().getFullYear(), monthDate)}
                      onSelect={handleMonthday}
                      width={270} />
                    : <GenXDropdown
                      placeholder='Select Date'
                      data-testId='month'
                      value={Monthday}
                      options={getDatesForCurrentMonth()}
                      onSelect={handleMonthday}
                      width={270} />}
                  {reqError && !addState.dayOfTheMonth && (
                    <div className='error-response'>
                      <span className='error-upload-message'>{CreateSchedulerStrings.required}</span>
                    </div>
                  )}
                </Grid>
              )}
              {weekly && recurrencePattern !== null && (
                <Grid item md={3}>
                  <span
                    style={{ fontSize: '15px', padding: 10, display: 'block' }}
                  >
                    {CreateSchedulerStrings.selectDay}
                    <sup style={{ color: '#EE3426' }}>*</sup>
                  </span>
                  <GenXDropdown
                    placeholder='Select Day'
                    data-testId='weekdays'
                    value={Weekday}
                    options={weekdays}
                    onSelect={handleWeeklyDay}
                    width={270} />
                  {reqError && !addState.dayOfTheWeek && (
                    <div className='error-response'>
                      <span className='error-upload-message'>{CreateSchedulerStrings.required}</span>
                    </div>
                  )}
                </Grid>
              )}
              {hourly && recurrencePattern !== null && (
                <Grid item md={3}>
                  <span
                    style={{ fontSize: '15px', padding: 10, display: 'block' }}
                  >
                    {CreateSchedulerStrings.hourly}
                    <sup style={{ color: '#EE3426' }}>*</sup>
                  </span>
                  <GenXDropdown
                    placeholder='Select Frequency'
                    data-testId='hourly'
                    value={HourlyValue}
                    options={HourlyFrequency}
                    onSelect={handleHourly}
                    width={270} />
                  {reqError && !addState.frequency && (
                    <div className='error-response'>
                      <span className='error-upload-message'>{CreateSchedulerStrings.required}</span>
                    </div>
                  )}
                </Grid>
              )}
              {daily && recurrencePattern !== null && (
                <Grid item md={3}>
                  <span
                    style={{ fontSize: '15px', padding: 10, display: 'block' }}
                  >
                    {CreateSchedulerStrings.time}
                    <sup style={{ color: '#EE3426' }}>*</sup>
                  </span>
                  <div style={{ marginTop: '0px' }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <WhiteTimePicker
                        className='time-picker-Scheduler'
                        data-testid='time-picker'
                        views={['hours', 'minutes']}
                        value={Time}
                        onChange={(val: any) => {
                          handleSelectedTime(val, 'Time')
                        } } />
                    </LocalizationProvider>
                  </div>
                  {reqError && !addState.time && (
                    <div className='error-response'>
                      <span className='error-upload-message'>{CreateSchedulerStrings.required}</span>
                    </div>
                  )}
                </Grid>
              )}
            </Grid>
          </CardContent>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item md={3}>
                <span style={{ fontSize: '15px', padding: 10, display: 'block' }}>
                  {CreateSchedulerStrings.startDate}
                  <sup style={{ color: '#EE3426' }}>*</sup>
                </span>
                <CustomLocalizationProviderWrapper>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <WhiteDatePicker
                      data-testid='date-picker'
                      disablePast
                      // defaultValue={defaultDateAsset}
                      slotProps={{ textField: { fullWidth: true }, field: { readOnly: true } }}
                      value={startDate}
                      onAccept={(val: any) => {
                        handleStartDate(dayjs(val))
                      } } />
                  </LocalizationProvider>
                </CustomLocalizationProviderWrapper>
                {reqError && !addState.startDate && (
                  <div className='error-response'>
                    <span className='error-upload-message'>{CreateSchedulerStrings.required}</span>
                  </div>
                )}
              </Grid>
              <Grid item md={3}>
                <span style={{ fontSize: '15px', padding: 10, display: 'block' }}>
                  {CreateSchedulerStrings.endDate}
                  <sup style={{ color: '#EE3426' }}>*</sup>
                </span>
                <CustomLocalizationProviderWrapper>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <WhiteDatePicker
                      disablePast
                      // defaultValue={defaultDateAsset}
                      slotProps={{ textField: { fullWidth: true }, field: { readOnly: true } }}
                      value={endDate}
                      onAccept={(val: any) => {
                        handleEndDate(dayjs(val))
                      } } />
                  </LocalizationProvider>
                </CustomLocalizationProviderWrapper>
                {reqError && !addState.endDate && (
                  <div className='error-response'>
                    <span className='error-upload-message'>{CreateSchedulerStrings.required}</span>
                  </div>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Box></>
    )
  }

  const getErrorForNewSchedulerLoad = (): any[] => {
    // props.SchdulerDropDownListData, props.ConfigurationListData, props.reportTemplateList
    const error: any[] = []
    if (props.reportTemplateList.httpStatus !== 200 && !props.reportTemplateList.isLoading) {
      error.push('Error fetching in Report Templates')
    }
    if (props.SchdulerDropDownListData.httpStatus !== 200 && !props.SchdulerDropDownListData.isLoading) {
      error.push('Error fetching in Metadata')
    }
    if (props.ConfigurationListData.httpStatus !== 200 && !props.ConfigurationListData.isLoading) {
      if (props.ConfigurationListData.httpStatus === 404 && protocolOptions.length === 0) {
        error.push(
          <>
            {CreateSchedulerStrings.connectionNotAvilable}
            <span
              style={{ color: '#1976d2', cursor: 'pointer', marginLeft: '5px' }}
              onClick={() => { navigate('/create-sftp-configuration') } }
            >
              here.
            </span>
          </>
        )
      } else {
        error.push(props.ConfigurationListData.loadingError ?? 'Error fetching in Protocols')
      }
    }
    if (error.length === 0) {
      error.push('CANNOT FETCH DATA')
    }
    return error
  }

  // istanbul ignore next
  const handleSchedulerUIConditionally = (): JSX.Element => {
    const errors = getErrorForNewSchedulerLoad()
    if (parametersLoaded === 'loaded') {
      return getLoadedSchedulerUI()
    } else if (parametersLoaded === 'started') {
      return <div className='CircularprogressAmi'>
        <CircularProgress />
      </div>
    } else {
      return (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <GenericError style={{ width: '35%' }} errorItemStyle={{ margin: 0, listStyleType: errors.length === 1 ? 'none' : 'unset' }} retry={handleParameterRetry} error={getErrorForNewSchedulerLoad()} />
        </div>
      )
    }
  }

  const handleCreateScheduler = (): JSX.Element => {
    return (
        <>
          <div style={{ minHeight: '65vh' }}>
            {handleSchedulerUIConditionally()}
          </div>
          <Box sx={style} className='add-Scheduler-container'>
                <Grid container spacing={1}>
                  <Grid style={{ textAlign: 'left' }} item md={6}>
                    <Button type='hollow' data-testid='cancel-btn' onClick={() => {
                      handleBackNavigate()
                      clearAllDetails()
                    }}>
                      {CreateSchedulerStrings.CancelBtn}
                    </Button>
                  </Grid>
                  <Grid style={{ textAlign: 'right' }} item md={6}>
                    <Button
                      onClick={() => {
                        validateFields()
                      }}
                      primaryColor={checkIfEditInfoValid() ? '#B0B0B0' : undefined}
                      disabled={checkIfEditInfoValid()}
                    >
                      {CreateSchedulerStrings.CreateBtn}
                    </Button>
                  </Grid>
                </Grid>
          </Box>
        </>
    )
  }

  function checkIfTimeIsPast (startDate: any, time: any): boolean {
    // handle dates with dayjs
    const currentDate = dayjs()
    const selectedDate = dayjs(startDate)
    const selectedTime = dayjs(time)
    const selectedDateTime = selectedDate.set('hour', selectedTime.hour()).set('minute', selectedTime.minute())
    return currentDate.isAfter(selectedDateTime)
  }

  return (
    <>
      <div
        role='presentation'
        data-testid='breadcrumbs'
        style={{ width: '100%' }}
      >
        <Breadcrumbs
          aria-name='breadcrumb'
          sx={{
            color: '#f0f0f0',
            fontSize: '12px',
            marginBottom: '14px',
            fontFamily: 'Honeywell Sans Web',
            marginTop: '12px'
          }}
        >
          <Link
            underline='hover'
            color='inherit'
            data-testid='scheduler-breadcrumb'
            onClick={() => {
              navigate('/datapub')
            }}
          >
            {CreateSchedulerStrings.dataPublisher}
          </Link>
          <Typography
            color='inherit'
            sx={{ fontSize: '12px', fontFamily: 'Honeywell Sans Web' }}
          >
            {CreateSchedulerStrings.createNewSchedule}
          </Typography>
        </Breadcrumbs>
      </div>
      <div style={{ display: 'flex' }}>
        <ArrowBackIos
          sx={{ cursor: 'pointer' }}
          data-testid='back-arrow'
          onClick={() => {
            handleBackNavigate()
          }}
        />
        <div style={{ marginBottom: '32px' }}>
          <span
            data-testid='schedule-title'
            style={{
              color: '#f0f0f0',
              fontSize: '24px',
              fontWeight: '900',
              paddingRight: '16px',
              marginRight: '10px'
            }}
          >
            {CreateSchedulerStrings.createNewSchedule}
          </span>
        </div>
      </div>
      {handleCreateScheduler()}
      {confirm && (
        <Modal
          width='600px'
          titleStyle={{ paddingRight: '2em', marginBottom: '20px' }}
          buttonTypeNegative='hollow'
          LabelPositive={CreateSchedulerStrings.ConfirmBtn}
          LabelNegative={CreateSchedulerStrings.CancelBtn}
          onClickPositive={() => {
            handleconfirmClick()
          }}
          onClose={() => {
            setconfirm(false)
          }}
          onClickNegative={() => {
            setconfirm(false)
          }}
          title={CreateSchedulerStrings.ConfirmTitle}
        >
          <div className='confirm-msg' style={{ marginBottom: '30px' }}>
            <span>{CreateSchedulerStrings.confirmMsg}</span>
            {/* check is startTime is todays and selected time is already past then show warning */}
            {
              addState.startDate && Time && checkIfTimeIsPast(addState.startDate, Time) && (
                <>
                  <br />
                  <span style={{ fontWeight: 'bold' }}>
                    {CreateSchedulerStrings.WarningForSelectingPastTime}
                  </span>
                </>
              )
            }
          </div>
        </Modal>
      )}
      {showSuccessPopUp && handleCreateSchedulrWithFailure()
      }
      {dateAlertPopup && (
        <Modal
          buttonTypeNegative='hollow'
          title={CreateSchedulerStrings.DateAlertTitle}
          LabelPositive='Ok'
          onClickPositive={() => {
            setdateAlertPopup(false)
          }}
          onClose={() => {
            setdateAlertPopup(false)
          }}
        >
          <p style={{ paddingTop: '1.2em' }}>
            {CreateSchedulerStrings.dateAlert}
          </p>
        </Modal>
      )}
    </>
  )
}

interface DispatchToProps {
  getAllCreateSchdulerDropdown: () => void
  createReportSchedulerTemplate: (createReportSchedulerTemplatePostRequest: ReportSchedulerData) => void
  fetchTemplateDetailsList: () => void
  fetchAssetGroupList: (page: number, size: number, sortdir: string, sortfield: string, input: string) => void
  fetchDeviceList: (page: number, size: number, sortdir: string, sortfield: string, tenantId: string) => void
  fetchAssetBySearchValue: (searchVal: string, tenantId: string, page: number, size: number, sortfield: string, sortdir: string) => void
  fetchAssetTypes: () => void
  getConfigurationListDetails: (page: number, size: number, sortdir: string, sortfield: string) => void
}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  getAllCreateSchdulerDropdown: () => dispatch(getAllCreateSchdulerDropdown()),
  fetchTemplateDetailsList: () => dispatch(fetchTemplateDetailsList()),
  createReportSchedulerTemplate: (createReportSchedulerTemplatePostRequest: ReportSchedulerData) => dispatch(createReportSchedulerTemplate(createReportSchedulerTemplatePostRequest)),
  fetchAssetGroupList: (page: number, size: number, sortdir: string, sortfield: string, input: string) => dispatch(fetchAssetGroupList(page, size, sortdir, sortfield, input)),
  fetchDeviceList: (page: number, size: number, sortdir: string, sortfield: string, tenantId: string) => dispatch(fetchDeviceList(page, size, sortdir, sortfield, tenantId)),
  fetchAssetBySearchValue: (searchVal: string, tenantId: string, page: number, size: number, sortfield: string, sortdir: string) => dispatch(fetchAssetBySearchValue(searchVal, tenantId, page, size, sortfield, sortdir)),
  fetchAssetTypes: () => dispatch(fetchAssetTypes()),
  getConfigurationListDetails: (page: number, size: number, sortdir: string, sortfield: string) =>
    dispatch(getConfigurationListDetails(page, size, sortdir, sortfield))
})

interface StateToProps {
  SchdulerDropDownListData: CreateSchedulerDropDownState
  CreateSchedulerTemplate: CreateReportSchedulerTemplateState
  ConfigurationListData: ConfigurationListDataState
  assetTypeState: AssetTypesState
  groupList: AssetGroupsListState
  reportTemplateList: TemplateDetailsListState
}

const mapStateToProps = (state: RootState): StateToProps => ({
  SchdulerDropDownListData: state.SchdulerDropDownListData,
  CreateSchedulerTemplate: state.CreateSchedulerTemplate,
  ConfigurationListData: state.ConfigurationListData,
  assetTypeState: state.assetTypes,
  groupList: state.assetGroupList,
  reportTemplateList: state.templateDetailsListState
})

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(CreateReportScheduler)
