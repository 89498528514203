import interceptedAxios from '../../../utils/interceptedAxios'
import { z } from 'zod'

// Define the request schema
const ZGetFileRequest = z.object({
  fileName: z.string().regex(/^[a-zA-Z0-9.]*$/, {
    message: 'File name must contain only alphanumeric characters and dots'
  })
})

export type GetFileRequest = z.infer<typeof ZGetFileRequest>
export interface GetFileResponse {
  blob: Blob // Changed from File to Blob for raw data
  fileName: string
  contentType: string
}

/**
 * Service to fetch a file from the API and open it in a new tab
 * @param fileName - The name of the file to fetch
 */
export async function openFileInNewTab (fileName: string): Promise<void> {
  const url = `/fileuploadservice/api/v1/udp/ami/devices/resources/files?fileName=${fileName}`

  interceptedAxios.get(url, { responseType: 'blob' })
    .then((response) => {
      if (response.status === 200 && response.headers['content-type'] !== undefined) {
        const blob = new Blob([response.data], { type: response.headers['content-type'] })
        const blobUrl = URL.createObjectURL(blob)
        // Trigger file download
        const downloadLink = document.createElement('a')
        downloadLink.href = blobUrl
        downloadLink.download = fileName // Set the file name for the download
        document.body.appendChild(downloadLink)
        downloadLink.click()
        document.body.removeChild(downloadLink)
        const newTab = window.open(blobUrl, '_blank')
        if (newTab != null) {
          newTab.onload = () => {
            newTab.document.title = fileName
          }
        } else {
          console.error('Failed to open new tab')
        }
        URL.revokeObjectURL(blobUrl)
      } else {
        console.error('Failed to fetch file:', response.statusText)
      }
    })
    .catch((error) => {
      console.error('Error fetching file:', error)
    })
    .finally(() => {
      document.body.style.cursor = 'default'
    })
}

export async function getLicenseText (fileName: string): Promise<string | undefined> {
  const url = `/fileuploadservice/api/v1/udp/ami/devices/resources/files?fileName=${fileName}`
  return await interceptedAxios.get(url, { responseType: 'text' })
    .then((response) => {
      if (response.status === 200) {
        return response.data
      } else {
        return undefined
      }
    })
    .catch((error) => {
      console.error('Error fetching license text:', error)
      return undefined
    })
}
