import interceptedAxios from '../../../utils/interceptedAxios'
import { z } from 'zod'

const PropertySet = z.object({
  attribute_name: z.string(),
  value: z.string()
})

const ZAssetProperties = z.object({
  properties: z.array(PropertySet)
})

export type AssetProperties = z.infer<typeof ZAssetProperties>

export async function getAssetDetailsById (
  id: string,
  tenantId: string,
  onSuccess: (assetProperties: AssetProperties, httpStatus: number) => any,
  onFailure: (errorMessage: string, httpStatus: number) => any
): Promise<void> {
  let locale = 'en-US'

  if (navigator.language.startsWith('es') || navigator.language.startsWith('Spanish')) {
    locale = 'es-ES'
  }

  const url = `/asset-service/api/v1/udp/assets/tenantId/${id}/properties?locale=${locale}&tenantId=${tenantId}`

  interceptedAxios.get(url, { timeout: 15000 })
    .then((response) => {
      // istanbul ignore next
      if (response.status === 200) {
        const parseResponse = ZAssetProperties.safeParse(response.data.data)
        if (parseResponse.success) {
          const parsedData: AssetProperties = parseResponse.data
          onSuccess(parsedData, response.status)
        } else {
          onFailure(parseResponse.error.message, response.status)
        }
      }
    })
    .catch((error) => {
      onFailure(error.message, error.response)
    })
}
