import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

export interface Asset {
  id: string
  name: string
  serial_number: string
  device_type: string
  comm_type: string
  comm_mod_serial: string | null
  rem_bat_percent: number | null
  asset_state: string
  latitude: number | null
  longitude: number | null
  install_date: string | null
  firmware_version: string | null
  removal_date: string | null
  ip_address: string | null
  port: string | null
  tag: any | null
  installation_remarks: string | null
  towerId: string | null
  extended_coverage_mode: string | null
}

export interface AssetByIdState {
  isLoading: boolean
  assetData: Asset | null
  errorMessage: string | null
  httpStatus: number | null
}

const initialState: AssetByIdState = {
  isLoading: false,
  assetData: null,
  errorMessage: null,
  httpStatus: null
}

export const assetByIdSlice = createSlice({
  name: 'assetById',
  initialState,
  reducers: {
    assetByIdDataLoading: (state): AssetByIdState => {
      return {
        isLoading: true,
        assetData: null,
        errorMessage: null,
        httpStatus: 0
      }
    },
    assetByIdDataError: (state, action: PayloadAction<{ errorMessage: string, httpStatus: number }>): AssetByIdState => {
      return {
        isLoading: false,
        assetData: null,
        errorMessage: action.payload.errorMessage,
        httpStatus: action.payload.httpStatus
      }
    },
    addAssetByIdData: (state, action: PayloadAction<{ data: Asset, httpStatus: number }>): AssetByIdState => {
      return {
        isLoading: false,
        assetData: action.payload.data,
        errorMessage: null,
        httpStatus: action.payload.httpStatus
      }
    }
  }
})

export const { assetByIdDataLoading, assetByIdDataError, addAssetByIdData } = assetByIdSlice.actions
export default assetByIdSlice.reducer
