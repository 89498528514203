// istanbul ignore file
import React, { useEffect, useState, type Dispatch, type SetStateAction } from 'react'
import AdvancedFilterTable from './AdvancedFilterTable'
import { IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import SavedFilterTemplate from './HandleConjuction'
import LanguageStrings from '../../i18n/locales'
import { type AdvFilterParameterConditions } from '../../features/asset-group/components/GroupDetailsComponents/AssignedAssetList'
import { Button } from '../Button'
import { type ConjuctionOptions } from '../../features/asset-group/types'
import { isValidFirmwareVersion } from '../../utils/validator'

export const validateAdvFilterField = (value: string | string[], dataType?: string, parameter?: string): boolean => {
  // Check if value is an array
  if (Array.isArray(value)) {
    return value.filter((val) => val === '').length > 0
  } else {
    if (value === '') { return true }
  }

  // Validate based on data type
  if (dataType === 'string') {
    // Check for valid characters and length
    const isInvalidChar = !/^[a-zA-Z0-9-_]*$/.test(value)
    const isTooLong = value.length > 20
    return isInvalidChar || isTooLong
  } else if (dataType === 'double' && ((parameter?.toLowerCase().includes('version')) ?? false)) {
    return !isValidFirmwareVersion(value)
  } else if (dataType === 'double') {
    const isNaNValue = isNaN(Number(value))
    const isTooLong = value.length > 10
    const invalidDoubleFormat = /^\\d+(\\.\\d+)?$/.test(value)
    return isNaNValue || isTooLong || invalidDoubleFormat
  }

  return false
}

const AdvancedFilterStrings = LanguageStrings().AssetGroupStrings.GroupDetailsComponent.AdvancedFilter

const AdvancedFilter = (props: PropsFromParent): JSX.Element => {
  const [errors, setErrors] = useState<boolean[]>(props.conditions.map((f: AdvFilterParameterConditions) => validateAdvFilterField(f.value, f.dataType, f.parameter)))
  const [tempConditions, setTempConditions] = useState<AdvFilterParameterConditions[]>(JSON.parse(JSON.stringify(props.conditions))) // Deep clone to prevent reference issues

  useEffect(() => {
    if (props.disableOverflow) {
      document.body.style.overflow = 'hidden'
      return () => {
        document.body.style.overflow = 'unset'
      }
    }
  }, [])

  useEffect(() => {
    // Ensure tempConditions is always reset when modal opens
    setTempConditions(JSON.parse(JSON.stringify(props.conditions)))
  }, [props.conditions, props.setShowAdvancedFilter])

  const isConditionsValid = (): boolean => {
    return errors.every(e => !e)
  }

  const handleConjuctionChange = (value: string): void => {
    const conjunctionValue = value === 'All' ? 'AND' : 'OR'
    props.setConjuction(conjunctionValue)
  }

  const handleApply = (): void => {
    props.setConditions(tempConditions)
    props.setPageSize(1)
    props.setTriggerAdvFilter(!props.triggerAdvFilter)
    props.setShowAdvancedFilter(false)
  }

  return (
        <>
            <div style={{
              backgroundColor: 'rgb(0,0,0,0.4)',
              height: '100vh',
              width: '100vw',
              position: 'fixed',
              zIndex: 1000,
              top: 0,
              backdropFilter: 'blur(5px)',
              left: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
                <div id='modal-div-add-asset' style={{
                  width: '80vw',
                  backgroundColor: '#272727',
                  maxHeight: '90vh',
                  borderRadius: '5px'
                }}>
                    <div className='filter-box' style={{
                      display: 'flex',
                      padding: '30px 30px 10px 30px',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100%',
                      borderBottom: '2px #202020 solid',
                      borderRadius: '5px'
                    }}>
                        <span style={{ fontSize: 24, fontWeight: 500 }}>{AdvancedFilterStrings.AdvanceFilter}</span>
                        <IconButton data-testid={'close-cross-btn'} onClick={() => { props.setShowAdvancedFilter(false) }}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', height: '100%', padding: '20px', paddingTop: '7px' }}>
                        <div style={{ height: 'auto' }}>
                            <SavedFilterTemplate
                            onConjuctionChange={handleConjuctionChange}
                            currentConjuction={props.conjuction} />
                            <AdvancedFilterTable
                                conditions={tempConditions}
                                setConditions={setTempConditions}
                                errors={errors}
                                setErrors={setErrors}
                                searchKey={props.searchKey}
                            />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', gap: '10px', marginTop: '20px', flexGrow: 1, alignItems: 'end', height: '20%' }}>
                            <Button onClick={() => {
                              props.setShowAdvancedFilter(false)
                            }} type='borderless'>{AdvancedFilterStrings.Cancel}</Button>
                            <Button
                                disabled={!isConditionsValid()}
                                onClick={() => {
                                  props.setShowAdvancedFilter(false)
                                  handleApply()
                                }}
                                primaryColor={!isConditionsValid() ? '#C4C4C4' : '#64C3FF'}
                            >
                                {AdvancedFilterStrings.Apply}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
  )
}

interface PropsFromParent {
  conditions: AdvFilterParameterConditions[]
  setConditions: Dispatch<SetStateAction<AdvFilterParameterConditions[]>>
  setPageSize: Dispatch<SetStateAction<number>>
  conjuction: ConjuctionOptions
  setConjuction: Dispatch<SetStateAction<ConjuctionOptions>>
  setShowAdvancedFilter: Dispatch<SetStateAction<boolean>>
  triggerAdvFilter: boolean
  setTriggerAdvFilter: Dispatch<SetStateAction<boolean>>
  searchKey: string
  disableOverflow: boolean
}

export default AdvancedFilter
