import { z } from 'zod'
import interceptedAxios from '../../../utils/interceptedAxios'
import { type AdvancedFilterPostRequest, ZAssetForGroup, type AssetForGroup } from '../types'

const getFields = (sortfield: string, groupId: string, input: string, assetTypeId: string): string => {
  let field = sortfield
  if (field === 'assetId') {
    field = 'id'
  } else if (field === 'state') {
    field = 'stateName'
  }
  if (assetTypeId !== '' && input === '' && groupId === '') {
    if (field === 'tag') {
      field = 'tag.name'
    }
  } else if (assetTypeId === '' && input === '' && groupId !== '') {
    // istanbul ignore else
    if (field === 'id') {
      field = 'assetId'
    }
  }
  return field
}

const ZApiResponse = z.object({
  http_status: z.number(),
  data: z.optional(ZAssetForGroup.nullable()),
  error: z.boolean(),
  error_message: z.optional(z.string())
})

export type ApiResponse = z.infer<typeof ZApiResponse>

export async function getAssetListForGroup (
  page: number,
  size: number,
  sortdir: string,
  sortfield: string,
  onSuccess: (assetForGroup: AssetForGroup, httpStatus: number) => any,
  onFailure: (errorMessage: any, httpStatus: number) => any,
  groupId: string,
  input: string,
  assetTypeId: string,
  searchInput: string
): Promise<void> {
  const field = getFields(sortfield, groupId, input, assetTypeId)
  const url = `/asset-service/api/v1/udp/groupManagement/asset-group/assets?groupId=${groupId !== undefined ? groupId : ''}&input=${input !== undefined ? input : ''}&searchAssetsInput=${searchInput}&assetTypeId=${assetTypeId !== undefined ? assetTypeId : ''}&page=${page}&size=${size}&sort=${field},${sortdir}`

  interceptedAxios
    .get(url)
    .then((response) => {
      const parseResponse = ZApiResponse.safeParse(response.data)
      if (parseResponse.success) {
        if (
          parseResponse.data.data !== null &&
          parseResponse.data.data !== undefined
        ) {
          onSuccess(parseResponse.data.data, parseResponse.data.http_status)
        } else {
          onFailure(parseResponse.data.error_message, parseResponse.data.http_status)
        }
      } else {
        onFailure(parseResponse.error.message, response.status)
      }
    })
    .catch((error) => {
      // istanbul ignore else
      if (error.response !== undefined) {
        if (error.response.data !== undefined) {
          onFailure(error.response.data.error_message, error.response.status)
        } else {
          onFailure(error.message, error.response.status)
        }
      } else {
        onFailure(error.message, error.status)
      }
    })
}

// istanbul ignore file
export async function advancedFilter (
  advancedFilterPostRequest: AdvancedFilterPostRequest,
  page: number,
  size: number,
  groupId: string,
  sortdir: string,
  sortfield: string,
  searchPage: string,
  searchKey: string,
  onSuccess: (successResponse: AssetForGroup, httpStatus: number) => any,
  onFailure: (errorMessage: string, httpStatus: number) => any
): Promise<void> {
  const url = `/search-service/api/v1/udp/search/assetSearch?page=${page}&size=${size}&groupId=${groupId}&sort=${sortfield},${sortdir}&searchPage=${searchPage}&searchKey=${searchKey}`

  interceptedAxios
    .post(url, advancedFilterPostRequest)
    .then((response) => {
      const parseResponse = ZApiResponse.safeParse(response.data)
      // istanbul ignore else
      if (parseResponse.success && parseResponse.data.data !== null && parseResponse.data.data !== undefined) {
        onSuccess(parseResponse.data.data, parseResponse.data.http_status)
      } else {
        onFailure('Error occurred while processing response', response.status)
      }
    })
    .catch((error) => {
      if (error.response !== undefined) {
        // istanbul ignore else
        if (error.response.data !== undefined) {
          onFailure(error.response.data.error_message, error.response.data.http_status)
        } else {
          onFailure(error.response.statusText, error.response.status)
        }
      } else {
        onFailure('Network Failure', error.status)
      }
    })
}
