import thunk from 'redux-thunk'
import logger from 'redux-logger'
import { configureStore } from '@reduxjs/toolkit'
import tenantReducer from '../features/ami-meter-data-collection/redux/tenantSlice'
import languageReducer from './language'
import kpiDailyReducer from '../features/ami-meter-data-collection/redux/kpiDailySlice'
import deviceInfoReducer from '../features/upload-file/redux/deviceInfoSlice'
import assetByIdReducer from '../features/device-details/redux/assetByIdSlice'
import assetIdReducer from '../features/device-details/redux/assetIdSlice'
import templateDetailsReducer from '../features/configuration-upload-status/redux/selectedTemplateDetailsSlice'
import assetPHistoryReducer from '../features/device-provisioning/redux/assetProvisioingHistoryInfoSlice'
import deviceListSlice from '../features/ami-meter-data-collection/redux/deviceListSlice'
import deviceDetailReducer from '../features/device-provisioning/redux/ShowAssetDetailsSlice'
import channelDataDeviceDetailsReducer from '../features/device-details/redux/channelDataSlice'
import deviceDetailsListReducer from '../features/device-details/redux/deviceListSlice'
import deviceDataReducer from '../features/device-provisioning/redux/ProvisioningHistorySummaryDetailsInfoSlice'
import registerReadingsReducer from '../features/device-details/redux/registerReadingsSlice'
import eventsReducer from '../features/device-details/redux/eventsSlice'
import exportAssetProvisioningDetailsReducer from '../features/device-provisioning/redux/exportAssetProvisioningDetailsSlice'
import exportProvisioningHistoryByIdReducer from '../features/device-provisioning/redux/exportProvisioningHistoryByIdSlice'
import exportProvisioningHistoryReducer from '../features/device-provisioning/redux/exportProvisioningHistorySlice'
import alarmByIdReducer from '../features/device-details/redux/alarmsByIdSlice'
import allAlarmsReducer from '../features/ami-meter-data-collection/redux/allAlarmsSlice'
import mapDataReducer from '../features/ami-meter-data-collection/redux/mapDataSlice'
import totalIssueCountReducer from '../features/ami-meter-data-collection/redux/activeIssuesCountSlice'
import addFirmwareInfoReducer from '../features/firmware/redux/addFirmwareInfoSlice'
import topIssuesReducer from '../features/ami-meter-data-collection/redux/topIssuesSlice'
import signalDataReducer from '../features/device-details/redux/cellularSignalSlice'
import topologyReducer from '../features/device-details/redux/topologyDataSlice'
import provisioningHistoryDetailsFilterDeviceTypeReducer from '../features/device-provisioning/redux/provisioningHistoryDetailsFilterDeviceTypeSlice'
import provisioningHistoryDetailsFilterStateReducer from '../features/device-provisioning/redux/provisioningHistoryDetailsFilterStateSlice'
import provisioningHistoryFilterStatusReducer from '../features/device-provisioning/redux/provisioningHistoryFilterStatusSlice'
import provisioningHistoryFilterUploadedUsersReducer from '../features/device-provisioning/redux/provisioningHistoryFilterUploadedUsersSlice'
import parameterListReducer from '../features/asset-configuration/redux/parameterListSlice'
import channelDataReducer from '../features/device-details/redux/channelDropdownSlice'
import firmawareListReducer from '../features/firmware/redux/firmwareListInfoSlice'
import firmwareTypeReducer from '../features/firmware/redux/getFirmwareTypeDataInfoSlice'
import getFirmwareFilterAssetTypeReducer from '../features/firmware/redux/getFirmwareFilterAssetTypeSlice'
import getFirmwareFilterFirmwareTypeReducer from '../features/firmware/redux/getFirmwareFilterFirmwareTypeSlice'
import getFirmwareFilterVersionReducer from '../features/firmware/redux/getFirmwareFilterVersionSlice'
import exportFirmwareListReducer from '../features/firmware/redux/exportFirmwareListSlice'
import deleteFirmwareReducer from '../features/firmware/redux/deleteFirmwareSlice'
import configurationListReducer from '../features/asset-configuration/redux/configurationListSlice'
import configurationDetailsForStatusReducer from '../features/configuration-upload-status/redux/configurationDetailsSlice'
import associateAssetsReducer from '../features/asset-configuration/redux/associateAssetsSlice'
import assetTypesReducer from '../features/asset-configuration/redux/assetTypeSlice'
import createConfigurationReducer from '../features/asset-configuration/redux/createConfigurationSlice'
import assetListByAssetTypeReducer from '../features/asset-configuration/redux/unAssignedAssetsListSlice'
import disassociateAssetsReducer from '../features/asset-configuration/redux/disassociateAssetsSlice'
import configurationsForStatusReducer from '../features/configuration-upload-status/redux/configurationListForStatusSlice'
import configurationDetailsReducer from '../features/asset-configuration/redux/configDetailsSlice'
import templateIdReducer from '../features/asset-configuration/redux/templateIdSlice'
import configIdReducer from '../features/data-publisher/redux/configIdSlice'
import assignedAssetListReducer from '../features/asset-configuration/redux/assignedAssetListSlice'
import thirtyDayReducer from '../features/ami-meter-data-collection/redux/thirtyDaySlice'
import grandParentBreadcrumbReducer from '../features/dashboard/redux/grandParentBreadcrumbSlice'
import allConfigDataReducer from '../features/data-publisher/redux/allConfigSlice'
import allStatusFilesReducer from '../features/data-publisher/redux/fileStatusSlice'
import createReducer from '../features/data-publisher/redux/addNewConfigSlice'
import operationPermissionsReducer from '../features/dashboard/redux/operationPermissionSlice'
import kpiTypeReducer from '../features/ami-meter-data-collection/redux/changeKpiType'
import hamburgerStateReducer from '../features/dashboard/redux/hamburgerSlice'
import ipConfigReducer from '../features/device-details/redux/ipConfigSlice'
import groupListReducer from '../features/device-provisioning/redux/groupListSlice'
import updateGroupReducer from '../features/device-provisioning/redux/updateGroupSlice'
import createGroupProvicioningReducer from '../features/device-provisioning/redux/createGroupSlice'
import AssetGroupsListReducer from '../features/asset-group/redux/AssetGroupsListSlice'
import singleConfigReducer from '../features/data-publisher/redux/singleConfigSlice'
import updateConfigReducer from '../features/data-publisher/redux/updateConfigSlice'
import deleteConfigReducer from '../features/data-publisher/redux/deleteConfigSlice'
import AssetStatesTypeDataReducer from '../features/ami-meter-data-collection/redux/assetStatesSlice'
import updateAssetStateReducer from '../features/ami-meter-data-collection/redux/updateAssetStateSlice'
import createGroupReducer from '../features/asset-group/redux/createGroupSlice'
import assetForCreateGroupReducer from '../features/asset-group/redux/assetForCreateGroupSlice'
import editAssetSummaryReducer from '../features/device-details/redux/editAssetSummaryInfoSlice'
import AssetGroupDetailsReducer from '../features/asset-group/redux/AssetGroupDetailsSlice'
import AssetAssignedListReducer from '../features/asset-group/redux/AssetAssignedListSlice'
import UnassignedAssetListReducer from '../features/asset-group/redux/UnassignedAssetListSlice'
import updateGroupDetailsReducer from '../features/asset-group/redux/updateGroupSlice'
import getAllTagsByIdSliceReducer from '../features/device-details/redux/getAllTagsByIdSlice'
import getAllGroupFilterParameterSliceReducer from '../features/asset-group/redux/getAllGroupFilterParameterSlice'
import CreateGroupFirmwareReducer from '../features/firmware/redux/createGroupSlice'
import FirmwareAssignGroupReducer from '../features/firmware/redux/firmwareAssignGroupSlice'
import DeleteGroupReducer from '../features/asset-group/redux/DeleteGroupSlice'
import ConfigurationAssignGroupReducer from '../features/asset-configuration/redux/configAssignGroupSlice'
import DeviceHistoryReducer from '../features/device-details/redux/deviceLifeCycleHistorySlice'
import getAllSchdulerListSliceReducer from '../features/data-publisher/redux/getAllSchdulerListSlice'
import createReportTemplateReducer from '../features/data-publisher/redux/createReportTemplateSlice'
import templateDetailsListReducer from '../features/data-publisher/redux/templateDetailsListSlice'
import reportContentColumnReducer from '../features/data-publisher/redux/reportContentColumnSlice'
import deleteReportTemplateSlice from '../features/data-publisher/redux/deleteReportTemplateSlice'
import editReportTemplateSlice from '../features/data-publisher/redux/editReportTemplateSlice'
import FirmwareUnassignGroupReducer from '../features/firmware/redux/firmwareUnassignGroupSlice'
import totalConnectedSlice from '../features/ami-meter-data-collection/redux/KpiSlices/totalConnectedSlice'
import totalRegisteredSlice from '../features/ami-meter-data-collection/redux/KpiSlices/totalRegisteredSlice'
import totalShippedSlice from '../features/ami-meter-data-collection/redux/KpiSlices/totalShippedSlice'
import totalStaleSlice from '../features/ami-meter-data-collection/redux/KpiSlices/totalStaleSlice'
import SchedulerDropDownReducer from '../features/data-publisher/redux/getAllCreateSchdulerDropdownSlice'
import createSchedulerTemplateReducer from '../features/data-publisher/redux/createReportSchedulerTemplateSlice'
import HistoryARTableReducer from '../features/ami-meter-data-collection/redux/HistoryARTableSlice'
import HistoryKSTableReducer from '../features/ami-meter-data-collection/redux/HistoryKSTableSlice'
import getSchedulerfilterDropdownReducer from '../features/data-publisher/redux/reportSchedulerFilterDropdownSlice'
import getKpiPreferenceReducer from '../features/ami-meter-data-collection/redux/getKpiPreferenceSlice'
import deleteSchedulerSlice from '../features/data-publisher/redux/deleteSchedulerSlice'
import exportSchedulerDataReducer from '../features/data-publisher/redux/exportSchedulerDataSlice'
import createSFTPConfigurationReducer from '../features/data-publisher/redux/CreateSftpConfigurationSlice'
import testConnectionReducer from '../features/data-publisher/redux/testConnectionSlice'
import createConfigurationMetadataReducer from '../features/data-publisher/redux/configurationMetaDataSlice'
import configurationListDetailsReducer from '../features/data-publisher/redux/getConfigurationProtocolListSlice'
import updateConfigurationProtocolReducer from '../features/data-publisher/redux/updateConfigurationProtocolSlice'
import firmwareCampaignDetailsReducer from '../features/firmware/redux/firmwareUpgradeDetailsSlice'
import pauseSchedulerSlice from '../features/data-publisher/redux/pauseSchedulerSlice'
import resumeSchedulerSlice from '../features/data-publisher/redux/resumeSchedulerSlice'
import firmwareCreateCampaignReducer from '../features/firmware/redux/firmwareCreateCampaignSlice'
import firmwareEditCampaignReducer from '../features/firmware/redux/firmwareEditCampaignSlice'
import firmwareCampaignListReducer from '../features/firmware/redux/firmwareCampaignListSlice'
import totalReadsDonutDataReducer from '../features/ami-meter-data-collection/redux/KpiSlices/totalReadsSlice'
import historyDonutReducer from '../features/ami-meter-data-collection/redux/historyDonutSlice'
import historyDonutTableReducer from '../features/ami-meter-data-collection/redux/HistoryDonutTableSlice'
import donutKpiBarGraphSlice from '../features/ami-meter-data-collection/redux/historyDonutKpiBarGraphSlice'
import ecModeDonutSlice from '../features/ami-meter-data-collection/redux/KpiSlices/ecModeSlice'
import signalBreakdownSlice from '../features/ami-meter-data-collection/redux/KpiSlices/signalBreakdownSlice'
import alarmBreakdownSlice from '../features/ami-meter-data-collection/redux/KpiSlices/alarmBreakdownSlice'
import firmwareBreakdownSlice from '../features/ami-meter-data-collection/redux/KpiSlices/firmwareBreakdownSlice'
import lastReadAlarmsReducer from '../features/ami-meter-data-collection/redux/KpiSlices/lastReadAlarmsSlice'
import batteryBreakdownSlice from '../features/ami-meter-data-collection/redux/KpiSlices/batteryBreakdownSlice'
import groupWithCountListReducer from '../features/firmware/redux/groupWithCountListSlice'
import firmwareCampaignDeleteReducer from '../features/firmware/redux/firmwareCampaignDeleteSlice'
import SchedulerHistoryListReducer from '../features/data-publisher/redux/getSchedulerHistoryDetailsSlice'
import exportFirmwareUpgradeStatusListReducer from '../features/firmware/redux/exportFirmwareUpgradeStatusSlice'
import exportFirmwareCampaignListReducer from '../features/firmware/redux/exportFirmwareCampaignDetailsSlice'
import getFirmwareCampaignFilterMetaDataReducer from '../features/firmware/redux/getFirmwareCampaignFilterMetaDataSlice'
import uploadAssetProfileInfoSlice from '../features/asset-configuration/redux/updateAssetProfileSlice'
import alarmHistorySlice from '../features/device-details/redux/alarmHistorySlice'
import seedReducer from '../features/device-provisioning/redux/setC12.22SeedSlice'
import lifecycleBreakdownSlice from '../features/ami-meter-data-collection/redux/KpiSlices/lifecycleBreakdownSlice'
import exportARKpiDetailsReducer from '../features/ami-meter-data-collection/redux/exportARKpiDetailsSlice'
import exportKSKpiDetailsSlice from '../features/ami-meter-data-collection/redux/exportKSKpiDetailsSlice'
import exportDonutKpiDetailsSlice from '../features/ami-meter-data-collection/redux/exportDonutKpiDetailsSlice'
import getPublicKeySlice from '../features/data-publisher/redux/getPublicKeySlice'
import getReportSchedulerByIdSlice from '../features/data-publisher/redux/getReportSchedulerByIdSlice'
import editReportSchedulerSlice from '../features/data-publisher/redux/editReportSchedulerSlice'
import getprotocolDetailsSlice from '../features/data-publisher/redux/getProtocolDetailsByIdSlice'
import assetAttributeSlice from '../features/device-details/redux/assetAttributeSlice'
import assetAttrParameterSlice from '../features/device-details/redux/assetAttrParameterSlice'
import advancedFilterOptionSlice from '../features/asset-group/redux/advancedFilterOptionSlice'
import topIssuesGeoJsonReducer from '../features/Maps/redux/TopIssuesListSlice'
import topIssuesForPositionReducer from '../features/Maps/redux/TopIssuesForPositionSlice'
import assetPropertiesByIdReducer from '../features/device-details/redux/assetPropertiesByIdSlice'

const store = configureStore({
  reducer: {
    tenant: tenantReducer,
    language: languageReducer,
    kpiDailyData: kpiDailyReducer,
    deviceInfo: deviceInfoReducer,
    assetById: assetByIdReducer,
    assetId: assetIdReducer,
    assetPropertiesById: assetPropertiesByIdReducer,
    assetAttributes: assetAttributeSlice,
    assetAttrParameter: assetAttrParameterSlice,
    deviceList: deviceListSlice,
    deviceDetail: deviceDetailReducer,
    channelDataDeviceDetail: channelDataDeviceDetailsReducer,
    deviceDetailsList: deviceDetailsListReducer,
    assetProvisioningHistoryData: assetPHistoryReducer,
    deviceData: deviceDataReducer,
    registerReadingsData: registerReadingsReducer,
    eventsData: eventsReducer,
    exportAssetProvisioningDetails: exportAssetProvisioningDetailsReducer,
    exportProvisioningHistoryById: exportProvisioningHistoryByIdReducer,
    exportProvisioningHistory: exportProvisioningHistoryReducer,
    alarmsById: alarmByIdReducer,
    allActiveIssues: allAlarmsReducer,
    mapData: mapDataReducer,
    totalIssuesCount: totalIssueCountReducer,
    addFirmwareInfoSlice: addFirmwareInfoReducer,
    topIssues: topIssuesReducer,
    signalData: signalDataReducer,
    topology: topologyReducer,
    provisioningHistoryDetailsFilterDeviceType: provisioningHistoryDetailsFilterDeviceTypeReducer,
    provisioningHistoryDetailsStateFilter: provisioningHistoryDetailsFilterStateReducer,
    provisioningHistoryFilterStatus: provisioningHistoryFilterStatusReducer,
    provisioningHistoryFilterUploadedUsers: provisioningHistoryFilterUploadedUsersReducer,
    parameterList: parameterListReducer,
    AssetStatesTypeData: AssetStatesTypeDataReducer,
    updateAssetStateData: updateAssetStateReducer,
    channelDropdownData: channelDataReducer,
    firmwareListData: firmawareListReducer,
    firmwareTypeData: firmwareTypeReducer,
    firmwareFilterAssetType: getFirmwareFilterAssetTypeReducer,
    firmwareFilterFirmwareType: getFirmwareFilterFirmwareTypeReducer,
    firmwareFilterVersion: getFirmwareFilterVersionReducer,
    exportFirmwareList: exportFirmwareListReducer,
    deleteFirmware: deleteFirmwareReducer,
    configurationList: configurationListReducer,
    associateAssets: associateAssetsReducer,
    assetTypes: assetTypesReducer,
    createConfiguration: createConfigurationReducer,
    assetsByAssetType: assetListByAssetTypeReducer,
    disassociateAssets: disassociateAssetsReducer,
    configurationsForStatus: configurationsForStatusReducer,
    configurationDetails: configurationDetailsReducer,
    templateDetails: templateDetailsReducer,
    configurationDetailsForStatus: configurationDetailsForStatusReducer,
    templateIdForConfigurationDetails: templateIdReducer,
    configId: configIdReducer,
    assignedAssetList: assignedAssetListReducer,
    thirtyDay: thirtyDayReducer,
    grandParentBreadcrumb: grandParentBreadcrumbReducer,
    allConfigData: allConfigDataReducer,
    allStatusFiles: allStatusFilesReducer,
    createDatapubConfig: createReducer,
    operationPermissions: operationPermissionsReducer,
    kpiType: kpiTypeReducer,
    hamburgerState: hamburgerStateReducer,
    ipConfigState: ipConfigReducer,
    groupListData: groupListReducer,
    updateGroup: updateGroupReducer,
    createGroup: createGroupProvicioningReducer,
    assetGroupList: AssetGroupsListReducer,
    singleConfigData: singleConfigReducer,
    updateConfigData: updateConfigReducer,
    deleteConfigData: deleteConfigReducer,
    createGroupState: createGroupReducer,
    advancedFilterOption: advancedFilterOptionSlice,
    assetForCreateGroup: assetForCreateGroupReducer,
    EditAssetSummaryInfoSlice: editAssetSummaryReducer,
    assetGroupDetails: AssetGroupDetailsReducer,
    assetAssignedList: AssetAssignedListReducer,
    UnassignedAssetList: UnassignedAssetListReducer,
    updateGroupDetailsState: updateGroupDetailsReducer,
    getAllTags: getAllTagsByIdSliceReducer,
    getAllGroupFilterParamter: getAllGroupFilterParameterSliceReducer,
    createFirmwareGroup: CreateGroupFirmwareReducer,
    firmwareAssignGroup: FirmwareAssignGroupReducer,
    deleteGroup: DeleteGroupReducer,
    configurationAssignGroup: ConfigurationAssignGroupReducer,
    deviceHistory: DeviceHistoryReducer,
    SchdulerListData: getAllSchdulerListSliceReducer,
    createReportTemplateState: createReportTemplateReducer,
    templateDetailsListState: templateDetailsListReducer,
    reportContentColumns: reportContentColumnReducer,
    editReportTemplateState: editReportTemplateSlice,
    deleteReportTemplateState: deleteReportTemplateSlice,
    firmwareUnassignGroup: FirmwareUnassignGroupReducer,
    SchdulerDropDownListData: SchedulerDropDownReducer,
    CreateSchedulerTemplate: createSchedulerTemplateReducer,
    SchedulerFilterDropDownListData: getSchedulerfilterDropdownReducer,
    KpiUserPreference: getKpiPreferenceReducer,
    deleteScheduler: deleteSchedulerSlice,
    pauseScheduler: pauseSchedulerSlice,
    resumeScheduler: resumeSchedulerSlice,
    exportSchedulerDa: exportSchedulerDataReducer,
    SFTPConfigurationData: createSFTPConfigurationReducer,
    ConfigurationMetadata: createConfigurationMetadataReducer,
    ConfigurationListData: configurationListDetailsReducer,
    UpateProtocolConfigurationResponce: updateConfigurationProtocolReducer,
    firmwareCampaignDetails: firmwareCampaignDetailsReducer,
    FirmwareCreateCampaignState: firmwareCreateCampaignReducer,
    firmwareEditCampaignState: firmwareEditCampaignReducer,
    firmwareCampaignListState: firmwareCampaignListReducer,
    ProtocolDetailsData: getprotocolDetailsSlice,
    testConnectionData: testConnectionReducer,
    // History Table Generic
    KpiHistoryARState: HistoryARTableReducer,
    KpiHistoryKSState: HistoryKSTableReducer,
    // Bar Kpis
    TotalConnectedState: totalConnectedSlice,
    TotalRegisteredState: totalRegisteredSlice,
    TotalShippedState: totalShippedSlice,
    TotalStaleState: totalStaleSlice,
    LastReadAlarmsData: lastReadAlarmsReducer,
    // donut kpi
    totalReadsDonutData: totalReadsDonutDataReducer,
    ecModeDonutData: ecModeDonutSlice,
    historyDonutState: historyDonutReducer,
    donutKpiHistoryTableState: historyDonutTableReducer,
    donutKpiBarGraphData: donutKpiBarGraphSlice,
    signalBreakdownData: signalBreakdownSlice,
    alarmBreakdownData: alarmBreakdownSlice,
    firmwareBreakdownData: firmwareBreakdownSlice,
    batteryBreakdownData: batteryBreakdownSlice,
    lifecycleBreakdownData: lifecycleBreakdownSlice,
    // Scheduler, firmware, group
    groupWithCountListState: groupWithCountListReducer,
    firmwareCampaignDeleteState: firmwareCampaignDeleteReducer,
    firmwareCampaignDetailsState: firmwareCampaignDetailsReducer,
    FirmwareUpgradeStatusList: exportFirmwareUpgradeStatusListReducer,
    FirmwareCampaignDetailsList: exportFirmwareCampaignListReducer,
    SchdulerHistoryListData: SchedulerHistoryListReducer,
    uploadAssetProfileInfoState: uploadAssetProfileInfoSlice,
    alarmHistoryState: alarmHistorySlice,
    PublicKeyData: getPublicKeySlice,
    // Seed
    seedState: seedReducer,
    FirmwareCampaignFilterMetaData: getFirmwareCampaignFilterMetaDataReducer,
    // export kpi details
    exportARKpiDetailsData: exportARKpiDetailsReducer,
    exportKSKpiDetailsData: exportKSKpiDetailsSlice,
    exportDonutKpiDetailsData: exportDonutKpiDetailsSlice,
    getReportSchedulerById: getReportSchedulerByIdSlice,
    editReportScheduler: editReportSchedulerSlice,
    // Google maps
    topIssuesGeoJson: topIssuesGeoJsonReducer,
    topIssuesForPosition: topIssuesForPositionReducer
  },
  middleware: [thunk, logger]
})

export default store
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
