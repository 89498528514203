/* eslint-disable @typescript-eslint/prefer-optional-chain */
// istanbul ignore file
import React, { useEffect } from 'react'
import { CircularProgress, styled } from '@mui/material'
import dayjs from 'dayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers'
import AuthError from '../../../components/ErrorComponents/AuthError'
import { type ReportData, getReactApexChartBar } from './KpiComponents/BarCharts'
import { type ConnectedProps, connect, useSelector } from 'react-redux'
import { type RootState } from '../../../store'
import { fetchTotalRegisteredKpi, fetchTotalConnectedKpi, fetchTotalShippedKpi, fetchTotalStaleKpi, fetchLastReadAlarmsKpi } from '../redux/actionCreators'
import { kpiColorMap } from './KpiComponents/BuildKpiData'
import { type BarChartState } from '../types'
import { buildLastReadAlarmsHistoryData, buildTotalConnectedHistoryData, buildTotalRegisteredHistoryData, buildTotalShippedHistoryData, buildTotalStaleHistoryData, type GeneratedData } from './KpiComponents/BuildKpiHistory'
import { type KpiKey } from '../redux/changeKpiType'
import { getKpiIdFromName, getKpiService, getKpiTitle } from './KpiComponents/kpiUtils'
import { type TotalStaleState } from '../redux/KpiSlices/totalStaleSlice'
import { type LastReadAlarmsState } from '../redux/KpiSlices/lastReadAlarmsSlice'
import { type BarGraphParameter } from '../KpiDetails'
import useWindowSize from '../../../customHooks/windowResize'

export interface KpiSeriesData {
  name: string
  data: number[]
}

const nintyDayDate = dayjs(new Date((new Date()).setMonth(new Date().getMonth() - 3)))
export type KpiDispatchLoader = (startDate: string, endDate: string, kpiType: string, service: string, kpiCategoryId: string) => void

export const getKpiType = (kpiType: KpiKey): string => {
  switch (kpiType) {
    case 'Total Registered':
      return 'total-registered'
    case 'Total Connected':
      return 'total-connected'
    case 'Total Shipped':
      return 'total-shipped'
    case 'Total Stale':
      return 'total-stale'
    case 'Last Read Alarms':
      return 'last-read-alarms'
    default:
      return 'total-shipped'
  }
}

export interface KpiHistoryState <T> {
  isLoading: boolean
  error: boolean
  data: T[] | undefined
  httpStatus: number
}

interface ParentProps {
  startDate: dayjs.Dayjs
  endDate: dayjs.Dayjs
  setStartDate: React.Dispatch<React.SetStateAction<dayjs.Dayjs>>
  setEndDate: React.Dispatch<React.SetStateAction<dayjs.Dayjs>>
  barGraphParameter: BarGraphParameter
  setBarGraphParameter: React.Dispatch<React.SetStateAction<BarGraphParameter>>
}

const WhiteDatePicker = styled(DatePicker)({
  '& input': {
    color: '#D0D0D0',
    fontSize: '14px',
    paddingRight: 0
  }
})

const CustomLocalizationProviderWrapper = styled('div')({
  backgroundColor: '#505050',
  border: 'none',
  borderRadius: '4px'
})

export const createReportDataTemp = (kpiName: string, selectedBar: string, kpiData: () => GeneratedData): ReportData => {
  const series = kpiData().seriesData
  let colors
  if (kpiName === 'Total Shipped') {
    colors = kpiColorMap['Total Shipped']
  } else if (kpiName === 'Total Stale') {
    colors = kpiColorMap['Total Stale']
  } else if (kpiName === 'Last Read Alarms') {
    colors = kpiColorMap['Last Read Alarms']
  } else {
    if (kpiName === 'Total Registered') {
      colors = [kpiColorMap['Total Registered'][0], kpiColorMap['Total Registered'][1]]
    } else {
      colors = [kpiColorMap['Total Connected'][0], kpiColorMap['Total Connected'][1]]
    }
  }

  const categories = kpiData().category
  return {
    name: kpiName,
    type: 'bar',
    categories,
    series,
    colors,
    suffix: ''
  }
}

function HistoryBarGraph (props: AllProps): JSX.Element {
  const initialReportData: ReportData = {
    name: '',
    type: 'bar',
    categories: [],
    series: [],
    colors: [],
    suffix: ''
  }
  const [reportData, setReportData] = React.useState<ReportData>(initialReportData)
  const { width } = useWindowSize()
  const selectedKpi = useSelector((state: RootState) => state.kpiType)
  const [barChart, setBarChart] = React.useState(getReactApexChartBar(reportData, '350px', true, props.setBarGraphParameter, selectedKpi, props.barGraphParameter))

  useEffect(() => {
    setBarChart(getReactApexChartBar(reportData, '350px', true, props.setBarGraphParameter, selectedKpi, props.barGraphParameter))
  }, [width, reportData, props.barGraphParameter])

  function kpiData (): GeneratedData {
    if (selectedKpi === 'Total Registered') {
      return buildTotalRegisteredHistoryData(props.totalRegistered)
    } else if (selectedKpi === 'Total Connected') {
      return buildTotalConnectedHistoryData(props.totalConnected)
    } else if (selectedKpi === 'Total Stale') {
      return buildTotalStaleHistoryData(props.totalStale)
    } else if (selectedKpi === 'Last Read Alarms') {
      return buildLastReadAlarmsHistoryData(props.lastReadAlarms)
    } else {
      return buildTotalShippedHistoryData(props.totalShipped)
    }
  }

  const kpiLoader = (): KpiDispatchLoader => {
    if (selectedKpi === 'Total Registered') {
      return props.fetchTotalRegistered
    } else if (selectedKpi === 'Total Connected') {
      return props.fetchTotalConnected
    } else if (selectedKpi === 'Total Shipped') {
      return props.fetchTotalShipped
    } else if (selectedKpi === 'Total Stale') {
      return props.fetchTotalStale
    } else if (selectedKpi === 'Last Read Alarms') {
      return props.fetchLastReadAlarms
    } else {
      return () => undefined
    }
  }

  const state = (): BarChartState => {
    if (selectedKpi === 'Total Registered') {
      return props.totalRegistered
    } else if (selectedKpi === 'Total Connected') {
      return props.totalConnected
    } else if (selectedKpi === 'Total Stale') {
      return props.totalStale
    } else if (selectedKpi === 'Last Read Alarms') {
      return props.lastReadAlarms
    } else {
      return props.totalShipped
    }
  }

  const loadKpiData = (): void => {
    const loader = kpiLoader()
    loader(props.startDate.format('YYYY-MM-DD'), props.endDate.format('YYYY-MM-DD'), getKpiType(selectedKpi), getKpiService(selectedKpi), getKpiIdFromName(selectedKpi) ?? '')
  }

  useEffect(() => {
    loadKpiData()
  }, [props.startDate, props.endDate])

  useEffect(() => {
    setReportData(createReportDataTemp(selectedKpi, props.barGraphParameter.bar.value, kpiData))
  }, [state().data])

  const handleGraphWithFailure = (): JSX.Element => {
    const kpiState = state()
    if (kpiState.isLoading) {
      return (
        <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress /></div>
      )
    } else if (kpiState.httpStatus === 401) {
      return (
        <div className='authErrorThirtyDaysTend daystrend401'><AuthError errorMessage="Unauthorized"/></div>
      )
    } else if (kpiState.httpStatus === 403) {
      return (<div className='authErrorThirtyDaysTend daystrend401'><AuthError errorMessage="accessForbidden"/></div>)
    } else if (kpiState.httpStatus === 200 && kpiState.data === undefined) {
      return (
        <div className='authErrorThirtyDaysTend daystrend401'><AuthError errorMessage="NoDataPresent"/></div>
      )
    } else if (kpiState.data !== undefined && kpiState.httpStatus === 200) {
      return barChart
    } else {
      return (
        <div className='authErrorThirtyDaysTend daystrend401'><AuthError errorMessage="cannotFetch" retry={loadKpiData}/></div>
      )
    }
  }

  const handleDateChange = (type: string, date: dayjs.Dayjs): void => {
    if (type === 'start') {
      if (date.isAfter(props.endDate) || date.isSame(props.endDate)) {
        props.setStartDate(date)
        props.setEndDate(date)
      } else {
        props.setStartDate(date)
      }
      if (date.isAfter(props.barGraphParameter.date)) {
        props.setBarGraphParameter({ ...props.barGraphParameter, date })
      }
    } else {
      if (date.isBefore(props.startDate) || date.isSame(props.startDate)) {
        props.setEndDate(date)
        props.setStartDate(date)
      } else {
        props.setEndDate(date)
      }
      if (date.isBefore(props.barGraphParameter.date)) {
        props.setBarGraphParameter({ ...props.barGraphParameter, date })
      }
    }
  }

  return (
    <div className='kpiHistoryContainer'>
      <div className='kpiHistoryHeader'>
        <span style={{ fontSize: 18 }}>{getKpiTitle(selectedKpi)}</span>
        <div style={{ display: 'flex' }}>
          <span style={{ paddingRight: '1em', display: 'flex', alignItems: 'center' }}>
            <span style={{ paddingRight: '6px', fontSize: '14px' }}>
            Start Date
            </span>
            <span className='datePicker-deviceDetails'>
              <CustomLocalizationProviderWrapper>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <WhiteDatePicker minDate={nintyDayDate} disableFuture slotProps={{ textField: { size: 'small' }, field: { readOnly: true } }} value={props.startDate} onAccept={(val: any) => { handleDateChange('start', val) }} />
                </LocalizationProvider>
              </CustomLocalizationProviderWrapper>
            </span>
          </span>
          <span style={{ paddingRight: '1em', display: 'flex', alignItems: 'center' }}>
            <span style={{ paddingRight: '6px', fontSize: '14px' }}>
            End Date
            </span>
            <span className='datePicker-deviceDetails'>
              <CustomLocalizationProviderWrapper>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <WhiteDatePicker minDate={nintyDayDate} disableFuture slotProps={{ textField: { size: 'small' }, field: { readOnly: true } }} value={props.endDate} onAccept={(val: any) => { handleDateChange('end', val) }} />
                </LocalizationProvider>
              </CustomLocalizationProviderWrapper>
            </span>
          </span>
        </div>
      </div>
      <div>
        {handleGraphWithFailure()}
      </div>
    </div>
  )
}

interface DispatchToProps {
  fetchTotalRegistered: (startDate: string, endDate: string, kpiType: string, service: string, kpiCategoryId: string) => void
  fetchTotalConnected: (startDate: string, endDate: string, kpiType: string, service: string, kpiCategoryId: string) => void
  fetchTotalShipped: (startDate: string, endDate: string, kpiType: string, service: string, kpiCategoryId: string) => void
  fetchTotalStale: (startDate: string, endDate: string, barItem: string, service: string, kpiCategoryId: string) => void
  fetchLastReadAlarms: (startDate: string, endDate: string, kpiType: string, service: string, kpiCategoryId: string) => void
}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  fetchTotalRegistered: (startDate: string, endDate: string, kpiType: string, service: string, kpiCategoryId: string) => dispatch(fetchTotalRegisteredKpi(startDate, endDate, kpiType, service, kpiCategoryId)),
  fetchTotalConnected: (startDate: string, endDate: string, kpiType: string, service: string, kpiCategoryId: string) => dispatch(fetchTotalConnectedKpi(startDate, endDate, kpiType, service, kpiCategoryId)),
  fetchTotalShipped: (startDate: string, endDate: string, kpiType: string, service: string, kpiCategoryId: string) => dispatch(fetchTotalShippedKpi(startDate, endDate, kpiType, service, kpiCategoryId)),
  fetchTotalStale: (startDate: string, endDate: string, kpiType: string, service: string, kpiCategoryId: string) => dispatch(fetchTotalStaleKpi(startDate, endDate, kpiType, service, kpiCategoryId)),
  fetchLastReadAlarms: (startDate: string, endDate: string, kpiType: string, service: string, kpiCategoryId: string) => dispatch(fetchLastReadAlarmsKpi(startDate, endDate, kpiType, service, kpiCategoryId))
})
interface StateToProps {
  totalRegistered: BarChartState
  totalConnected: BarChartState
  totalShipped: BarChartState
  totalStale: TotalStaleState
  lastReadAlarms: LastReadAlarmsState
}

const mapStateToProps = (state: RootState): StateToProps => ({
  totalRegistered: state.TotalRegisteredState,
  totalConnected: state.TotalConnectedState,
  totalShipped: state.TotalShippedState,
  totalStale: state.TotalStaleState,
  lastReadAlarms: state.LastReadAlarmsData
})

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>
type AllProps = ParentProps & PropsFromRedux
export default connector(HistoryBarGraph)
