import interceptedAxios from '../../../utils/interceptedAxios'
import { z } from 'zod'

const ZAssetStateData = z.object({
  asset_state_type_id: z.string(),
  asset_state_type_name: z.string()
})

const ZApiResponse = z.object({
  http_status: z.number(),
  data: z.optional(z.any()),
  error: z.boolean()
})

export type AssetStateData = z.infer<typeof ZAssetStateData>

export async function getAssetStateData (
  onSuccess: (AssetStatesTypeData: any, httpStatus: number) => any,
  onFailure: (errorMessage: string, httpStatus: number) => any
): Promise<void> {
  const url = '/asset-service/api/v1/udp/assets/state'
  interceptedAxios
    .get(url)
    .then((response) => {
      const parseResponse = ZApiResponse.safeParse(response.data)
      // istanbul ignore else
      if (parseResponse.success) {
        // istanbul ignore else
        if (
          parseResponse.data.data !== null &&
            parseResponse.data.data !== undefined
        ) {
          onSuccess(parseResponse.data.data, response.status)
        }
      } else {
        onFailure(parseResponse.error.message, response.status)
      }
    })
    .catch(
      (error) => {
        onFailure(error.message, error.response.status)
      })
}
