// istanbul ignore file
import React, { useEffect, useState } from 'react'
import { type RootState } from '../../store'
import { type ConnectedProps, connect, useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import BackButton from '../../components/BackButton'
import LanguageStrings from '../../i18n/locales'
import HistoryBarGraph from './components/HistoryBarGraph'
import dayjs, { type Dayjs } from 'dayjs'
import './KpiDetails.css'
import { type ToggleOption } from '../../components/GenericToggleButton'
import HistoryTable from './components/KpiComponents/HistoryTable'
import { getDetailKeyword, getDonutDetails, getToggleOptions } from './components/KpiComponents/kpiUtils'
import { type KpiKeyDonut, doesKpiHaveDonutChart } from './redux/changeKpiType'
import HistoryDonutKpiBarGraph from './components/HistoryDonutKpiBarGraph'
import exportIcon from '../../assets/exportIcon.svg'
import { type ExportARKpiDetailsState } from './redux/exportARKpiDetailsSlice'
import { exportARKpiHistoryTable, exportDonutKpiHistoryTable, exportKSKpiHistoryTable } from './redux/exportActionCreator'
import { type ExportDonutKpiDetailsState } from './redux/exportDonutKpiDetailsSlice'
import { exportCSV } from '../../components/exportFile'
import { getColumnsByKpi } from './components/KpiComponents/HistoryTableCustomColumns'
import { type DonutKpiState } from './types'
import { exportDonutKpiDetailsResetAction } from './redux/exportDonutKpiDetailsSlice'
import { exportARKpiDetailsResetAction } from './redux/exportARKpiDetailsSlice'
import { convertDateIntoAohStandardUnSeperated } from '../../customHooks/parseDate'
import { resetKpiDetails } from './redux/actionCreators'
import { exportKSKpiDetailsResetAction, type ExportKSKpiDetailsState } from './redux/exportKSKpiDetailsSlice'
const KpiDetailsStrings = LanguageStrings().AMIDataCollectionStrings.KpiDetails

const defaultDate = {
  end: dayjs(new Date()),
  start: dayjs(new Date((new Date()).setMonth(new Date().getMonth() - 1)))
}

export interface BarGraphParameter {
  date: Dayjs
  bar: ToggleOption
}

const KpiDetails = (props: PropsFromRedux): JSX.Element => {
  const navigate = useNavigate()
  const selectedKpi = useSelector((state: RootState) => state.kpiType)
  const [selectedDonut, setSelectedDonut] = useState<number>(0)
  const [barGraphParameter, setBarGraphParameter] = useState<BarGraphParameter>({ date: defaultDate.end, bar: getToggleOptions(selectedKpi)[getToggleOptions(selectedKpi).length - 1] })
  const [startDate, setStartDate] = useState<Dayjs>(defaultDate.start)
  const [endDate, setEndDate] = useState<Dayjs>(defaultDate.end)
  const [initModification, setInitModification] = useState<boolean>(false)
  const [detailKeyword, detailService] = getDetailKeyword(selectedKpi, barGraphParameter.bar.value)
  const [page, setPage] = React.useState(1)
  const rowsPerPage = 10
  const dispatch = useDispatch()

  const getExportState = (): ExportKSKpiDetailsState | ExportARKpiDetailsState | ExportDonutKpiDetailsState => {
    if (doesKpiHaveDonutChart(selectedKpi)) {
      return props.exportDonutKpiDetailData
    } else {
      if (detailService === 'asset-service') {
        return props.exportARKpiDetailData
      } else {
        return props.exportKSKpiDetailData
      }
    }
  }

  useEffect(() => {
    if (getExportState().httpStatus === 200) {
      const data: string[][] = []
      const headCells = getColumnsByKpi(detailKeyword)
      const headers = headCells.map(column => column.label)
      data.push(headers)

      if (doesKpiHaveDonutChart(selectedKpi)) {
        props.exportDonutKpiDetailData.exportDonutKpiDetails?.assetDetails.forEach(asset => {
          const row = headCells.map(col => {
            return (col.isDate ? convertDateIntoAohStandardUnSeperated(asset[col.id as keyof typeof asset]) : String(asset[col.id as keyof typeof asset]))
          })
          data.push(row)
        })
        dispatch(exportDonutKpiDetailsResetAction())
      } else {
        if (detailService === 'asset-service') {
          props.exportARKpiDetailData.exportARKpiDetails?.assets.forEach(asset => {
            const row = headCells.map(col => {
              return (col.isDate ? convertDateIntoAohStandardUnSeperated(asset[col.id as keyof typeof asset]) : String(asset[col.id as keyof typeof asset]))
            }).filter((item): item is string => item !== null)
            data.push(row)
          })
          dispatch(exportARKpiDetailsResetAction())
        } else {
          props.exportKSKpiDetailData.exportKSKpiDetails?.assetDetails.forEach(asset => {
            const row = headCells.map(col => {
              return (col.isDate ? convertDateIntoAohStandardUnSeperated(asset[col.id as keyof typeof asset]) : String(asset[col.id as keyof typeof asset]))
            }).filter((item): item is string => item !== null)
            data.push(row)
          })
          dispatch(exportKSKpiDetailsResetAction())
        }
      }

      console.log('exporting csv', data)
      // Modified line to include HH:mm format
      exportCSV(data, `${selectedKpi.replaceAll(' ', '-')}_${dayjs(barGraphParameter.date).format('MM-DD-YYYY HH:mm')}`)
    }

    return () => {
      if (window.location.pathname === '/isense') {
        console.log('Destroying KpiDetails')
        props.resetKpiDetails()
      }
    }
  }, [props.exportARKpiDetailData, props.exportDonutKpiDetailData, props.exportKSKpiDetailData])

  const exportAssets = (): void => {
    if (doesKpiHaveDonutChart(selectedKpi)) {
      props.exportDonutKpiHistoryTable(detailKeyword, dayjs(barGraphParameter.date).format('YYYY-MM-DD'), getDonutDetails(selectedKpi as KpiKeyDonut, selectedDonut, props.historyDonutState), page - 1, rowsPerPage, detailService)
    } else {
      if (detailService === 'asset-service') {
        props.exportARKpiHistoryTable(detailKeyword, dayjs(barGraphParameter.date).format('YYYY-MM-DD'), page - 1, rowsPerPage, detailService)
      } else {
        props.exportKSKpiHistoryTable(detailKeyword, dayjs(barGraphParameter.date).format('YYYY-MM-DD'), page - 1, rowsPerPage, detailService)
      }
    }
  }

  return (
    <div style={{ width: 'calc(100vw - 5.5rem)' }}>
      <div className='link-breadcrumb'>
        <span data-testid='assetManagementbreadcrumb' onClick={ () => { navigate('/isense') }} className='assetmgmtli'>{KpiDetailsStrings.Honeywell_Heading_Breadcrumb}</span>
        <span style={{ padding: '0 0.5em' }}>/</span>
        <span>{selectedKpi}</span>
      </div>
      <div className='topUtilityBar' style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <BackButton/>
          <span id='componentTitle' data-testid='kpi-details'>{selectedKpi}</span>
        </div>
        <div>
          <img onClick={() => { exportAssets() }} width={25} height={25} alt='exportIcon' src={exportIcon} id='kpi-detail-download' style={{ cursor: 'pointer' }} />
        </div>
      </div>
      <div>
        {
          doesKpiHaveDonutChart(selectedKpi)
            ? <HistoryDonutKpiBarGraph selectedDonut={selectedDonut} setSelectedDonut={setSelectedDonut} barGraphParameter={barGraphParameter} setBarGraphParameter={setBarGraphParameter} startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} />
            : <HistoryBarGraph barGraphParameter={barGraphParameter} setBarGraphParameter={setBarGraphParameter} startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} />
        }
      </div>
      <div style={{ backgroundColor: '#272727', border: '1px solid black', borderBottomLeftRadius: 10, borderBottomRightRadius: 10, marginBottom: 10 }}>
        <HistoryTable endDate={endDate} page={page} setPage={setPage} selectedDonut={selectedDonut} barGraphParameter={barGraphParameter} setBarGraphParameter={setBarGraphParameter} initModification={initModification} setInitModification={setInitModification} />
      </div>
    </div>
  )
}
interface DispatchToProps {
  exportKSKpiHistoryTable: (
    kpiType: string,
    inputDateRead: string,
    page: number,
    size: number, service: string) => void
  exportARKpiHistoryTable: (
    kpiType: string,
    inputDateRead: string,
    page: number,
    size: number, service: string) => void
  exportDonutKpiHistoryTable: (
    kpiType: string,
    inputDateRead: string,
    donutType: string,
    page: number,
    size: number, service: string) => void
  resetKpiDetails: () => void
}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  exportKSKpiHistoryTable: (
    kpiType: string,
    inputDateRead: string,
    page: number,
    size: number, service: string) => dispatch(exportKSKpiHistoryTable(kpiType, inputDateRead, page, size, service)),
  exportARKpiHistoryTable: (
    kpiType: string,
    inputDateRead: string,
    page: number,
    size: number, service: string) => dispatch(exportARKpiHistoryTable(kpiType, inputDateRead, page, size, service)),
  exportDonutKpiHistoryTable: (
    kpiType: string,
    inputDateRead: string,
    donutType: string,
    page: number,
    size: number, service: string) => dispatch(exportDonutKpiHistoryTable(kpiType, inputDateRead, donutType, page, size, service)),
  resetKpiDetails: () => dispatch(resetKpiDetails())
})

interface StateToProps {
  exportKSKpiDetailData: ExportKSKpiDetailsState
  exportARKpiDetailData: ExportARKpiDetailsState
  exportDonutKpiDetailData: ExportDonutKpiDetailsState
  historyDonutState: DonutKpiState

}

const mapStateToProps = (state: RootState): StateToProps => ({
  exportKSKpiDetailData: state.exportKSKpiDetailsData,
  exportARKpiDetailData: state.exportARKpiDetailsData,
  exportDonutKpiDetailData: state.exportDonutKpiDetailsData,
  historyDonutState: state.historyDonutState
})

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(KpiDetails)
