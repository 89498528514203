import { Footer, Header, SidebarLayout } from '@scuf/common'
import React, { useCallback, useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { useAuth } from 'react-oidc-context'
import IdleModal from './IdleModal'
import { escapeHTML } from '../../../utils/escapeHTML'
import { useDispatch } from 'react-redux'
import { changeHamburgerState } from '../redux/hamburgerSlice'
import signOutIcon from '../../../assets/signOutIcon.svg'
import LanguageStrings from '../../../i18n/locales'
import { getLicenseText, openFileInNewTab } from '../services/getFileService'
import Modal from '../../../components/Modal'
import { CircularProgress } from '@mui/material'
import { GenericError } from '../../../components/ErrorComponents/GenericError'

const dashboardStrings = LanguageStrings().Dashboard

const DashboardLayout = (props: any): any => {
  const dispatch = useDispatch()
  const [sidebarCollapsed, setSidebarCollapsed] = useState(true)
  const [showLicense, setShowLicense] = useState(false)
  const [license, setLicense] = useState<'loading' | 'error' | string | undefined>('loading')
  const auth = useAuth()

  // istanbul ignore next
  const serviceVersion = window._env_?.REACT_APP_SERVICE_VERSION !== undefined ? window._env_.REACT_APP_SERVICE_VERSION : ''

  useEffect(() => {
    // istanbul ignore else
    if (sidebarCollapsed) {
      document.body.style.overflow = 'unset'
    } else {
      document.body.style.overflow = 'hidden'
    }
  }, [sidebarCollapsed])

  // istanbul ignore next
  const onCollapsedClick = (): any => {
    dispatch(changeHamburgerState(sidebarCollapsed ? 'open' : 'close'))
    setSidebarCollapsed(!sidebarCollapsed)
  }

  const onLogout = (): void => {
    // istanbul ignore next
    auth.signoutRedirect({
      post_logout_redirect_uri: escapeHTML(window.location.origin) + '/logout'
    })
      .catch((e) => {
        // istanbul ignore next
        console.log(e)
      })
  }
  const navigate = useNavigate()
  const handleHomeOnClick = useCallback(() => {
    // istanbul ignore next
    navigate('/', { replace: true })
  }, [navigate])
  const handleHelpClick = (): void => {
    document.body.style.cursor = 'wait'
    openFileInNewTab('UDP.pdf')
      .catch((e) => {
      // istanbul ignore next
        console.log(e)
      })
  }

  const shouldShowSearchIcon = (): boolean => {
    return false
  }

  const handleLicenseClick = (): void => {
    setShowLicense(true)
    if (license === 'loading' || license === 'error') {
      setLicense('loading')
      getLicenseText('license.txt')
        .then(response => {
          if (response !== undefined) {
            setLicense(response)
          } else {
            setLicense('error')
          }
        })
        .catch(e => {
          setLicense('error')
        })
    }
  }

  const handleDownloadLicense = (): void => {
    if (license !== undefined) {
      const blob = new Blob([license], { type: 'text/plain' })
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = 'license.txt'
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      URL.revokeObjectURL(url)
    }
  }

  const getLicenseModal = (): JSX.Element => {
    return (
      <div data-testid='licenseText' style={{ backgroundColor: '#404040', color: '#F0F0F0', overflowY: 'scroll', height: 'calc(80vh -  160px)', marginTop: '20px', fontFamily: 'Honeywell Sans Web' }}>
        <pre
          className="license-text"
          style={{
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            fontSize: '14px',
            padding: '20px'
          }}
        >
          {license}
        </pre>
      </div>
    )
  }

  const isValidLicene = (): boolean => {
    return license !== undefined && license !== 'loading' && license !== 'error'
  }

  const handleCloseLicense = (): void => {
    setShowLicense(false)
  }

  return (
        <div id='MainLayoutFlexContainer' data-testid='Home'>
            <Header title='' style={{ position: 'fixed', top: 0 }} onMenuToggle={() => onCollapsedClick()}>
                { shouldShowSearchIcon() && (<Header.Search onSearch={() => undefined}/>) }
                {auth.isAuthenticated
                  ? <Header.UserProfile firstName={auth.user?.profile.given_name ?? ''}
                                          lastName={auth.user?.profile.family_name}
                                          imageUrl={auth.user?.profile.avatar as string}>
                        <Header.UserProfile.Item data-testid="user-name-id">
                          <span style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <img src={auth.user?.profile.avatar as string} style={{ marginRight: 8, height: 25, width: 25, borderRadius: '100%' }} alt='logout-icon' /> Hi, {auth.user?.profile.name?.split(' ')[0] ?? ''}
                          </span>
                        </Header.UserProfile.Item>
                        <Header.UserProfile.Item data-testid="logout-id" onClick={onLogout}>
                          <span style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <img src={signOutIcon} style={{ paddingRight: 8 }} alt='logout-icon' /> Sign out
                          </span>
                        </Header.UserProfile.Item>
                    </Header.UserProfile>
                  : <></>
                }
            </Header>
            <div id='bottom-flex-container'>
                <SidebarLayout collapsed={sidebarCollapsed} className='example-sidebar'>
                    <SidebarLayout.Sidebar>
                        <SidebarLayout.Sidebar.Item content='My Dashboard' icon='home' onClick={handleHomeOnClick}
                                                    test-id='Home'/>
                        <SidebarLayout.Sidebar.Item
                            content='Asset Management'
                            icon='document-blank'
                            aria-label='notification'
                            onClick={() => {
                              // istanbul ignore next
                              navigate('/card')
                            }}
                        />
                        <SidebarLayout.Sidebar.Item
                            content='Configuration Management'
                            icon='settings'
                            aria-label='notification'
                            onClick={() => {
                              // istanbul ignore next
                              navigate('/configuration-dashboard')
                            }}
                        />
                        <SidebarLayout.Sidebar.Item content='help' icon='badge-help'
                                                    data-testid='help' onClick={handleHelpClick}/>
                    </SidebarLayout.Sidebar>
                    <SidebarLayout.Content onClick={() => { setSidebarCollapsed(true) }}>
                        <div id='sidebar-layout-content-flex-container' style={{ pointerEvents: sidebarCollapsed ? 'unset' : 'none', userSelect: sidebarCollapsed ? 'unset' : 'none', paddingTop: 40 }} className={`sidebar-layout-content ${sidebarCollapsed ? '' : 'blurred'}`}>
                            <div className='outlet-content'>
                                <Outlet/>
                            </div>
                            <Footer style={{ width: 'calc(100vw - 3.5rem)' }}>
                                <Footer.Item>{dashboardStrings.Version} - {serviceVersion}</Footer.Item>
                                <Footer.Item href='https://www.honeywell.com/us/en/privacy-statement' target='_blank'>{dashboardStrings.Privacy_Policy}</Footer.Item>
                                <Footer.Item href='https://Honpat.com' target='_blank'>{dashboardStrings.Patents}</Footer.Item>
                                <Footer.Item><span onClick={handleLicenseClick}>{dashboardStrings.License}</span></Footer.Item>
                            </Footer>
                        </div>
                    </SidebarLayout.Content>
                </SidebarLayout>
            </div>
            {
              showLicense && <Modal title={'OSS License'} width='70vw' height={isValidLicene() ? '80vh' : 'fit-content'} onClose={handleCloseLicense} LabelPositive={isValidLicene() ? 'Download' : undefined} onClickPositive={handleDownloadLicense}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  {
                    license === 'loading'
                      ? <div className='CircularprogressAmi'>
                        <CircularProgress />
                      </div>
                      : license === 'error'
                        ? <GenericError style={{ height: '300px' }} retry={handleLicenseClick}><p>Unable to load license</p></GenericError>
                        : getLicenseModal()
                  }
                </div>
              </Modal>
            }
            <div className='IdleModal'>

                {auth.isAuthenticated
                  // eslint-disable-next-line operator-linebreak
                  ?
                  // istanbul ignore next
                  <IdleModal onLogout={onLogout} />
                  : <></>
                }
            </div>
        </div>
  )
}

export default DashboardLayout
