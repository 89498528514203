import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)
// use this if timestamp has both date and time
export const parseDateInAOHstandard = (date: string | Date): string => {
  return new Date(date).toUTCString()
}

export function convertDateIntoAohStandardInUTC (date: string | null | Date): string {
  if (date === null) return ''
  console.log('date', date)
  console.log('dayjs.utc(date)', dayjs.utc(date))
  return dayjs.utc(date).format('ddd, DD MMM YYYY') // Use utc to handle possible UTC string correctly
}

// use this if timestamp has only date
export function convertDateIntoAohStandardUsingDayjs (date: string | null | Date): string {
  if (date === null) return ''
  return dayjs(date).format('ddd, DD MMM YYYY')
}

export function convertDateIntoAohStandardUnSeperated (date: string | null | Date): string {
  if (date === null) return ''
  return dayjs(date).format('ddd DD MMM YYYY')
}
