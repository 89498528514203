import { z } from 'zod'
import interceptedAxios from '../../../utils/interceptedAxios'
import type dayjs from 'dayjs'

export interface assetStateReq {
  assetStateType: string
  assetIds: string[]
  transitionDate: dayjs.Dayjs
  confirmedUpdate: boolean
}

const ZApiResponse = z.object({
  http_status: z.number(),
  error: z.boolean(),
  data: z.optional(z.string()),
  error_message: z.optional(z.string())
})

export async function getUpdateInfoAssetStateData (
  assetStateParamter: assetStateReq,
  tenantId: string,
  onSuccess: (AssetStateUpdateSatus: any, httpStatus: number) => any,
  onFailure: (errorMessage: any, httpStatus: number) => any
): Promise<void> {
  const url = '/asset-service/api/v1/udp/assets/updateLifeCycleState'
  interceptedAxios
    .put(url, assetStateParamter)
    .then((response) => {
      const parseResponse = ZApiResponse.safeParse(response.data)
      // istanbul ignore else
      if (parseResponse.success) {
        onSuccess(parseResponse.data.data, parseResponse.data.http_status)
      } else {
        onFailure('Unable to update state', response.status)
      }
    })
    .catch((error) => {
      // istanbul ignore else
      if (error.response !== undefined) {
        const parseResponse = ZApiResponse.safeParse(error.response.data)
        // istanbul ignore next
        if (parseResponse.success) {
          onFailure(parseResponse.data.error_message, parseResponse.data.http_status)
        } else {
          onFailure('Unable to update state', error.response.status)
        }
      } else {
        onFailure('Network Error', error.status)
      }
    })
}
