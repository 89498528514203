/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useEffect, useRef, useState } from 'react'
import './searchbox.css'
import searchIcon from '../assets/searchIcon.svg'
import CloseIcon from '@mui/icons-material/Close'

interface SearchBoxProps {
  searchVal: string | null
  setSearchVal: (val: string) => void
  classes?: string
  parameters?: [] | string
  performAction: () => void
  emptyEnterFallback: () => void
  placeholder?: string
  sx?: React.CSSProperties
  containerStyle?: React.CSSProperties
  immediate?: boolean
  inputStyle?: React.CSSProperties
  crossBarStyle?: React.CSSProperties
  debounce?: boolean
  onClearSearch?: (fn: () => void) => void
}

const SearchBox = (props: SearchBoxProps): JSX.Element => {
  const [isInputEmpty, setIsInputEmpty] = useState(true)
  const [init, setInit] = useState(false)
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null)
  const [shouldTriggerEffect, setShouldTriggerEffect] = useState(true)

  const debounce = (func: () => void, delay: number): void => {
    console.log('debounce', timer)
    if (timer.current !== null) clearTimeout(timer.current)
    timer.current = setTimeout(func, delay)
  }

  const triggerSearch = (): void => {
    if (props.performAction !== undefined && typeof props.performAction === 'function' && props.searchVal !== '') {
      props.performAction()
    } else if (props.searchVal === '') {
      props.emptyEnterFallback()
    }
  }
  const handleKeyEnter = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      if (props.searchVal !== null) {
        triggerSearch()
      }
    }
  }

  useEffect(() => {
    return () => {
      if (timer.current !== null) {
        clearTimeout(timer.current)
      }
    }
  }, [])

  useEffect(() => {
    if (!shouldTriggerEffect) {
      setShouldTriggerEffect(true)
      return
    }

    if (timer.current !== null) {
      clearTimeout(timer.current)
    }
    if (props.immediate !== undefined && props.immediate && init && (props.debounce ?? false) && props.searchVal !== '') {
      debounce(() => {
        if (props.searchVal !== null) {
          triggerSearch()
        }
      }, 1000)
    } else if (props.immediate !== undefined && props.immediate && init && !(props.debounce ?? false) && props.searchVal !== '') {
      if (props.searchVal !== null) {
        triggerSearch()
      }
    } else if (props.searchVal === '' && init) {
      props.emptyEnterFallback()
    }
    setInit(true)
  }, [props.searchVal])

  // istanbul ignore next
  const handleClearInput = (): void => {
    props.setSearchVal('') // Clear the input
    setIsInputEmpty(true)
    props.emptyEnterFallback()
  }

  const clearSearchWithoutEffect = (): void => {
    setShouldTriggerEffect(false)
    props.setSearchVal('')
    setIsInputEmpty(true)
  }

  useEffect(() => {
    if (props.onClearSearch !== undefined) {
      props.onClearSearch(clearSearchWithoutEffect)
    }
  }, [props.onClearSearch])

  return (
    <div style={{ ...(props.containerStyle?.margin ? {} : { margin: '10px 17px', ...props.containerStyle }) }} className={`custom-search-box-component ${props.classes !== undefined ? props.classes : ''}`}>
      <div className='custom-search-box' style={props.sx !== undefined ? props.sx : {}}>
        <i className='search-box-icon-img'><img className='search-icon' src={searchIcon} /></i>
        <input data-testid='search-box-input' className='search-box-input' style={props.inputStyle}
          onChange={(e) => {
            props.setSearchVal(e.target.value)
            setIsInputEmpty(e.target.value === '')
          }}
          placeholder={props.placeholder !== undefined ? props.placeholder : 'Search using Serial Number, Asset Name, Tag'}
          onKeyDown={handleKeyEnter}
          value={props.searchVal ?? ''} />
        <span className='search-cross-bar' style={props.crossBarStyle}>{(!isInputEmpty && props.searchVal !== null && props.searchVal !== '') && (<CloseIcon onClick={handleClearInput}/>)}</span>
      </div>
    </div>
  )
}

export default SearchBox
