import { Box, CircularProgress, IconButton, Menu, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, styled, tableCellClasses } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Pagination from '../../../components/TablePagination'
import React, { useEffect } from 'react'
import AssignAssetWindow from './AssignAssetWindow'
import { type ConnectedProps, connect } from 'react-redux'
import { type RootState } from '../../../store'
import { fetchConfigurationList, fetchConfigurationListBySearch } from '../redux/actionCreator'
import { type ConfigurationListState } from '../redux/configurationListSlice'
import { type ConfigurationObj, type GroupParaObj } from '../services/configurationService'
import { changeTemplateId } from '../redux/templateIdSlice'
import AuthError from '../../../components/ErrorComponents/AuthError'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useAuth } from 'react-oidc-context'
import { type OperationPermissionState } from '../../dashboard/redux/operationPermissionSlice'
import ConfigAssignGroupPopUp from '../components/ConfigAssignGroupPopup'
import UnassignGroupConfigurationPopup from './UnassignGroupConfigurationPopup'
import { getGroupModel } from './getGroupModel'
import SearchBox from '../../../components/SearchBox'
import { type ConjuctionOptions, type AdvancedFilterPostRequest } from '../../asset-group/types'

const style = {
  width: '99%',
  bgcolor: '#313030',
  border: '1px solid #101010',
  borderRadius: '10px',
  boxShadow: '1px 1px 1px rgba(0, 0, 0, 0.15)',
  marginBottom: '16px',
  padding: 0
}

function EnhancedTableHead (): any {
  return (
    <TableHead data-testid='table-header'>
      <TableRow>
        {headCells.map((headCell: HeadCell) => (
          <StyledTableCell
            key={headCell.id}
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
          >
            <>{headCell.label}</>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

interface HeadCell {
  disablePadding: boolean
  id: any
  label: string
  numeric: boolean
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: '#F0F0F0',
    fontWeight: '700',
    fontSize: 12,
    border: '2px solid #202020',
    padding: '5px 16px',
    fontFamily: 'Honeywell Sans Web'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: '#d0d0d0',
    paddingLeft: '17px',
    borderBottom: '1px solid #202020',
    borderRight: 0,
    fontFamily: 'Honeywell Sans Web'
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '& th': {
    border: '1px solid #202020'
  }
}))

const headCells: readonly HeadCell[] = [
  {
    id: 'template_name',
    numeric: false,
    disablePadding: false,
    label: 'NAME'
  },
  {
    id: 'category_type_name',
    numeric: false,
    disablePadding: false,
    label: 'CATEGORY'
  },
  {
    id: 'asset_type_name',
    numeric: false,
    disablePadding: false,
    label: 'ASSET TYPE'
  },
  {
    id: 'groups',
    numeric: false,
    disablePadding: false,
    label: 'GROUP NAME'
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'STATUS'
  },
  {
    id: 'assets_associated',
    numeric: true,
    disablePadding: false,
    label: 'TOTAL ASSETS'
  },
  {
    id: 'action',
    numeric: true,
    disablePadding: false,
    label: 'ACTION'
  }
]

type Order = 'asc' | 'desc'

interface Sort {
  order: Order
  orderBy: string
}

const ConfigurationTable = (props: PropsFromRedux): JSX.Element => {
  const [configList, setConfigList] = React.useState<ConfigurationObj[]>([])
  const [selectedAssetType, setSelectedAssetType] = React.useState<string>('')
  const [selectedConfiguration, setSelectedConfiguration] = React.useState<string>('')
  const [selectedRow, setSelectedRow] = React.useState<any>(null)
  const [showGroup, setshowGroup] = React.useState<GroupParaObj[]>([])
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [showModifyAssetList, setShowModifyAssetList] = React.useState('')
  const [page, setPage] = React.useState(1)
  const [sort] = React.useState<Sort>({ order: 'asc', orderBy: 'template_id' })
  const rowsPerPage = 10
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const auth = useAuth()
  const [showAssignGroupPopUp, setShowAssignGroupPopUp] = React.useState(false)
  const [showUnassignGroupPopUp, setShowUnassignGroupPopUp] = React.useState(false)
  const [unassignGroupRow, setUnassignGroupRow] = React.useState<GroupParaObj[]>([])
  const [anchorAssetType, setAnchorAssetType] = React.useState('')
  const [anchorTemplateId, setAnchorTemplateId] = React.useState('')
  const [selectedGroups, SetSelectedGroups] = React.useState<GroupParaObj[]>([])
  const [searchValue, setSearchValue] = React.useState<string | null>(null)
  const [apiToBeCalled, setApiToBeCalled] = React.useState('default')
  const [init, setInit] = React.useState(false)

  const handleFetchConfigList = (): void => {
    if (page >= 1) {
      props.fetchConfigurationList(page - 1, rowsPerPage, sort.order, sort.orderBy, auth.user?.profile.customer as string)
    }
  }

  const postConfigStatusForSearch = (searchValue: string | null): AdvancedFilterPostRequest => {
    const keys = [
      'templateName',
      'assetTypeName',
      'status',
      'groupNames',
      'categoryTypeName'
    ]

    const conditions = keys.map(key => ({
      key,
      operation: 'Contains',
      value: searchValue ?? ''
    }))

    return {
      conditions,
      conjuction: 'OR' as ConjuctionOptions
    }
  }

  /* istanbul ignore next */
  const handleSearchApi = (): void => {
    props.fetchConfigurationListBySearch(postConfigStatusForSearch(searchValue), page - 1, rowsPerPage, sort.order, sort.orderBy)
  }

  // istanbul ignore next
  const handleSearch = (): void => {
    if (apiToBeCalled === 'search') {
      if (page === 1) {
        handleSearchApi()
      } else {
        setPage(1)
      }
    } else {
      setApiToBeCalled('search')
    }
  }

  useEffect(() => {
    if (apiToBeCalled === 'default') {
      handleFetchConfigList()
    } else if (apiToBeCalled === 'search') {
      handleSearchApi()
    }
  }, [page, sort])

  useEffect(() => {
    if (init) {
      if (page > 1) {
        setPage(1)
      } else {
        if (apiToBeCalled === 'default') {
          handleFetchConfigList()
        } else if (apiToBeCalled === 'search') {
          handleSearch()
        }
      }
    } else {
      setInit(true)
    }
  }, [apiToBeCalled])

  useEffect(() => {
    if (props.configurationListState.configurationList !== null && props.configurationListState.configurationList.configuration_templates.length > 0) {
      setConfigList(props.configurationListState.configurationList.configuration_templates)
    }
  }, [props.configurationListState.configurationList])

  const handleEllipsisClick = (event: any, row: string): void => {
    setAnchorEl(event.currentTarget)
    setSelectedRow(row)
  }

  const handleMenuClose = (): void => {
    setAnchorEl(null)
    setSelectedRow(null)
  }

  const onAssetGroupPopUpClose = (): void => {
    setShowAssignGroupPopUp(false)
    setShowUnassignGroupPopUp(false)
  }

  const handleRowClick = (templateId: string): void => {
    dispatch(changeTemplateId(templateId))
    sessionStorage.setItem('templateId', templateId)
    navigate('/asset-config-details')
  }

  const handleAssignClick = (assetTypeId: string, templateId: string, modifyOption: string): void => {
    setSelectedAssetType(assetTypeId)
    setSelectedConfiguration(templateId)
    setShowModifyAssetList(modifyOption)
    setSelectedRow(null)
  }

  const onAssetWindowClose = (): void => {
    setShowModifyAssetList('')
  }

  const handleCofigurationTable = (): JSX.Element => {
    if (props.configurationListState.configurationList !== null && props.configurationListState.configurationList.configuration_templates.length > 0 && props.configurationListState.httpStatus === 200) {
      return (
      <Paper
      sx={{
        width: '100%',
        mb: 2,
        backgroundColor: '#272727',
        boxShadow: 'unset',
        color: '#F0F0F0',
        marginBottom: '0'
      }}
    >
      <TableContainer className="device-table">
        <Table
          sx={{ minWidth: 750, color: '#f0f0f0' }}
          aria-labelledby="tableTitle"
          size={'small'}
        >
          <EnhancedTableHead />
          <TableBody>
            {configList.length > 0 &&
              configList.map((row: ConfigurationObj, index: number) => {
                const labelId = `enhanced-table-checkbox-${row.template_id}`

                return (
                  <StyledTableRow
                    hover
                    tabIndex={-1}
                    key={row.template_id}
                    aria-label="devices-list-row"
                  >
                    <StyledTableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                      aria-label="device-name"
                      onClick={() => { handleRowClick(row.template_id) }}
                      data-testid="device-name"
                      sx={{
                        color: '#64C3FF !important',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: '25vw',
                        cursor: 'pointer'
                      }}
                    >
                      {row.template_name}
                    </StyledTableCell>
                    <StyledTableCell>
                      {row.category_type_name.toUpperCase()}
                    </StyledTableCell>
                    <StyledTableCell>
                      {row.asset_type_name}
                    </StyledTableCell>
                    <StyledTableCell>
                      {(row.groups === null || Object.keys(row.groups).length === 0)
                        ? ''
                        : row.groups.length === 1
                          ? <span data-testid={`assigned-single-group-${index}`} onClick={() => { setshowGroup(row.groups) }} style={{ color: '#64C3FF', cursor: 'pointer' }}>{row.groups[0]?.group_name}</span>
                          : <span data-testid={`assigned-groups-${index}`} onClick={() => { setshowGroup(row.groups) }} style={{ color: '#64C3FF', cursor: 'pointer' }}>{row.groups[0]?.group_name}+{row.groups.length - 1}</span>
                      }
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{
                        color:
                          row.status.toUpperCase() === 'ACTIVE'
                            ? '#68D560 !important'
                            : '#FF644C !important'
                      }}
                    >
                      {(row.status[0].toUpperCase() + row.status.slice(1).toLowerCase())}
                    </StyledTableCell>
                    <StyledTableCell>
                      {row.assets_associated}
                    </StyledTableCell>
                    <StyledTableCell sx={{ paddingLeft: 0 }}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          height: '20px',
                          fontSize: '14px'
                        }}
                      >
                        {props.operationPermissions.permissions.can_modify_asset_config && row.isDefault !== true &&
                          <MenuItem
                            data-testid="assign-group-btn"
                            style={{ paddingLeft: '0px' }}
                            onClick={() => {
                              // handleAssignClick(row.asset_type_id, row.template_id, 'Assign')
                              SetSelectedGroups(row.groups)
                              setAnchorAssetType(row.asset_type_name)
                              setAnchorTemplateId(row.template_id)
                              setShowAssignGroupPopUp(true)
                              setSelectedRow(null)
                            }}
                          >
                            <span style={{
                              color: '#64c3ff',
                              textDecoration: 'none',
                              paddingLeft: '1px'
                            }}>Assign Group</span>
                          </MenuItem>
                        }
                        {props.operationPermissions.permissions.can_modify_asset_config && row.isDefault !== true &&
                          <IconButton
                            aria-controls={`menu-${index}`}
                            aria-haspopup="true"
                            data-testid="device-ellipsis"
                            onClick={(event) => {
                              handleEllipsisClick(event, row.template_id)
                            }}
                            sx={{ padding: '0px !important' }}
                          >
                            <MoreVertIcon />
                          </IconButton>
                        }
                        <Menu
                          id={`menu-${row.template_id}`}
                          anchorEl={anchorEl}
                          open={
                            Boolean(anchorEl) &&
                            selectedRow === row.template_id
                          }
                          onClose={handleMenuClose}
                        >
                          {(Object.keys(row.groups).length !== 0)
                            ? <MenuItem
                                data-testid="unassign-group-btn"
                                style={{
                                  color: '#64c3ff',
                                  textDecoration: 'none'
                                }}
                                onClick={() => {
                                  setAnchorAssetType(row.asset_type_name)
                                  setAnchorTemplateId(row.template_id)
                                  setUnassignGroupRow(row.groups === null ? [] : row.groups)
                                  setShowUnassignGroupPopUp(true)
                                  setSelectedRow(null)
                                }}
                              >
                                Unassign Group
                              </MenuItem>
                            : ''
                          }
                          <MenuItem
                            data-testid="assign-asset-btn"
                            style={{
                              color: '#64c3ff',
                              textDecoration: 'none'
                            }}
                            onClick={() => {
                              handleAssignClick(row.asset_type_id ?? '', row.template_id, 'Assign')
                            }}
                          >
                            Assign Asset
                          </MenuItem>
                          <MenuItem
                            data-testid="unassign-btn"
                            style={{
                              color: '#64c3ff',
                              textDecoration: 'none'
                            }}
                            onClick={() => {
                              handleAssignClick(row.asset_type_id ?? '', row.template_id, 'Unassign')
                            }}
                          >
                            Unassign Asset
                          </MenuItem>
                        </Menu>
                      </div>
                    </StyledTableCell>
                  </StyledTableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {props.configurationListState.configurationList.total_count > 10 && (<Pagination count={props.configurationListState.configurationList.total_count} page={page} setPage={setPage} rowsPerPage={rowsPerPage} siblingCount={1}
        boundaryCount={1} />)}
      {showAssignGroupPopUp && (
        <ConfigAssignGroupPopUp
          assignedGroups={selectedGroups.map(group => group.group_name)}
          onAssetGroupPopUpClose={onAssetGroupPopUpClose}
          assetType={anchorAssetType}
          templateId={anchorTemplateId}
          refreshConfigurationList={handleFetchConfigList}
        />
      )}
      {showUnassignGroupPopUp && (
        <UnassignGroupConfigurationPopup
          onAssetGroupPopUpClose={onAssetGroupPopUpClose}
          assetType={anchorAssetType}
          templateId={anchorTemplateId}
          UnassigGroups={unassignGroupRow}
          handleFetchConfigList={handleFetchConfigList}
        />
      )}
    </Paper>
      )
    } else if (props.configurationListState.isLoading) {
      return (
      <div className='CircularprogressAmi'>
        <CircularProgress />
      </div>
      )
    } else if (props.configurationListState.httpStatus === 401) {
      return (
      <div className='authError'><AuthError errorMessage="Unauthorized" /></div>
      )
    } else if (props.configurationListState.httpStatus === 403) {
      return (
      <div className='authError'><AuthError errorMessage="accessForbidden" /></div>
      )
    } else if ((props.configurationListState.configurationList === null && props.configurationListState.httpStatus === 200) || (props.configurationListState.configurationList?.configuration_templates.length === 0 && props.configurationListState.httpStatus === 200)) {
      return (
      <div className='authError'><AuthError errorMessage="NoDataPresent" /></div>
      )
    } else if ((props.configurationListState.configurationList === null && props.configurationListState.error === 'No configuration templates found')) {
      return (
      <div className='authError'><AuthError errorMessage="NoDataPresent" /></div>
      )
    } else if (props.configurationListState.httpStatus === 400) {
      return <div className="authError"><AuthError errorMessage="invalidSearchInput" /></div>
    } else {
      return (
      <div className='authError'><AuthError errorMessage="cannotFetch" retry={handleFetchConfigList} /></div>
      )
    }
  }

  return (
      <>
        <Box sx={style} className="devices">
        <div className='searchSection' style={{ width: '100%', backgroundColor: '#272727' }}>
          <Paper className='searchBoxComponent'
            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
          >
            <SearchBox immediate={true} debounce={true} placeholder='Search using Name, Category, Asset Type, Group Name' sx={{ width: '445px', marginLeft: '-15px' }}
            emptyEnterFallback={() => {
              setApiToBeCalled('default')
            }} searchVal={searchValue} setSearchVal={setSearchValue}
            performAction={handleSearch} />
          </Paper>
        </div>
        {handleCofigurationTable()}
        </Box>
        {
          (showGroup.length > 0)
            ? getGroupModel(showGroup, () => { setshowGroup([]) })
            : <></>
        }
        {showModifyAssetList !== '' && <AssignAssetWindow handleFetchConfigList={handleFetchConfigList} assetTypeId={selectedAssetType} templateId={selectedConfiguration} onClose={onAssetWindowClose} assetListOption={showModifyAssetList} />}
      </>
  )
}

interface DispatchToProps {
  fetchConfigurationList: (page: number, size: number, sortdir: string, sortfield: string, tenantId: string) => void
  fetchConfigurationListBySearch: (advancedFilterPostRequest: AdvancedFilterPostRequest, page: number, size: number, sortdir: string, sortfield: string) => void
}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  fetchConfigurationList: (page: number, size: number, sortdir: string, sortfield: string, tenantId: string) => dispatch(fetchConfigurationList(page, size, sortdir, sortfield, tenantId)),
  fetchConfigurationListBySearch: (advancedFilterPostRequest: AdvancedFilterPostRequest, page: number, size: number, sortdir: string, sortfield: string) => dispatch(fetchConfigurationListBySearch(advancedFilterPostRequest, page, size, sortdir, sortfield))
})

interface StateToProps {
  configurationListState: ConfigurationListState
  operationPermissions: OperationPermissionState
}

const mapStateToProps = (state: RootState): StateToProps => ({
  configurationListState: state.configurationList,
  operationPermissions: state.operationPermissions
})

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(ConfigurationTable)
