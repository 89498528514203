import { z } from 'zod'
import interceptedAxios from '../../../utils/interceptedAxios'

export interface GroupUpdationRequest {
  asset_group_id: string
  assets: string[]
}

const ZApiResponse = z.object({
  http_status: z.number(),
  data: z.boolean(),
  error: z.boolean()
})

export type ApiResponse = z.infer<typeof ZApiResponse>

export async function updateAssetGroup (
  groupUpdationBody: GroupUpdationRequest,
  onSuccess: (successResponse: ApiResponse) => any,
  onFailure: (errorMessage: string, httpStatus: number) => any
): Promise<void> {
  const url = '/asset-service/api/v1/udp/groupManagement/assign'

  interceptedAxios
    .put(url, groupUpdationBody)
    .then((response) => {
      if (response.status === 200) {
        const parseResponse = ZApiResponse.safeParse(response.data)
        if (parseResponse.success) {
          onSuccess(parseResponse.data)
        } else {
          onFailure('Error occurred while processing response', response.status)
        }
      } else {
        onFailure(response.statusText, response.status)
      }
    })
    .catch((error) => {
      onFailure(error.response !== undefined ? error.response.statusText : 'Network Error', error.response !== undefined ? error.response.status : 404)
    })
}
