import { z } from 'zod'
import interceptedAxios from '../../../utils/interceptedAxios'
import type dayjs from 'dayjs'

export interface editSummaryReq {
  tag: [{ name: string }]
  state: string
  installDate: dayjs.Dayjs
  ip: { ip_address: string, port: string }
  confirmedStateUpdate: boolean
}

const ZApiResponse = z.object({
  http_status: z.number(),
  error: z.boolean(),
  data: z.boolean()
})

export async function editAssetSummary (
  editSummaryParamter: editSummaryReq,
  assetId: string,
  tenantId: string,
  onSuccess: (EditAssetSummaryUpdateSatus: any, httpStatus: number) => any,
  onFailure: (errorMessage: any, httpStatus: number) => any
): Promise<void> {
  const url = `/asset-service/api/v1/udp/assets/${assetId}/attributes`
  interceptedAxios
    .put(url, editSummaryParamter)
    .then((response) => {
      const parseResponse = ZApiResponse.safeParse(response.data)
      // istanbul ignore else
      if (parseResponse.success) {
        onSuccess(parseResponse.data.data, parseResponse.data.http_status)
      } else {
        onFailure('Unable to update state', response.status)
      }
    })
    .catch((error) => {
      // istanbul ignore else
      if (error.response !== undefined) {
        onFailure(error.response.data.error_message, error.response.data.http_status)
      } else {
        onFailure('Network Error', error.status)
      }
    })
}
