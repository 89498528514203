/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useRef } from 'react'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import LanguageStrings from '../../../i18n/locales'
import { useAuth } from 'react-oidc-context'
import { useNavigate } from 'react-router-dom'
import Tooltip from '@mui/material/Tooltip'
import { CircularProgress } from '@mui/material'
import AuthError from '../../../components/ErrorComponents/AuthError'
import Modal from '@mui/material/Modal'
import { Button as GenericButton } from '../../../components/Button'
import WarningIcon from '@mui/icons-material/WarningAmber'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import Checkbox from '@mui/material/Checkbox'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import { visuallyHidden } from '@mui/utils'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { type SelectChangeEvent } from '@mui/material/Select'
import IconButton from '@mui/material/IconButton'
import FileExport from '../../../assets/exportIcon.svg'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import { styled } from '@mui/material/styles'
import { useSelector } from 'react-redux'
import InputBase from '@mui/material/InputBase'
import SearchIcon from '@mui/icons-material/Search'
import Pagination from '../../../components/TablePagination'
import { type RootState } from '../../../store'
import { type ConnectedProps, connect } from 'react-redux'
import Typography from '@mui/material/Typography'
import ListItemText from '@mui/material/ListItemText'
import ReplayIcon from '@mui/icons-material/Replay'
import FilterIcon from '../../../assets/filterIcon.png'
import CloseIcon from '@mui/icons-material/Close'
import Drawer from '@mui/material/Drawer'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import { type firmwareListingDetailsState } from '../redux/firmwareListInfoSlice'
import { type deleteFirmwareState } from '../redux/deleteFirmwareSlice'
import {
  deleteFirmware,
  getFirmwareListingDetails,
  getFirmwareListingDetailsFilteredData,
  getFirmwareListingFilterAssetType,
  getFirmwareListingFilterFirmwareType,
  getFirmwareListingFilterVersion,
  exportFirmwareList,
  resetDeleteParams
} from '../redux/actionCreators'
import Button from '@mui/material/Button'
import './firmwareList.css'
import BackButton from '../../../components/BackButton'
import { getTemplateModal } from '../../asset-group/components/createGroupComponents/utils/getTemplateModal'
import {
  type FirmwareListingDetails,
  type GroupParaObj
} from '../services/firmwareList'
import CreateCampaignPopup from '../components/CreateCampaignPopup'

const FirmwareTableStrings =
  LanguageStrings().AssetFirmwareStrings.AssetFirmwareTable
const provisioningDetailStrings =
  LanguageStrings().ProvisioningDetailStrings.ProvisioningDetailTable
let searchValue: any

interface Data {
  firmwareType: string
  assetType: string
  version: string
  minDEpendency1: string
  minDEpendency2: string
  imageIdentifier: string
  action: string
}

const style = {
  width: '100%',
  bgcolor: '#272727',
  border: '1px solid #101010',
  borderRadius: '10px',
  boxShadow: '1px 1px 1px rgba(0, 0, 0, 0.15)',
  marginBottom: '16px',
  padding: 0
}

const successModalStyle = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 399,
  bgcolor: '#272727',
  fontFamily: 'Honeywell Sans Web',
  p: 4
}
const modalStyle = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: '#272727',
  fontFamily: 'Honeywell Sans Web',
  p: 4
}

type Order = 'asc' | 'desc'

interface HeadCell {
  disablePadding: boolean
  id: any
  label: string
  numeric: boolean
}

interface HeadCell {
  disablePadding: boolean
  id: any
  label: string
  numeric: boolean
  sortable: boolean
}

const headCells: readonly HeadCell[] = [
  {
    id: 'firmwareType',
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: FirmwareTableStrings.firmwareType
  },
  {
    id: 'assetType',
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: FirmwareTableStrings.assetType
  },
  {
    id: 'version',
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: FirmwareTableStrings.version
  },
  {
    id: 'groupName',
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: FirmwareTableStrings.groupName
  },
  {
    id: 'minimumMeterVersion',
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: FirmwareTableStrings.minDEpendency1
  },
  {
    id: 'minimumCommunicationVersion',
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: FirmwareTableStrings.minDEpendency2
  },
  {
    id: 'imageIdentifier',
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: FirmwareTableStrings.imageIdentifier
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: FirmwareTableStrings.description
  },
  {
    id: 'action',
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: FirmwareTableStrings.action
  }
]

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    newOrderBy: keyof Data
  ) => void
  order: Order
  orderBy: string
  rowCount: any
}

function EnhancedTableHead (props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props
  const createSortHandler =
    (newOrderBy: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, newOrderBy)
    }
  return (
    <TableHead data-testid="firmware-table-header">
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            className="firmware-table-header"
            key={headCell.id}
            align={'left'}
            padding={'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label !== FirmwareTableStrings.action ? (
              <TableSortLabel
                hideSortIcon={!headCell.sortable}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={
                  headCell.sortable ? createSortHandler(headCell.id) : undefined
                }
                aria-label={headCell.label}
                data-testid="test-sort"
                className="firmware-table-header"
                sx={{ cursor: headCell.sortable ? 'pointer' : 'text' }}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box
                    data-testid="sort-firmware-table"
                    component="span"
                    sx={visuallyHidden}
                  >
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              <div>{FirmwareTableStrings.action.toUpperCase()}</div>
            )}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

// interface EnhancedTableToolbarProps {
//   handleChange: (event: SelectChangeEvent) => void
//   sortCondition: string
//   checkName: string[]
//   setCheckName: (val: any) => any
//   titleName: string
//   rows: Data[] | []
//   exportFile: () => void
// }

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: '#F0F0F0',
    fontWeight: '700',
    fontSize: 12,
    border: '2px solid #202020',
    padding: '5px 10px',
    fontFamily: 'Honeywell Sans Web',
    lineHeight: '20px'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: '#d0d0d0',
    paddingLeft: '10px',
    borderBottom: '1px solid #202020',
    borderRight: 0,
    fontFamily: 'Honeywell Sans Web'
  }
}))

interface Sort {
  order: Order
  orderBy: string
}

function FirmwareList (props: PropsFromRedux) {
  const navigate = useNavigate()
  const rowsPerPage = 10
  const auth = useAuth()
  const rows = useSelector(
    (state: any) => state.firmwareListData?.firmwareListData
  )
  const [page, setPage] = React.useState(1)
  const [sort, setSort] = React.useState<Sort>({
    order: 'asc',
    orderBy: 'assetType'
  })
  const [selectedGroups, setSelectedGroups] = React.useState<string[]>([])
  const [searchVal, setSearchVal] = React.useState('')

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    newOrderBy: keyof Data
  ) => {
    const isAsc = sort.orderBy === newOrderBy && sort.order === 'asc'
    const toggledOrder = isAsc ? 'desc' : 'asc'
    setSort({ order: toggledOrder, orderBy: newOrderBy })
  }

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '& th': {
      border: '1px solid #202020'
    }
  }))

  function handleBreadCrumbsClick (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) {
    event.preventDefault()
  }

  const [fileId, setFileId] = React.useState<string>('')

  const [firmwareTypeState, setFirmwareTypeState] = React.useState<any>({})
  const [AssetTypeState, setAssetTypeState] = React.useState<any>({})
  const [versionState, setVersionState] = React.useState<any>({})
  const [showCreateCampaignPopup, setShowCreateCampaignPopup] =
    React.useState(false)
  const [filterCalled, setFilterCalled] = React.useState<string>('')
  const count = useSelector((state: any) => state.firmwareListData.totalCount)
  const firmwareType = useSelector(
    (state: any) =>
      state.firmwareFilterFirmwareType?.firmwareListFilterFirmwareType
  )
  const version = useSelector(
    (state: any) => state.firmwareFilterVersion?.firmwareListFilterVersion
  )
  const assetType = useSelector(
    (state: any) => state.firmwareFilterAssetType?.firmwareListFilterAssetType
  )
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef)

  function useOutsideAlerter (ref: any) {
    React.useEffect(() => {
      function handleClickOutside (event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setAnchorEl(null)
          setSelectedRow(null)
        }
      }
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }

  React.useEffect(() => {
    resetFirmwareType()
  }, [firmwareType])
  React.useEffect(() => {
    resetAssetType()
  }, [assetType])
  React.useEffect(() => {
    resetVersion()
  }, [version])
  const resetFirmwareType = () => {
    const tmp: any = {}
    if (firmwareType?.length) {
      for (const item of firmwareType) {
        tmp[item] = false
      }
    }
    setFirmwareTypeState(tmp)
  }
  const resetAssetType = () => {
    const tmp: any = {}
    if (assetType?.length) {
      for (const item of assetType) {
        tmp[item] = false
      }
    }
    setAssetTypeState(tmp)
  }
  const resetVersion = () => {
    const tmp: any = {}
    if (version?.length) {
      for (const item of version) {
        tmp[item] = false
      }
    }
    setVersionState(tmp)
  }

  const handleFetchFirmwareList = (): void => {
    if (page >= 1) {
      props.getFirmwareListingDetails(
        page,
        rowsPerPage,
        sort.order,
        sort.orderBy,
        searchVal,
        auth.user?.profile.customer as string
      )
    }
  }

  const clearAllFilters = () => {
    resetAssetType()
    resetVersion()
    resetFirmwareType()
  }

  const onAssetGroupPopUpClose = (): void => {
    setShowCreateCampaignPopup(false)
  }

  const [filterParams, setFilterParams] = React.useState<any>([])
  const [selectedFirmwareType, setSelectedFirmwareType] = React.useState<any>(
    []
  )
  const [selectedAssetType, setSelectedAssetType] = React.useState<any>([])
  const [selectedVersion, setSelectedVersion] = React.useState<any>([])
  const [clearAllClicked, setClearAllClicked] = React.useState(false)
  const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false)
  const [showDeleteSuccessModal, setShowDeleteSuccessModal] =
    React.useState<boolean>(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [anchorAssetType, setAnchorAssetType] = React.useState('')
  const [anchorFileId, setAnchorFileId] = React.useState('')
  const [selectedRow, setSelectedRow] = React.useState<any>(null)
  const [showTemplates, setShowTemplates] = React.useState<GroupParaObj[]>([])

  React.useEffect(() => {
    const extractedTrueValues = Object.keys(firmwareTypeState).filter(
      (key) => firmwareTypeState[key] === true
    )
    setSelectedFirmwareType(extractedTrueValues)
  }, [firmwareTypeState])
  React.useEffect(() => {
    const extractedTrueValues = Object.keys(AssetTypeState).filter(
      (key) => AssetTypeState[key] === true
    )
    setSelectedAssetType(extractedTrueValues)
  }, [AssetTypeState])
  React.useEffect(() => {
    const extractedTrueValues = Object.keys(versionState).filter(
      (key) => versionState[key] === true
    )
    setSelectedVersion(extractedTrueValues)
  }, [versionState])

  React.useEffect(() => {
    if (filterCalled === 'start') {
      const arr = []
      if (selectedFirmwareType.length) {
        arr.push({
          field: 'firmwareType',
          operator: 'IN',
          values: selectedFirmwareType
        })
      }

      if (selectedAssetType.length) {
        arr.push({
          field: 'assetType',
          operator: 'IN',
          values: selectedAssetType
        })
      }
      if (selectedVersion.length) {
        arr.push({
          field: 'version',
          operator: 'IN',
          values: selectedVersion
        })
      }
      if (arr.length === 0 && clearAllClicked) {
        props.getFirmwareListingDetails(
          page,
          rowsPerPage,
          sort.order,
          sort.orderBy,
          searchVal,
          auth.user?.profile.customer as string
        )
      }
      setFilterParams(arr)
    }
  }, [filterCalled])

  const callSearchValue = (e: any) => {
    setSearchVal(e)
    if (page === 1) {
      props.getFirmwareListingDetails(
        page,
        rowsPerPage,
        sort.order,
        sort.orderBy,
        e,
        auth.user?.profile.customer as string
      )
    }
  }
  React.useEffect(() => {
    if (filterParams.length !== 0) {
      props.getFirmwareListingDetailsFilteredData(
        page,
        rowsPerPage,
        sort.order,
        sort.orderBy,
        filterParams,
        auth.user?.profile.customer as string
      )
    } else {
      props.getFirmwareListingDetails(
        page,
        rowsPerPage,
        sort.order,
        sort.orderBy,
        searchVal !== '' ? searchVal : '',
        auth.user?.profile.customer as string
      )
    }
  }, [page, sort])
  const handleRetry = () => {
    props.getFirmwareListingDetails(
      page,
      rowsPerPage,
      sort.order,
      sort.orderBy,
      searchVal,
      auth.user?.profile.customer as string
    )
  }
  React.useEffect(() => {
    props.getFirmwareListingFilterAssetType(
      auth.user?.profile.customer as string
    )
    props.getFirmwareListingFilterFirmwareType(
      auth.user?.profile.customer as string
    )
    props.getFirmwareListingFilterVersion(
      auth.user?.profile.customer as string
    )
  }, [])

  const filterData = () => {
    setFilterCalled('start')
  }

  React.useEffect(() => {
    setPage(1)
    // istanbul ignore else
    if (filterParams.length !== 0) {
      props.getFirmwareListingDetailsFilteredData(
        1,
        rowsPerPage,
        sort.order,
        sort.orderBy,
        filterParams,
        auth.user?.profile.customer as string
      )
      setFilterCalled('end')
    } else {
      if (filterCalled === 'start') {
        props.getFirmwareListingDetails(
          1,
          rowsPerPage,
          sort.order,
          sort.orderBy,
          searchVal !== '' ? searchVal : '',
          auth.user?.profile.customer as string
        )
        setFilterCalled('end')
      }
    }
  }, [filterParams])
  const [value, setValue] = React.useState('')
  const [searched, setSearched] = React.useState('')

  const onchangehandleSearch = (e: any) => {
    setValue(e.target.value)
  }

  const handleCloseSearch = () => {
    setSearchVal('')
    setValue('')
    if (page === 1) {
      props.getFirmwareListingDetails(
        1,
        rowsPerPage,
        sort.order,
        sort.orderBy,
        '',
        auth.user?.profile.customer as string
      )
    } else {
      setPage(1)
    }
  }
  // istanbul ignore next
  const handleCloseDelete = () => {
    setShowDeleteSuccessModal(false)
    props.resetDeleteParams()
    if (filterParams.length !== 0) {
      props.getFirmwareListingDetailsFilteredData(
        page,
        rowsPerPage,
        sort.order,
        sort.orderBy,
        filterParams,
        auth.user?.profile.customer as string
      )
    } else {
      props.getFirmwareListingDetails(
        page,
        rowsPerPage,
        sort.order,
        sort.orderBy,
        searchVal !== '' ? searchVal : '',
        auth.user?.profile.customer as string
      )
    }
  }

  const handleMenuClose = (): void => {
    setAnchorEl(null)
    setSelectedRow(null)
  }

  const handleEllipsisClick = (event: any, row: string): void => {
    setAnchorEl(event.currentTarget)
    setSelectedRow(row)
  }

  React.useEffect(() => {
    searchValue = searched
  }, [searched])
  const [openFilter, setOpenFilter] = React.useState(false)
  const deleteFirmwareResponse = useSelector(
    (state: any) => state.deleteFirmware
  )
  const handleFirmwareTypeChange = (e: any) => {
    setFirmwareTypeState({
      ...firmwareTypeState,
      [e.target.name]: e.target.checked
    })
  }
  const handleAssetTypeChange = (e: any) => {
    setAssetTypeState({ ...AssetTypeState, [e.target.name]: e.target.checked })
  }

  const handleClick = () => {
    navigate('/firmware-upload')
  }
  const populateValues = () => {
    const tmp: any = {}
    const tmp1: any = {}
    const tmp2: any = {}

    for (let i = 0; i < firmwareType.length; i++) {
      if (
        filterParams.filter((par: any) => par.field === 'firmwareType')
          .length &&
        filterParams
          .filter((par: any) => par.field === 'firmwareType')[0]
          .values.includes(firmwareType[i])
      ) {
        tmp[firmwareType[i]] = true
      } else {
        tmp[firmwareType[i]] = false
      }
    }
    for (let i = 0; i < assetType.length; i++) {
      if (
        filterParams.filter((par: any) => par.field === 'assetType').length &&
        filterParams
          .filter((par: any) => par.field === 'assetType')[0]
          .values.includes(assetType[i])
      ) {
        tmp1[assetType[i]] = true
      } else {
        tmp1[assetType[i]] = false
      }
    }
    for (let i = 0; i < version.length; i++) {
      if (
        filterParams.filter((par: any) => par.field === 'version').length &&
        filterParams
          .filter((par: any) => par.field === 'version')[0]
          .values.includes(version[i])
      ) {
        tmp2[version[i]] = true
      } else {
        tmp2[version[i]] = false
      }
    }
    setFirmwareTypeState(tmp)
    setAssetTypeState(tmp1)
    setVersionState(tmp2)
  }
  React.useEffect(() => {
    if (deleteFirmwareResponse.deleteFirmware === 'Success') {
      setShowDeleteSuccessModal(true)
    }
  }, [deleteFirmwareResponse])

  const handleClearAllFiltersClick = (): void => {
    clearAllFilters()
    setSearchVal('')
    setFilterParams([])
    if (page === 1) {
      props.getFirmwareListingDetails(
        page,
        rowsPerPage,
        sort.order,
        sort.orderBy,
        '',
        auth.user?.profile.customer as string
      )
    } else {
      setPage(1)
    }
  }

  const handleVersionChange = (event: any) => {
    const {
      target: { value }
    } = event
    setSelectedVersion(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    )
  }

  const ITEM_HEIGHT = 48
  const ITEM_PADDING_TOP = 8
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    }
  }
  if (
    (rows !== null &&
      rows !== undefined &&
      props.firmwareListData?.httpStatus === 200) ||
    props.firmwareListData.httpStatus === 404
  ) {
    return (
      <div style={{ width: 'calc(100vw - 3.5rem)', paddingRight: '2rem' }}>
        <div
          role="presentation"
          data-testid="breadcrumbs"
          style={{ width: '100%' }}
        >
          <Breadcrumbs
            aria-label="breadcrumb"
            sx={{
              color: '#f0f0f0',
              fontSize: '12px',
              marginBottom: '14px',
              fontFamily: 'Honeywell Sans Web',
              marginTop: '12px'
            }}
          >
            <Link
              color="inherit"
              className="breadcrumbAssetMgmt"
              style={{ textDecoration: 'none', fontSize: '.875rem' }}
              data-testid="device-management-breadcrumb"
              onClick={() => {
                navigate('/card')
              }}
            >
              {LanguageStrings().AssetFirmwareStrings.assetManagement}
            </Link>
            <Link
              data-testid="asset-provisioning-history"
              style={{
                cursor: 'text',
                color: '#F0F0F0',
                textDecoration: 'none',
                fontSize: '.875rem'
              }}
              color="inherit"
            >
              {LanguageStrings().AssetFirmwareStrings.title}
            </Link>
          </Breadcrumbs>
        </div>
        <div
          className="topUtilityBar"
          style={{ marginBottom: '30px', marginRight: '0px' }}
        >
          <BackButton
            dataTestId="back-arrow"
            onclick={() => {
              navigate('/card')
            }}
          />
          <span id="componentTitle" data-testid="firmwaretitle">
            {LanguageStrings().AssetFirmwareStrings.title}
          </span>
          <span id="top-right-ami-utilities">
            <span id="top-right-btn-span">
              <GenericButton
                sx={{ margin: 0 }}
                width="fit-content"
                onClick={() => {
                  handleClick()
                }}
                dataTestId="goto-firmware-upload"
              >
                {LanguageStrings().AssetFirmwareStrings.addFirmware}
              </GenericButton>
            </span>
          </span>
        </div>
        <div className="filterDownloadSection">
          <div>
            <span data-testid="" className="firmware-Component-list-title">
              {' '}
              {LanguageStrings().AssetFirmwareStrings.firmwareList} |{' '}
            </span>
            <span className="total-firmware">
              {' '}
              {count} {LanguageStrings().AssetFirmwareStrings.title}{' '}
            </span>
          </div>
        </div>
        <Box sx={style} className="devices">
          <div
            className="import-section"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <div className="searchSection" style={{ width: '100%' }}>
              <Paper
                className="searchBoxComponent"
                sx={{
                  p: '2px 4px',
                  display: 'flex',
                  alignItems: 'left',
                  width: 375
                }}
              >
                {!version.length &&
                !assetType.length &&
                !firmwareType.length ? (
                  <Tooltip title={provisioningDetailStrings.noDataFilter}>
                    <Button
                      data-testid="filter-data"
                      className="filter-btn"
                      sx={{
                        background: '#707070',
                        cursor: 'not-allowed',
                        color: '#D0D0D0'
                      }}
                    >
                      <img
                        src={FilterIcon}
                        height={16}
                        width={16}
                        style={{ marginLeft: '7px' }}
                      />
                      {provisioningDetailStrings.filterTitle}
                    </Button>
                  </Tooltip>
                    ) : (
                  <Button
                    data-testid="filter-data"
                    className="filter-btn"
                    sx={{
                      background: '#707070',
                      cursor: 'pointer',
                      color: '#D0D0D0',
                      minWidth: '111.34px'
                    }}
                    onClick={() => {
                      setOpenFilter(true)
                      populateValues()
                    }}
                  >
                    <img
                      src={FilterIcon}
                      height={16}
                      width={16}
                      style={{ marginLeft: '7px' }}
                    />
                    {provisioningDetailStrings.filterTitle}
                  </Button>
                    )}

                <InputBase
                  className="searchBox"
                  sx={{ ml: 1, p: 1, minWidth: '420px', fontSize: '14px' }}
                  placeholder={FirmwareTableStrings.searchPlaceholder}
                  inputProps={{ 'aria-label': 'searchInput ' }}
                  value={value}
                  onChange={onchangehandleSearch}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      clearAllFilters()
                      setFilterParams([])
                      setSearched(value)
                      setPage(1)
                      callSearchValue(value)
                    }
                  }}
                />
                {value !== '' ? (
                  <CloseIcon
                    onClick={handleCloseSearch}
                    className="search-close-firmware"
                    aria-label="close-drawer"
                    sx={{ right: '-43%' }}
                  />
                ) : (
                  ''
                )}
                <IconButton
                  onClick={() => {
                    clearAllFilters()
                    setFilterParams([])
                    setSearched(value)
                    setPage(1)
                    callSearchValue(value)
                  }}
                  className="searchBtn"
                  type="button"
                  sx={{ p: '10px' }}
                  aria-label="search"
                >
                  <SearchIcon />
                </IconButton>
              </Paper>
              <Drawer
                anchor="right"
                open={openFilter}
                className="filter-drawer"
                onClose={() => {
                  setOpenFilter(false)
                  if (filterCalled !== 'end') {
                    clearAllFilters()
                  }
                }}
              >
                <div className="filter-headar">
                  <div className="title">Filter</div>
                  <div>
                    <Button
                      aria-label="clear-all-filter"
                      className="cancel"
                      style={{ marginTop: '-2px', marginLeft: '80px' }}
                      onClick={() => {
                        clearAllFilters()
                        setClearAllClicked(true)
                      }}
                    >
                      <ReplayIcon
                        sx={{
                          width: '16px',
                          height: '16px',
                          marginRight: '8px'
                        }}
                      />
                      Clear All
                    </Button>
                  </div>
                  <div>
                    <CloseIcon
                      aria-label="close-drawer"
                      sx={{ color: '#f0f0f0', cursor: 'pointer' }}
                      onClick={() => {
                        setOpenFilter(false)
                      }}
                    />
                  </div>
                </div>

                {firmwareType && firmwareType.length !== 0 && (
                  <>
                    <div className="type-name status">Firmware Type</div>
                    <FormGroup>
                      {firmwareType.map((val: string, index: number) => (
                        <FormControlLabel
                          key={`${index}-${val}`}
                          aria-label="firmware-type-change"
                          name={val}
                          onChange={(e) => {
                            handleFirmwareTypeChange(e)
                          }}
                          checked={firmwareTypeState[val]}
                          control={
                            <Checkbox
                              inputProps={{ id: `select-item-${index}` }}
                            />
                          }
                          label={val}
                        />
                      ))}
                    </FormGroup>
                  </>
                )}
                <div className="divider" />
                {assetType && assetType.length !== 0 && (
                  <>
                    <div className="type-name">
                      {' '}
                      {
                        LanguageStrings().AssetFirmwareStrings
                          .AssetFirmwareTable.assetType
                      }
                    </div>
                    <FormGroup>
                      {assetType.map((val: string, index: number) => (
                        <FormControlLabel
                          key={`${index}-${val}`}
                          aria-label="asset-type-change"
                          name={val}
                          onChange={(e) => {
                            handleAssetTypeChange(e)
                          }}
                          checked={AssetTypeState[val]}
                          control={
                            <Checkbox
                              inputProps={{ id: `select-item-${index}` }}
                            />
                          }
                          label={val}
                        />
                      ))}
                    </FormGroup>
                    <div className="divider" />
                  </>
                )}
                {version && version.length !== 0 && (
                  <>
                    <div className="type-name">
                      {
                        LanguageStrings().AssetFirmwareStrings
                          .AssetFirmwareTable.version
                      }
                    </div>

                    <FormControl>
                      <InputLabel className="select-title">
                        Select version
                      </InputLabel>

                      <Select
                        labelId="demo-multiple-checkbox-label"
                        className="uploaded-by-select"
                        multiple
                        value={selectedVersion}
                        onChange={handleVersionChange}
                        sx={{
                          height: '40px',
                          backgroundColor: '#5c5b5bdd',
                          borderRadius: '4px',
                          color: '#d0d0d0'
                        }}
                        renderValue={(selected: any) =>
                          (selected.length as string) + 'selected'
                        }
                        MenuProps={MenuProps}
                        aria-label="user-change"
                      >
                        {version?.map((name: string, index: number) => (
                          <MenuItem key={name} value={name}>
                            <Checkbox
                              inputProps={{ id: `select-item-${index}` }}
                              checked={selectedVersion.indexOf(name) > -1}
                            />
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <div className="divider" />
                  </>
                )}
                <div className="buttons-container">
                  <Button
                    className="cancel"
                    aria-label="cancel-drawer"
                    onClick={() => {
                      setOpenFilter(false)
                      if (filterCalled !== 'end') {
                        clearAllFilters()
                      }
                    }}
                  >
                    {provisioningDetailStrings.cancelButton}
                  </Button>
                  <Button
                    className="confirm"
                    aria-label="confirm-filter"
                    onClick={() => {
                      setSearched('')
                      setValue('')
                      filterData()
                      setOpenFilter(false)
                    }}
                  >
                    {provisioningDetailStrings.showResultButton}
                  </Button>
                </div>
              </Drawer>
            </div>
            <div style={{ padding: '5px' }}>
              <img
                className="fileExport"
                src={FileExport}
                height={25}
                width={25}
                onClick={() => {
                  props.exportFirmwareList(
                    auth.user?.profile.customer as string
                  )
                }}
              />
            </div>
          </div>
          {filterParams.length !== 0 && (
            <div
              style={{
                padding: '12px 24px',
                borderTop: '2px solid #202020',
                color: '#d0d0d0'
              }}
            >
              {filterParams.length === 0 ? (
                'No filters applied'
              ) : (
                <div className="classNAme">
                  {count} Results | Filters :
                  <>
                    {filterParams.filter(
                      (par: any) => par.field === 'firmwareType'
                    ).length !== 0 &&
                      filterParams.filter(
                        (par: any) => par.field === 'firmwareType'
                      )[0].values.length !== 0 && (
                        <div className="box">
                          {' '}
                          Firmware Type:{' '}
                          {(filterParams.filter(
                            (par: any) => par.field === 'firmwareType'
                          )[0].values.length as string) + ' selected'}
                        </div>
                    )}
                    {filterParams.filter(
                      (par: any) => par.field === 'assetType'
                    ).length !== 0 &&
                      filterParams.filter(
                        (par: any) => par.field === 'assetType'
                      )[0].values.length !== 0 && (
                        <div className="box">
                          {' '}
                          Asset Type:{' '}
                          {(filterParams.filter(
                            (par: any) => par.field === 'assetType'
                          )[0].values.length as string) + ' selected'}
                        </div>
                    )}
                    {filterParams.filter((par: any) => par.field === 'version')
                      .length !== 0 &&
                      filterParams.filter(
                        (par: any) => par.field === 'version'
                      )[0].values.length !== 0 && (
                        <div className="box">
                          {' '}
                          Version:{' '}
                          {(filterParams.filter(
                            (par: any) => par.field === 'version'
                          )[0].values.length as string) + ' selected'}
                        </div>
                    )}
                  </>
                  <em
                    onClick={() => {
                      handleClearAllFiltersClick()
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    Clear Filters{' '}
                  </em>
                </div>
              )}
            </div>
          )}
          {props.firmwareListData?.httpStatus === 200 && rows?.length !== 0 ? (
            <>
              <TableContainer className="firmware-table">
                <Table
                  sx={{ minWidth: 750, color: '#f0f0f0' }}
                  aria-labelledby="tableTitle"
                  size={'small'}
                >
                  <EnhancedTableHead
                    order={sort.order}
                    orderBy={sort.orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={rows?.length}
                  />
                  <TableBody>
                    {rows?.map((row: FirmwareListingDetails, index: number) => {
                      const labelId = `enhanced-table-checkbox-${index}`
                      return (
                        <StyledTableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.fileId}
                          aria-label="devices-firmware-row"
                        >
                          <StyledTableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            aria-label="assetType"
                            padding="none"
                            data-testid="assetType"
                          >
                            {row.firmwareType}
                          </StyledTableCell>
                          <StyledTableCell>{row.assetType}</StyledTableCell>
                          <StyledTableCell>{row.version}</StyledTableCell>
                          <StyledTableCell
                            sx={{
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              maxWidth: '12vw'
                            }}
                          >
                            {row.groupsDto === null ? (
                              ''
                            ) : Object.keys(row.groupsDto).length === 0 ? (
                              ''
                            ) : row.groupsDto.length === 1 ? (
                              row.groupsDto[0].groupName.length > 10 ? (
                                <abbr
                                  style={{ textDecoration: 'none' }}
                                  title={row.groupsDto[0].groupName}
                                >
                                  {row.groupsDto[0].groupName}
                                </abbr>
                              ) : (
                                row.groupsDto[0].groupName
                              )
                            ) : (
                              <span
                                onClick={() => {
                                  setShowTemplates(
                                    row.groupsDto === null ? [] : row.groupsDto
                                  )
                                }}
                                style={{ color: '#64C3FF' }}
                              >
                                {row.groupsDto[0].groupName.length > 10 ? (
                                  <abbr
                                    style={{ textDecoration: 'none' }}
                                    title={row.groupsDto[0].groupName}
                                  >
                                    {row.groupsDto[0].groupName.substring(
                                      0,
                                      10
                                    ) + '...'}
                                  </abbr>
                                ) : (
                                  row.groupsDto[0].groupName
                                )}
                                +{row.groupsDto.length - 1}
                              </span>
                            )}
                          </StyledTableCell>
                          <StyledTableCell>
                            {row.minimumMeterVersion}
                          </StyledTableCell>
                          <StyledTableCell>
                            {row.minimumCommunicationVersion}
                          </StyledTableCell>
                          <StyledTableCell>
                            {row.imageIdentifier}
                          </StyledTableCell>
                          <StyledTableCell>{row.description}</StyledTableCell>
                          <StyledTableCell
                            sx={{
                              color: '#64C3FF !important',
                              cursor: 'pointer',
                              paddingLeft: '5px !important'
                            }}
                          >
                            {/* <div aria-label='delete' style={{ display: 'inline', paddingRight: '10px' }} data-testid="assign-group-btn" onClick={() => {
                                setSelectedGroups(row.groupsDto.map(group => group.groupId))
                                setAnchorAssetType(row.assetType || "")
                                setAnchorFileId(row.fileId || "")
                                }}>Delete</div> */}
                            <div
                              data-testid="create-campaign-btn"
                              style={{
                                display: 'inline-flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                position: 'relative',
                                paddingBottom: '3px',
                                paddingLeft: '15px'
                              }}
                              onClick={() => {
                                setAnchorAssetType(row.assetType ?? '')
                                setAnchorFileId(row.fileId ?? '')
                                setShowCreateCampaignPopup(true)
                                setSelectedRow(null)
                              }}
                            >
                              Create Campaign
                            </div>
                          </StyledTableCell>
                        </StyledTableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              {count > 10 && (
                <Pagination
                  count={count}
                  page={page}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  siblingCount={1}
                  boundaryCount={1}
                />
              )}
              {showCreateCampaignPopup && (
                <CreateCampaignPopup
                  assignedGroups={selectedGroups}
                  refreshFirmwareList={handleRetry}
                  onCreateCampaignPopUpClose={onAssetGroupPopUpClose}
                  selected={anchorFileId}
                  assetType={anchorAssetType}
                  aria-label="create-camp-comp"
                />
              )}
            </>
          ) : (
            <div className="authError">
              <AuthError errorMessage="NoDataPresent" />
            </div>
          )}
        </Box>
        {showTemplates.length > 0 ? (
          getTemplateModal(
            showTemplates.map((template) => template.groupName),
            () => {
              setShowTemplates([])
            },
            'Groups'
          )
        ) : (
          <></>
        )}
        {props.deleteFirmware?.isLoading && (
          <div className="CircularprogressAmi">
            <CircularProgress />
          </div>
        )}
        <Modal
          className="add-firmware-modal"
          open={showDeleteModal}
          onClose={() => {
            setShowDeleteModal(false)
          }}
        >
          <Box sx={modalStyle}>
            <div className="title">
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {LanguageStrings().AssetFirmwareStrings.deleteFirmwareVersion}
              </Typography>
              <CloseIcon
                style={{ cursor: 'pointer' }}
                data-testid="close"
                onClick={() => {
                  setShowDeleteModal(false)
                }}
              />
            </div>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {LanguageStrings().AssetFirmwareStrings.doYouWantToDelete}
            </Typography>
            <div className="add-modal-button-container">
              <Button
                data-testid="cancel-add-firmware"
                onClick={() => {
                  setShowDeleteModal(false)
                }}
              >
                Cancel
              </Button>
              <Button
                data-testid="delete-firmware"
                onClick={() => {
                  props.deleteFirmware(
                    fileId,
                    auth.user?.profile.customer as string
                  )
                  setShowDeleteModal(false)
                }}
              >
                Confirm
              </Button>
            </div>
          </Box>
        </Modal>
        <Modal
          className="add-firmware-modal"
          open={showDeleteSuccessModal}
          onClose={() => {
            handleCloseDelete()

            if (filterParams.length !== 0) {
              props.getFirmwareListingDetailsFilteredData(
                page,
                rowsPerPage,
                sort.order,
                sort.orderBy,
                filterParams,
                auth.user?.profile.customer as string
              )
            } else {
              props.getFirmwareListingDetails(
                page,
                rowsPerPage,
                sort.order,
                sort.orderBy,
                searchVal !== '' ? searchVal : '',
                auth.user?.profile.customer as string
              )
            }
          }}
        >
          <Box sx={successModalStyle}>
            <div className="title">
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {LanguageStrings().AssetFirmwareStrings.deletedSuccessfully}
              </Typography>
              <CloseIcon
                style={{ cursor: 'pointer' }}
                data-testid="close"
                onClick={handleCloseDelete}
              />
            </div>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {
                LanguageStrings().AssetFirmwareStrings
                  .FullMsgDeletedSuccessfully
              }
            </Typography>
            {deleteFirmwareResponse.deleteFirmware !== 'Success' && (
              <div className="failure-upload-response">
                <WarningIcon className="error-upload-icon" />
                <span className="error-upload-message">
                  {' '}
                  {' Please try again later.'}
                </span>
              </div>
            )}
            <div className="add-modal-button-container">
              <Button style={{ visibility: 'hidden' }}></Button>
              <Button data-testid="ok" onClick={handleCloseDelete}>
                Ok
              </Button>
            </div>
          </Box>
        </Modal>
      </div>
    )
  } else if (props.firmwareListData.isLoading) {
    return (
      <div className="CircularprogressAmi">
        <CircularProgress />
      </div>
    )
  } else if (props.firmwareListData?.httpStatus === 401) {
    return (
      <div className="authError">
        <AuthError errorMessage="Unauthorized" />
      </div>
    )
  } else if (props.firmwareListData?.httpStatus === 403) {
    return (
      <div className="authError">
        <AuthError errorMessage="accessForbidden" />
      </div>
    )
  } else if (props.firmwareListData.httpStatus === 400) {
    return (
      <div className="authError">
        <AuthError errorMessage="invalidSearchInput" />
      </div>
    )
  } else {
    return (
      <div className="authError">
        <AuthError errorMessage="cannotFetch" retry={handleRetry} />
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  getFirmwareListingDetails: (
    page: number,
    size: number,
    sortdir: string,
    sortfield: string,
    search: string,
    tenantId: string
  ) =>
    dispatch(
      getFirmwareListingDetails(
        page,
        size,
        sortdir,
        sortfield,
        search,
        tenantId
      )
    ),
  getFirmwareListingDetailsFilteredData: (
    page: number,
    size: number,
    sortdir: string,
    sortfield: string,
    filterParams: any,
    tenantId: string
  ) =>
    dispatch(
      getFirmwareListingDetailsFilteredData(
        page,
        size,
        sortdir,
        sortfield,
        filterParams,
        tenantId
      )
    ),
  getFirmwareListingFilterAssetType: (tenantId: string) =>
    dispatch(getFirmwareListingFilterAssetType(tenantId)),
  getFirmwareListingFilterFirmwareType: (tenantId: string) =>
    dispatch(getFirmwareListingFilterFirmwareType(tenantId)),
  getFirmwareListingFilterVersion: (tenantId: string) =>
    dispatch(getFirmwareListingFilterVersion(tenantId)),
  exportFirmwareList: (tenantId: string) =>
    dispatch(exportFirmwareList(tenantId)),
  deleteFirmware: (firmwareId: string, tenantId: string) =>
    dispatch(deleteFirmware(firmwareId, tenantId)),
  resetDeleteParams: () => dispatch(resetDeleteParams())
})

interface StateToProps {
  firmwareListData: firmwareListingDetailsState
  deleteFirmware: deleteFirmwareState
}

const mapStateToProps = (state: RootState): StateToProps => ({
  firmwareListData: state.firmwareListData,
  deleteFirmware: state.deleteFirmware
})

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(FirmwareList)
