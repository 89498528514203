import React from 'react'
import catchFetch from '../../assets/errImg.svg'
import { Button } from '../Button'

interface ErrorProps {
  className?: string[]
  error?: any[]
  errorStyle?: React.CSSProperties
  style?: React.CSSProperties
  retry?: () => void
  imgStyle?: React.CSSProperties
  errorItemStyle?: React.CSSProperties
  img?: string
  children?: React.ReactNode
  dataTestId?: string
}

export const GenericError = (props: ErrorProps): JSX.Element => {
  return (
    <div data-testid={props.dataTestId ?? 'generic-error'} className={props.className !== undefined ? props.className.join(' ') : 'errorComp'} style={props.style}>
        <img src={props.img ?? catchFetch} style={props.imgStyle} className='errorComp1Group'/>
        <ul style={props.errorStyle}>
          {props.error?.map((err, index) => (
            <li style={props.errorItemStyle} key={index} className='errorCompContent'>{err}</li>
          ))}
        </ul>
        {
          props.children
        }
        <div style={{ display: 'flex', justifyContent: 'right', marginTop: '1rem', width: '100%' }}>
          <Button onClick={props.retry}>Retry</Button>
        </div>
      </div>
  )
}
