import interceptedAxios from '../../../utils/interceptedAxios'
import { ZApiResponse } from './deviceListService'

export async function getAssetBySearchValue (
  searchVal: string,
  tenantId: string,
  page: number,
  size: number,
  sortfield: string,
  sortdir: string,
  onSuccess: (deviceList: any, httpStatus: number) => any,
  onFailure: (errorMessage: any, httpStatus: number) => any
): Promise<void> {
  // istanbul ignore else
  if (sortfield === 'serial_number') {
    sortfield = 'serialNumber'
  } else if (sortfield === 'install_date') {
    sortfield = 'installDate'
  } else if (sortfield === 'removal_date') {
    sortfield = 'removalDate'
  } else if (sortfield === 'firmware_version') {
    sortfield = 'firmwareVersion'
  }
  const url = `/asset-service/api/v1/udp/assets/search?inputText=${searchVal}&tenantId=${tenantId}&page=${page}&size=${size}&sort=${sortfield},${sortdir}`

  interceptedAxios.get(url)
    .then((response) => {
      if (response.status === 200) {
        const parseResponse = ZApiResponse.safeParse(response.data)
        // istanbul ignore else
        if (parseResponse.success) {
        // istanbul ignore else
          if (parseResponse.data.data !== null && parseResponse.data.data !== undefined) {
            onSuccess(parseResponse.data.data, parseResponse.data.http_status)
          } else {
            onFailure(parseResponse.data.error, parseResponse.data.http_status)
          }
        } else {
          onFailure(parseResponse.error.message, response.status)
        }
      } else {
        onFailure(response.statusText, response.status)
      }
    })
    .catch((error) => {
      if (error.response !== undefined) {
        const errorMessage = (error.response.data !== undefined && error.response.data !== null) ? error.response.data.error_message : 'Network Error'
        onFailure(errorMessage, error.response.status)
      } else {
        onFailure('Network Error', 404)
      }
    })
}
